import _, { cloneDeep } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import { getProducts, setActiveFilter, setFilterData, setTableData, updateProductList } from '../store/dataSlice'
import { Card, Select,Input, Button, Tag } from 'components/ui'

import logo from "assets/images/logo-dark.png";
import Loading from 'components/shared/Loading'
import debounce from 'lodash/debounce'


import { HiCheck, HiOutlineSearch, HiX } from 'react-icons/hi'
import CategorySlider from './CategorySlider'
import ProductsTools from './ProductsTools'


const ProductTable = () => {
    const {category,id} = useParams()
    const searchInput = useRef()
    const navigate = useNavigate()
    const selectedCategory = useSelector(
        (state) => state.productlist.state.selectedCategory
    )
  const [filterText, setfilterText] = useState()

    const [productsIn, setproductsIn] = useState([])
    const selectedSubCategory = useSelector(
        (state) => state.productlist.state.selectedSubCategory
    )
    const dispatch = useDispatch()
    const { pageIndex, pageSize, sort, query, total } = useSelector(
        (state) => state.productlist.data.tableData
    )
    const filterData = useSelector(
        (state) => state.productlist.data.filterData
    )
    
    const tableData = useSelector(
        (state) => state.productlist.data.tableData
    )
    const loading = useSelector((state) => state.productlist.data.loading)
    const userFavorites = useSelector((state) => state.productlist.data.userFavorites)
    const data = useSelector((state) => state.productlist.data.productList)
    useEffect(() => {
      
        fetchData();
      return () => {
        
      }
    }, [category,id])
    useEffect(() => {
      
        setproductsIn(data)
      return () => {
        
      }
    }, [data])
    const debounceFn = debounce(handleDebounceFn, 500)
    const fetchData = () => {
        const newFilterData = cloneDeep(filterData)
        newFilterData.name = id === "all"?'':id
        dispatch(setFilterData(newFilterData))
        dispatch(getProducts({ pageIndex, pageSize, sort, query, filterData:newFilterData,token:null,isFav:false }))
        setproductsIn(data)
    }
    const filterByNameAndLastName = (array, nameTerm, lastNameTerm) =>{
    
        return array.filter(item => {
            const name = item.BrandName.toLowerCase();
            const lastName = item.StyleName.toString().toLowerCase();
            const lastTitle = item.Title.toString().toLowerCase();
            const searchName = nameTerm.toLowerCase();
            const searchLastName = lastNameTerm.toLowerCase();
            
            return name.includes(searchName) || lastName.includes(searchLastName)  || lastTitle.includes(searchLastName);
        });
    }
    // console.log(filterByNameAndLastName(producstData,"bella","1566"));
    
    function handleDebounceFn(val) {
        const newTableData = cloneDeep(tableData)
        newTableData.query = val
        newTableData.pageIndex = 1
        if (typeof val === 'string' && val.length > 1) {
            fetchDatas(newTableData)
        }

        if (typeof val === 'string' && val.length === 0) {
            fetchDatas(newTableData)
        }
    }
    const fetchDatas = (data) => {
        dispatch(setTableData(data))
        dispatch(getProducts(data))
    }
    const onClick = (e,id) =>{
        navigate("/product/"+id)
    }
    
    const cardHeader = (p,userFavorites) => {
        
        return(<><div className='absolute right-2 z-10 top-1'> <img src={logo} className=" w-[80px]" alt="logo" /></div><div className="rounded-tl-lg flex items-start justify-center bg-white rounded-tr-lg overflow-hidden relative ">
            
            {/* <UserFavoriteContent p={p} actionFav={userFavorites && userFavorites.includes(p.id)?true:false} /> */}
            
        <img className='h-[250px]' src={p.StyleImage && p.isSanmar?p.StyleImage:p.StyleImage.includes("https://")?p.StyleImage:"https://www.ssactivewear.com/"+p.StyleImage} alt="image" />
    </div></>)
    }
    
  
    

    
    const ClearFilters = ()=>{
        setfilterText("")
        dispatch(setActiveFilter(null))
        const initialFilterDataIn = {
            name: '',
            category:[ "Men","Women","Kids","Youth","Unisex"],
            status: [0, 1, 2],
            brand:"All",
            productStatus: 0,
        }
        dispatch(setFilterData(initialFilterDataIn));
        

        dispatch(getProducts({ pageIndex, pageSize, sort, query, filterData:initialFilterDataIn,token:null,isFav:false }))

    }
    const onEdit = (e) => {
        // filterList(e.target.value)
        debounceFn(e.target.value)
    }
  return (
    <section className='bg-center w-full pb-[160px] px-2 md:px-8 bg-white relative'>
        
            <CategorySlider />
            
            <ProductsTools />
        
        <div className='containers'>
 {loading ? <div className='flex items-center justify-center flex-1'><Loading loading={loading} /></div>:
           productsIn && productsIn.length > 0 ?<div className="grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-4 ">
            
          { productsIn.map((p)=>(
        
                <Card key={p.id}  className='mb-2 shadow relative rounded-md hover:shadow-lg transition duration-150 ease-in-out border-2'
                clickable
                headerClass="p-0 md:p-3 bg-white rounded-tl-lg rounded-tr-lg relative"
                header={cardHeader(p,userFavorites)}
                onClick={(e) => onClick(e,p["_id"])}
                bodyClass="p-6"
                
                >
                {/* <div className='tags-unisex'>
                    <div className='tag-item Unisex'>{p.StyleType}</div>
                </div> */}
                {/* <div className='card-borderleft border-slate-200 border-t-2 border-r-2'></div>
                <div className='card-borderright border-slate-200 border-b-2 border-l-2'></div> */}
                <div className='flex gap-y-2 flex-col'>
                <span className="text-gray-700 font-bold leading-5">
                   {p.BrandName}
                </span>
                <span className="text-indigo-700 font-semibold min-h-[2.5rem] leading-5">
                   {`${p.Title?p.Title:p.name} (${p.StyleName})`}
                </span>
                <span className=" font-extrabold text-lg mb-2">
                   Starting from ${p.StartingPrice?p.StartingPrice:p.sizeVariants[0]["price"]}
                </span>
                <div className='grid grid-cols-6 grid-rows-2 gap-1'>
                {       
                        p["colorVariants"] && p["colorVariants"].length > 12?
                       <>{p["colorVariants"] && p["colorVariants"].map((color,key) => 
                        key < 11?<div key={color.name} title={`${color.name} - ${color.code}`} className='w-7 h-5 border rounded-sm' style={color.imgcolor?{backgroundImage:`url("${color.imgcolor}")`}:{backgroundColor:`${color.code}`}}></div>:''
                        
                        )
}<div  className='w-7 h-5 border rounded-sm text-xs flex items-center justify-center' ><span>{`+${p["colorVariants"].length-11}`}</span></div></> 
                        : (p["colorVariants"] && p["colorVariants"].map((color) => 
                        <div key={color.name} title={`${color.name} - ${color.code}`} className='w-6 h-5 border rounded-sm' style={color.imgcolor?{backgroundImage:`url("${color.imgcolor}")`}:{backgroundColor:`${color.code}`}}></div>
                        
                        ))
                }
                </div>
                <div className='flex gap-1 flex-wrap'></div>
                
                <span className="font-extralight">
                        {p.sizeVariants[0]["name"]} - {_.last(p.sizeVariants)["name"]}
                </span>
                </div>
                </Card>
            
            
           ))}</div>:
           <div>
            {/* <LottieAnimation animationData={selectedCategory.value === "favorites"?animationDataFav: animationData} /> */}
            <div className='text-center'>{selectedCategory.value === "favorites"?"Pick your favorite products and we'll list them here":"No products found for selected category"}</div>
           </div>
           }
           </div>
           </section>
  )
}

export default ProductTable