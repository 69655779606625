import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetShopProductData } from 'services/ProductService'
// import { apiGetUserStores } from 'services/StoresService'


export const getSelectedProductInfo = createAsyncThunk(
    'productInfo/data/getSelectedProductInfo',
    async (data) => {
        const response = await apiGetShopProductData(data)
        return response.data
    }
)

export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'productSelection/data',
    initialState: {
        loading: true,
        productInfo: {},
        activeVariant:{},
        shippingDetails:{name:"",email:"",phone:"",address:"",address1:"",city:"",state:"",zip:"",country:""},
    },
    reducers: {
        setShippingDetails:(state,action) =>{
            state.shippingDetails = action.payload
        },
        setActiveVariant: (state, action) => {
            state.activeVariant = action.payload
        },
    },
    extraReducers: {
        [getSelectedProductInfo.fulfilled]: (state, action) => {
            state.productInfo = action.payload.product
            state.activeVariant = action.payload.product["productVariants"][0]
            state.loading = false
        },
        [getSelectedProductInfo.pending]: (state) => {
            state.loading = true
        },
    },
})
export const { setShippingDetails,setActiveVariant } = dataSlice.actions
export default dataSlice.reducer
