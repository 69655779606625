import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../components/headers/Header'
import Lottie from 'lottie-react';
import animationData from 'assets/json/404-page.json';
import FooterTwo from 'components/footers/FooterTwo';
const Page404Layout = () => {
    const desiredWidth = 500; // Set your desired width here
    const animationAspectRatio = animationData.w / animationData.h;
    
    const desiredHeight = desiredWidth / animationAspectRatio;
  return (
    <>
      <Header/>
      <section className="md:pt-[80px] md:pb-[80px] pt-[80px] pb-[80px] relative bg-cover bg-center bg-white flex items-center">
      <div style={{ width: desiredWidth, height: desiredHeight }}>
      <Lottie animationData={animationData} />
    </div>
      </section>
      <FooterTwo />
    </>
  )
}

export default Page404Layout
