import React, { useState } from "react";
import client6 from "../assets/images/clients/6.png";
import client7 from "../assets/images/clients/7.png";
import FooterTwo from "../components/footers/FooterTwo";
import ClientLogo from "../features/common/ClientLogo";
import RightPlan from "../features/common/RightPlan";
import Counter from "../features/landingpage-two/Counter";
import Coustomer from "../features/common/Coustomer";
import Features from "../features/landingpage-two/Features";
import FunfactVideo from "../features/landingpage-two/FunfactVideo";
import { Link } from "react-router-dom";
import HomeDisplay from "features/home/HomeDisplay";
import { HiCheck, HiChevronRight } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";
import ConnectBtn from "components/buttons/ConnectBtn";
import { Button } from "components/ui";
import CategorySlider from "views/Products/components/CategorySlider";
import ProductSlider from "features/landingpage-two/ProductSlider";
import HomeDisplayTwo from "features/home/HomeDisplayTwo";

const LandingPageTwo = () => {
  const [dataBlock] = useState({
    title: "Smart and effective solutions for businesses.",
    subTitle: "Make a website you’ll be proud of",
    since: "Since 1911",
  });
  const whyInfo = ["Better prices","100% Free to use","Lightning turnaround time"]
  const [dataBlockOffer] = useState({
    title: "We Offer a Wide Range of Printing Solutions",
    subTitle: "We are here to offer the highest quality printing services to you and your customers and the most competitive prices. The quick turnaround time makes us one of the leaders in the T-shirt printing business. We offer DTG, Screen-printing, Embroidery, Sublimation and Fulfillment services."
})
  return (
    <>
      <section className="md:pt-[80px] md:pb-[80px] pt-[80px] pb-[80px] relative bg-cover bg-center bg-white">
        <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full bg-[url('../images/home_sec_layer-2.png')]"></div>
       <HomeDisplay />
      </section>
      
          <ProductSlider />
          {/* <img src={} /> */}
          <div className="container my-2">
          <div className="grid grid-cols-1 pt-7">
            <div className="text-center">
              <h2 className="font-bold text-[28px] md:text-[40px]  mb-2 relative z-10">
                <span className=" h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlockOffer.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[900px] mt-6">
                {dataBlockOffer.subTitle}
              </p>
            </div>
          </div>
          </div>
      <HomeDisplayTwo />
      <Features />
      {/* <FunfactVideo/> */}

      {/* <RightPlan/> */}
      {/* <Counter/> */}
      {/* <Coustomer/> */}
      {/* <ClientLogo/> */}
     
      <FooterTwo/>
    </>
  );
};

export default LandingPageTwo;
