import React, { useState } from 'react';
import { Menu, ScrollBar } from 'components/ui';

import { IoMdShirt,IoIosShirt,IoMdGlasses,IoMdHeart } from "react-icons/io";
import { BsLampFill,BsHeartFill } from "react-icons/bs";
import { ImGlass } from "react-icons/im";
import { useSelector } from 'react-redux';
const { MenuItem, MenuGroup } = Menu

export const groupList = [
    { value: 'favorites', label: 'My favorites', icon: <IoMdHeart /> ,submenu:null},
    { value: 'apparel', label: 'Apparel', icon: <IoIosShirt />,
    submenu:[
        {value:"all",label:"All"},
        {value:"Mens T-Shirt",label:"Mens T-Shirts"},
        {value:"Unisex T-Shirt",label:"Unisex T-Shirts"},
        {value:"Womens Tank Top",label:"Womens Tank Tops"},
        {value:"Mens Tank Top",label:"Mens Tank Tops"},
        {value:"Womens V-Neck",label:"Womens V-Necks"},
        {value:"Mens V-Neck",label:"Mens V-Necks"},
        {value:"Hoodies",label:"Hoodies"},
        {value:"Sweatshirt",label:"Sweatshirts"},
        {value:"Long Sleeve",label:"Long Sleeve"},
        {value:"Kids T-Shirts",label:"Kids T-Shirt"},
        {value:"Womens T-Shirt",label:"Womens T-Shirts"},
    ] },
    { value: 'accessories', label: 'Accessories', icon: <IoMdGlasses />,submenu:null },
    { value: 'home-living', label: 'Home & Living', icon: <BsLampFill />,submenu:null },
    { value: 'drinkware', label: 'Drinkware', icon: <ImGlass /> ,submenu:null},
    
]
const MenuList = () => {
    const [defaultExpandKey, setdefaultExpandKey] = useState(["apparel"])

  // State to track the expand/collapse status of the sub-menu
  const onMenuClick = (category) => {
    // dispatch(updateMailId(''))
    // dispatch(updateSelectedCategory(getCategory(category.value)))
    // const currentpath = location.pathname.split("/").reverse()[1]
    // if(currentpath !== "create-design"){
    //     navigate(`/dashboard/product/design/${category.value}`, { replace: true })
    // }
    
    // const newFilterData = cloneDeep(filterData)
    // newFilterData.category = [category.value]
    // dispatch(setFilterData(newFilterData))

}
const onSubMenuClick = (category,menu) => {
    // dispatch(updateMailId(''))
    // dispatch(updateSelectedSubCategory(category))
    // dispatch(updateSelectedCategory(getCategory(menu.value)))
    // const currentpath = location.pathname.split("/").reverse()[1]
    // if(currentpath !== "create-design"){
    //     navigate(`/dashboard/product/design/${category.value}`, { replace: true })
    // }
    // if(category.value != "all"){
    // const newFilterData = cloneDeep(filterData)
    // newFilterData.category = [menu.value]
    // newFilterData.name = category.value
    // dispatch(setFilterData(newFilterData))
    // }
    // else{
    //     const newFilterData = cloneDeep(filterData)
    //     newFilterData.category = [menu.value]
    //     newFilterData.name = ""
    //     dispatch(setFilterData(newFilterData))
    // }
}

const selectedCategory =  { value: 'apparel', label: 'Apparel', icon: <IoIosShirt />,
submenu:[
    {value:"all",label:"All"},
    {value:"Mens T-Shirt",label:"Mens T-Shirts"},
    {value:"Unisex T-Shirt",label:"Unisex T-Shirts"},
    {value:"Womens Tank Top",label:"Womens Tank Tops"},
    {value:"Mens Tank Top",label:"Mens Tank Tops"},
    {value:"Womens V-Neck",label:"Womens V-Necks"},
    {value:"Mens V-Neck",label:"Mens V-Necks"},
    {value:"Hoodies",label:"Hoodies"},
    {value:"Sweatshirt",label:"Sweatshirts"},
    {value:"Long Sleeve",label:"Long Sleeve"},
    {value:"Kids T-Shirts",label:"Kids T-Shirt"},
    {value:"Womens T-Shirt",label:"Womens T-Shirts"},
] }

const selectedSubCategory =  { value: 'apparel', label: 'Apparel'}
    return (
        <ScrollBar direction={"ltr"}>
            <div className="flex flex-col justify-between h-full">
                <div>
                    <div className="my-8 mx-6">
                        {/* <h3>Mailbox</h3> */}
                    </div>
                    <Menu variant="transparent" className="mx-2 mb-10" defaultExpandedKeys={defaultExpandKey}>
                        {groupList.map((menu) => (
                            !menu.submenu?<MenuItem
                                key={menu.value}
                                eventKey={menu.value}
                                className={`mb-2 ${
                                    selectedCategory && selectedCategory.value === menu.value
                                        ? 'bg-gray-100 dark:bg-gray-700'
                                        : ''
                                }`}
                                onSelect={() => onMenuClick(menu)}
                            >
                                <span className="text-2xl ltr:mr-2 rtl:ml-2">
                                    {menu.icon}
                                </span>
                                <span>{menu.label}</span>
                            </MenuItem>
                            :
                    <Menu.MenuCollapse
                    key={menu.value}
                    eventKey={menu.value}
                    className={`mb-2 ${
                        selectedCategory && selectedCategory.value === menu.value
                            ? 'bg-gray-100 dark:bg-gray-700'
                            : 'flex'
                    }`}
                    onSelect={() => onMenuClick(menu)}
                         label={<><span className="text-2xl ltr:mr-2 rtl:ml-2">
                         {menu.icon}
                     </span>
                     <span>{menu.label}</span></>}
                    
                        >
                          {menu.submenu.map((submenu) =>( <MenuItem
                                key={submenu.value}
                                eventKey={submenu.value}
                                className={`mb-0 ${
                                    selectedSubCategory && selectedSubCategory.value === submenu.value
                                        ? 'bg-gray-100 dark:bg-gray-700'
                                        : ''
                                }`}
                                onSelect={() => onSubMenuClick(submenu,menu)}
                            >
                               
                                <span>{submenu.label}</span>
                            </MenuItem>))}
                    </Menu.MenuCollapse>
                        ))}
                         
                    </Menu>
                    {/* <Menu variant="transparent" className="mx-2 mb-6">
                        <MenuGroup label="Labels">
                            {labelList.map((label) => (
                                <MenuItem
                                    key={label.value}
                                    eventKey={label.value}
                                    className={`mb-2 ${
                                        selectedCategory.value === label.value
                                            ? 'bg-gray-100 dark:bg-gray-700'
                                            : ''
                                    }`}
                                    onSelect={() => onMenuClick(label)}
                                >
                                    <Badge
                                        className="ltr:mr-2 rtl:ml-2"
                                        innerClass={label.dotClass}
                                    />
                                    <span>{label.label}</span>
                                </MenuItem>
                            ))}
                        </MenuGroup>
                        
                    </Menu> */}
                </div>
                {/* <div className="mx-4 mb-4">
                    <MainCompose />
                </div> */}
            </div>
        </ScrollBar>
    
  );
};

export default MenuList;
