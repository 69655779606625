import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import display1 from "assets/images/features/slide-1.png";
import display2 from "assets/images/features/slide-2.png";
import display3 from "assets/images/features/slide-3.png";
import { Button, Dialog } from "components/ui";
import { useState } from "react";

const HomeDisplayTwo = () => {
   const [dialogIsOpen, setIsOpen] = useState(false)
   useEffect(() => {
    // openDialog()
   
     return () => {
      
     }
   }, [])
   

   const openDialog = () => {
       setIsOpen(true)
   }

   const onDialogClose = (e) => {
       console.log('onDialogClose', e)
       setIsOpen(false)
   }

   const onDialogOk = (e) => {
       console.log('onDialogOk', e)
       setIsOpen(false)
   }
      // Custom navigation arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: 0,
    slidesToScroll: 1,
    arrows:false,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    
  };
  return (
    <>
<div className="slider-container">
    <Slider {...settings}>
      <div className="item mx-6">
      
        <div className="flex items-start justify-center h-full flex-col pt-[36px]">
          <h1 className="font-extrabold text-[48px] md:text-[48px] capitalize mb-2 relative z-10 text-uspfred">Superior Quality,<br></br>
Unbeatable Prices <span className="font-extrabold text-[30px] md:text-[36px] normal-case mb-2 relative z-10 text-carbons">with USPF</span></h1>
          
          <p className=" text-lg max-w-[600px] mt-6">
          Discover cost-effective, high-quality Print On Demand
services. Whether you're on Shopify, Etsy, or using private
contracts, contact us to learn how our top-notch DTG
services can save you more.
            </p>
            <div className="mt-4">
            <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Extensive Catalog
                  </p>
                  <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Seamless Order Management
                  </p>
                  <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Competitive Pricing
                  </p>
                </div>
        </div>
        <div><img src={display1} className={"w-75"}/></div>
       
      </div>
      <div className="item mx-6">
       
        <div className="flex items-start justify-center h-full flex-col pt-[36px]">
          <h1 className="font-bold text-[48px] md:text-[48px] capitalize mb-2 relative z-10 text-uspfred">Reliable Delivery Partners</h1>
          <h1 className="font-bold text-[30px] md:text-[36px] capitalize mb-2 relative z-10 text-uspfblue">Fast Turnaround Timing</h1>
          <p className=" text-lg max-w-[600px] mt-6">
          Our trusted network guarantees prompt, reliable shipping. With a proven track record, you can count on us for secure, efficient deliveries. Your shipments are in capable hands.
            </p>
            <div className="mt-4">
                  <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom "></span>
                    On-time deliveries.
                  </p>
                  <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Secure handling.
                  </p>
                  <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Real-time tracking.
                  </p>
                </div>
        </div>
        <div><img src={display3} className={"w-75"}/></div>
       </div>
      <div className="item  mx-6">
       
        <div className="flex items-start justify-center h-full flex-col pt-[36px]">
        <h1 className="font-extrabold text-[48px] md:text-[48px] capitalize mb-2 relative z-10 text-uspfred">Cutting-Edge Facility with<br></br>
<span className="font-extrabold text-[30px] md:text-[36px] normal-case mb-2 relative z-10 text-carbons">In-House Production</span></h1>
          
          <p className=" text-lg max-w-[600px] mt-6">
          With our cutting-edge Brother DTG Pro equipment and advanced Brown Firefly system, we deliver stunning digital
prints with unmatched color vibrancy. Whether you’re selling 1 or 1000+ shirts per day, we have the complete setup and capacity to print and fulfill your orders effortlessly.
            </p>
            <div className="mt-4">
            <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    US based location.
                  </p>
                  <p className="mb-0 text-uspfred text-2xl font-bold">
                    <span className="w-2 h-2 inline-block rounded-full mr-2 relative top-[-2px] bg-custom"></span>
                    Worldwide delivery.
                  </p>
                  
                </div>
        </div>
        <div><img src={display2} className={"w-75"}/></div>
       
      </div>
      
      
    </Slider>
  </div>
  <Dialog
    isOpen={dialogIsOpen}
    onClose={onDialogClose}
    onRequestClose={onDialogClose}
    width={800}
    closable={false}
>
    <h5 className="mb-4 font-bold">Information Update</h5>
    <p>We're reviewing our prices. As you know, costs are rising all over the world and we're no stranger to these increases. From blank apparel to ink, to shipping costs and more, we've seen a significant increase in costs across the board. We're still going to do our best to keep our services as affordable as possible.
                        Please see the prices mentioned below - will be effective 1/8/2024.</p><br/><p>
Thank you for your understanding - please feel free to reach out if you have any questions or concerns.</p>
                <p><img src="https://uspfdashboard.com/js/price-update.png" width="100%" /></p>
               
    <div className="text-right mt-6">
        <Button
            className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
            variant="plain"
            onClick={onDialogClose}
        >
            Okay
        </Button>
        
    </div>
</Dialog></>
  )
}

export default HomeDisplayTwo