import React from 'react'
import { useSelector } from 'react-redux'

const ProductImage = () => {
    const currentvariant = useSelector((state) => state.productDetailsInfo.data.activeVariant)
    const data = useSelector((state) => state.productDetailsInfo.data.productInfo)
    
    let currentImage;
    if(currentvariant["productImage"]){
      currentImage = currentvariant["productImage"]
    }
    else{
      currentImage = data["frontImages"][currentvariant["name"].replace(/ /g,"-")]
    }
    
  return (
    <div className='border border-slate-200 rounded text-center md:w-1/2 px-5 md:mr-5 '>
        <img src={currentImage} className='w-full max-w-[550px] mx-auto' />
        


    </div>
  )
}

export default ProductImage