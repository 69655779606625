import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { apiGetUserStores } from 'services/StoresService'


// export const getUserStoresData = createAsyncThunk(
//     'salesDashboard/data/getUserStoresData',
//     async (data) => {
//         const response = await apiGetUserStores(data)
//         return response.data
//     }
// )

export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'teamShop/data',
    initialState: {
        loading: true,
        storesData: {},
        shippingDetails:{name:"",address:"",address1:"",city:"",state:"",zip:"",country:""},
    },
    reducers: {
        setShippingDetails:(state,action) =>{
            state.shippingDetails = action.payload
        },
    },
    extraReducers: {
        // [getUserStoresData.fulfilled]: (state, action) => {
        //     state.storesData = action.payload.data
        //     state.loading = false
        // },
        // [getUserStoresData.pending]: (state) => {
        //     state.loading = true
        // },
    },
})
export const { setShippingDetails } = dataSlice.actions
export default dataSlice.reducer
