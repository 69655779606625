import React, { useState } from "react";
import feature from "assets/fake-data/landingpage-two/feature";
import feature3 from 'assets/images/connect/choose-us.png'
import feature5 from 'assets/images/connect/fullfilment-services.png'
import penruller from 'assets/images/icon/pen&ruller.svg'
import code from 'assets/images/icon/code.svg'
import headphones from 'assets/images/icon/headphones.svg'
import groupchat from 'assets/images/icon/groupchat.svg'
import { Steps } from "components/ui";
import ConnectBtn from "components/buttons/ConnectBtn";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
const LOGO_SRC_PATH = '/images'



const Instructions = ({store}) => {
    const [dataBlock] = useState({
        title: "How does US Print Factory work with",
        subTitle: "We are here to offer the highest quality printing services to you and your customers and the most competitive prices. The quick turnaround time makes us one of the leaders in the T-shirt printing business. We offer DTG, Screen-printing, Embroidery, Sublimation and Fulfillment services."
    })
    const storeList = {
        shopify:"Shopify",
        etsy:"Etsy",
        woocommerce:"WooCommerce",
        amazon:"Amazon",
    }

  return (
    <>
      <section className=" bg-center pb-[30px] bg-white relative">
      {/* <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[28px] md:text-[40px]  mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {`${dataBlock.title} ${storeList[store]}?`}
              </h2>
              
            </div>
          </div> */}
      <div className="w-full px-4 mb-8 py-6 md:!items-start  items-center flex md:!flex-row flex-col ">
         <div className="whystore  flex md:!flex-col flex-row md:w-3/4   connectins space-x-3">
         <div className="mr-4 flex items-center justify-center py-4">
         <img className="w-[120px]" src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/connect/${store}.png`} />
         </div>
            <div className="flex flex-col flex-1 ">
            <h1 className="textheadconnect  font-bold ">{`Connect your ${storeList[store]} store`}</h1>
            <div>{<span className="mb-[40px]"><a className={store==="shopify"?"text-shopify font-bold":store==="etsy"?"text-etsy font-bold":store==="woocommerce"?"text-woocommerce font-bold":store==="amazon"?"text-amazon font-bold":"text-purple-500 font-bold"} href="https://usprintfactory.com/app/sign-in?redirectUrl=/" target="_black">Connect</a>{` your existing ${storeList[store]} store or create a new one with our robust integration app.`}</span>}</div>
            </div>
         </div>
         <div className=" whystore  flex md:!flex-col flex-row md:w-3/4  connectins space-x-3">
         <div className="mr-4 flex items-center justify-center py-4">
         <img className="w-[120px] " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/connect/${store}-2.png`} />
         </div>
            <div className="flex flex-col flex-1 ">
            <h1 className="textheadconnect  font-bold ">{`Create & Manage products`}</h1>
            <div>{<span className=""><a className={store==="shopify"?"text-shopify font-bold":store==="etsy"?"text-etsy font-bold":store==="woocommerce"?"text-woocommerce font-bold":store==="amazon"?"text-amazon font-bold":"text-purple-500 font-bold"}  href="https://usprintfactory.com/app/sign-in?redirectUrl=/" target="_black">Create</a>{` designs from our USPF catalog and take control of your store all in one place.`}</span>}</div>
            </div>
         </div>
         <div className=" whystore  flex md:!flex-col flex-row md:w-3/4 connectins space-x-3">
         <div className="mr-4 flex items-center justify-center py-4">
         <img className="w-[120px] " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/connect/${store}-1.png`} />
         </div>
            <div className="flex flex-col flex-1 ">
            <h1 className="textheadconnect  font-bold ">We will Handle the fulfillment</h1>
            <div>{`USPF handles printing, packing, and shipping your orders, with automatic tracking updates shared with Shopify for seamless fulfillment.`}</div>
            </div>
         </div>
         {/* <div className=" whystore flex connectins space-x-3">
         <div className="mr-4 flex items-center justify-center py-4">
         <img className="w-[120px]  " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/connect-4.jpg`} />
         </div>
            <div className="flex flex-col flex-1">
            <h1 className="textheadconnect  font-bold ">{`USPF handles ${storeList[store]} fulfillment process & update tracking automatically`}</h1>
            <div>{`USPF handles the entire process, from printing and packing to shipping your orders. Rest assured, the shipment tracking information will be automatically shared with ${storeList[store]}, ensuring a smooth and efficient order fulfillment experience.`}</div>
            </div>
         </div> */}
        </div>
        <div className="container-fluid p-[30px]">
         
          <div className="grid grid-cols-1 text-center">
            <div className="text-center mb-8">
              <h2 className="font-bold text-[28px] md:text-[40px]  mb-2 relative z-10">
                
                Need Help?
              </h2>
              </div>

              <Link to={`https://usprintfactory.com/app/dashboard/connect/${store}`} className="mx-auto" target="_blank">
              <ConnectBtn text="Contact Us" icon={<HiChevronRight className='bg-white text-red-600 rounded-full' size={28} />} />
              </Link>
          </div>
          </div>
        {/* <div className="container-fluid px-[30px]">
         
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[28px] md:text-[40px]  mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {`${dataBlock.title} ${storeList[store]}?`}
              </h2>
              
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 ">
            <div className="connect-steps">
            <Steps vertical current={-1}>
                <Steps.Item title={`${storeList[store]} store`} description={<span className="mb-[40px]">{`Leverage our user-friendly one-time setup to effortlessly connect your ${storeList[store]} store with our robust integration app.`}</span>} />
                <Steps.Item
                    title={`Create & synchronize products from USPF to ${storeList[store]}`}
                    description={<span className="">{`Utilize the USPF catalog to handpick and personalize products of your choice. Easily migrate individual items or specific product sets from USPF to your ${storeList[store]} store with seamless convenience.`}</span>}
                />
                <Steps.Item
                    title="Manage orders effectively in one place"
                    description={<span>{`Easily manage order fulfillment for all items, whether it's the entire order or specific parts of it. Take full control of your received orders from multiple stores with effortless management capabilities.`}</span>}
                />
                <Steps.Item title={`USPF handles ${storeList[store]} fulfillment process & update tracking automatically`}
                description={<span className="">{`USPF handles the entire process, from printing and packing to shipping your orders. Rest assured, the shipment tracking information will be automatically shared with ${storeList[store]}, ensuring a smooth and efficient order fulfillment experience.`}</span>} />
            </Steps>
            </div>
            <div className="flex items-start">
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/6t0yo2q5uSw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Instructions;
