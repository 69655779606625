import ApiService from './ApiService'

export async function apiSignIn(data) {
    
    return ApiService.fetchData({
        url: 'team/user-login?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/team/register-invite?oauth_verifier=true',
        method: 'post',
        data,
    })
}
export async function verifyUser(data) {
    return ApiService.fetchData({
        url: '/team/verify-user',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: 'user/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: 'user/forgot-password?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: 'user/reset-password?oauth_verifier=true',
        method: 'post',
        data,
    })
}

export async function apiaddRemoveFav(data) {
    return ApiService.fetchData({
        url: 'user/addRemoveFav',
        method: 'post',
        data,
    })
}
export async function apiGetSalesOrderDetails(data) {
    return ApiService.fetchData({
        url: '/user/orders-details',
        method: 'post',
        data,
    })
}