import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp,verifyUser } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { values } from 'lodash'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)
    
    const signIn = async (values) => {
        try {
            
            const resp = await apiSignIn(values)
            if (resp.data) {
                
                const { token,activepath } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                navigate(
                    "/"+resp.data.user.shopPath
                )
                return {
                    status: 'success',
                    message: '',
                }
               
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        
        try {
            const resp = await apiSignUp(values)
            
            if (resp.data) {
                if(!resp.data.IsSuccessful){
                    return {
                        status: 'failed',
                        message: resp.data.message,
                    }
                }
                else if(resp.data.IsSuccessful){
                if (!resp.data.inviteActive) {
                    return {
                        status: 'failed',
                        message: resp.data.message,
                    }
                }
                else{
                    if(!resp.data.user){
                        return {
                            status: 'failed',
                            message: "This invite has expired or a different email was used.",
                        }
                    }
                    else{
                        const { token } = resp.data
                        dispatch(onSignInSuccess(token))
                        dispatch(
                            setUser(
                                resp.data.user || {
                                    avatar: '',
                                    userName: 'Anonymous',
                                    authority: ['USER'],
                                    email: '',
                                }
                            )
                        )
                        navigate(
                            "/"+resp.data.user.currentShop.path
                        )
                        return {
                            status: 'success',
                            message: '',
                        }
                    }
                  
                }
                   
                }
            }
               
            //     if(!tempshopid){
            //         const redirectUrl = query.get(REDIRECT_URL_KEY)
            //         navigate(
            //             redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            //         )
            //         return {
            //             status: 'success',
            //             message: '',
            //         }
            //     }
            //     else{
            //         const redirectUrl = `/dashboard/stores/shopify-auth-callback?code=${tempshopid}&shop=${tempshopifyshop}`;
            //         localStorage.removeItem("tempshopifyid")
            //         localStorage.removeItem("tempshopifyshop")
            //         navigate(
            //             redirectUrl
            //         )
            //         return {
            //             status: 'success',
            //             message: '',
            //         }
            //     }
               
            // }
        } catch (errors) {
            
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const verifyGetUser = async(values)=>{
        try {
            const resp = await verifyUser(values)
            
            if (resp.data) {
                if(!resp.data.IsSuccessful){
                    return {
                        status: 'failed',
                        message: resp.data.message,
                    }
                }
                else if(resp.data.IsSuccessful){

                
                    if(!resp.data.user){
                        return {
                            status: 'failed',
                            message: "This invite has expired or a different email was used.",
                        }
                    }
                    else{
                console.log(resp.data.user);
                       
                        dispatch(
                            setUser(
                                resp.data.user || {
                                    avatar: '',
                                    userName: 'Anonymous',
                                    authority: ['USER'],
                                    email: '',
                                }
                            )
                        )
                        
                        return {
                            status: 'success',
                            message: '',
                        }
                    }
                  
                
                   
                }
            }
               
            
        } catch (errors) {
            
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }
    const getPassword = async(values)=>{
        return {
            status: 'success',
            message: '',
        }
    }
    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.appHome)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }
    const signOutOnly = async () => {
        await apiSignOut()
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        signOutOnly,
        getPassword,
        verifyGetUser
    }
}

export default useAuth
