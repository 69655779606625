import team1 from "../images/team/1.jpg";
import team2 from "../images/team/2.jpg";
import team3 from "../images/team/3.jpg";
import team4 from "../images/team/4.jpg";

const team = [
  {
    id: 1,
    image: team1,
    name: "Hannah Sharpe",
    role: "CEO, Co-Founder",
  },
  {
    id: 2,
    image: team2,
    name: "Clee Carter",
    role: "Managing Partner",
  },
  {
    id: 3,
    image: team3,
    name: "Mary Merrill",
    role: "Operations Director",
  },
  {
    id: 4,
    image: team4,
    name: "Kyle Pratt",
    role: "Software Engineer",
  },
];

export default team