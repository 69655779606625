

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar, Badge, Button, Dialog, Spinner } from 'components/ui'
import { DataTable } from 'components/shared'
import { HiOutlineEye, HiOutlineTrash } from 'react-icons/hi'
import { FiPackage } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { getMembers, getUserOrders, setTableData } from '../store/dataSlice'
import { setSelectedMemberId, setSortedColumn } from '../store/stateSlice'
import { toggleDeleteConfirmation } from '../store/stateSlice'
import useThemeClass from 'utils/hooks/useThemeClass'

import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
// import AddNewMember from './AddNewMember'
import dayjs from 'dayjs'
import OrderDetails from './OrderDetails'
// import MemberDeleteConfirmation from './MemberDeleteConfirmation'

const orderStatusColor = {
   
    "Fulfilled": {
        label: 'Fulfilled',
        dotClass: 'bg-emerald-500',
        textClass: 'text-emerald-500',
    },
    "Pending": {
        label: 'pending',
        dotClass: 'bg-amber-500',
        textClass: 'text-amber-500',
    },
    "Hold": { label: 'On Hold', dotClass: 'bg-red-500', textClass: 'text-red-500' },
    "draft": { label: 'Draft', dotClass: 'bg-gray-500', textClass: 'text-gray-500' },
    "Que": { label: 'Que', dotClass: 'bg-purple-500', textClass: 'text-gray-500' },
}

const ActionColumn = ({ row }) => {
    const dispatch = useDispatch()
    const { textTheme } = useThemeClass()
    const navigate = useNavigate()

    const onEdit = () => {
        navigate(`/dashboard/product-view/${row._id}`)
    }

    const onDelete = () => {
        window.scrollTo(0, 0)
        
        dispatch(toggleDeleteConfirmation(true))
        dispatch(setSelectedMemberId(row._id))
    }

    return (
        <div className="flex justify-end text-lg">
            
            <span
                className="cursor-pointer p-2 hover:text-red-500"
                onClick={onDelete}
            >
                <HiOutlineTrash />
            </span>
        </div>
    )
}

const ProductColumn = ({ row }) => {
    const avatar = row.image ? (
        <Avatar src={row.image} />
    ) : (
        <Avatar icon={<FiPackage />} />
    )

    return (
        <div className="flex items-start flex-col space-y-2">
            
            {row.name && <span className={`ml-2 rtl:mr-2 font-semibold`}>{row.name}</span>}
            <span className={`ml-2 rtl:mr-2 font-semibold`}>{row.email}</span>
        </div>
    )
}
const OrderColumn = ({ row,open }) => {
    const { textTheme } = useThemeClass()
    const navigate = useNavigate()

    const onView = useCallback(() => {
        open({id:row.id,orderId:row.order_id});
    }, [navigate, row])

    return (
        <span
            className={`cursor-pointer select-none font-semibold hover:${textTheme}`}
            onClick={onView}
        >
            #{row.order_id}
        </span>
    )
}


const OrderTable = () => {
    const token = useSelector((state) => state.auth.session.token)
    
    const dispatch = useDispatch()
    const { pageIndex, pageSize, sort, query, total } = useSelector(
        (state) => state.userOrders.data.tableData
    )
    const filterData = useSelector(
        (state) => state.userOrders.data.filterData
    )
    const loading = useSelector((state) => state.userOrders.data.loading)
    const data = useSelector((state) => state.userOrders.data.memberList)
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [orderId, setorderId] = useState({})

    const openDialog = (order) => {
        setorderId(order)
        setIsOpen(true)
    }

    const onDialogClose = (e) => {
        console.log('onDialogClose', e)
        setIsOpen(false)
    }

    const onDialogOk = (e) => {
        console.log('onDialogOk', e)
        setIsOpen(false)
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sort])

    const tableData = useMemo(
        () => ({ pageIndex, pageSize, sort, query, total }),
        [pageIndex, pageSize, sort, query, total]
    )
    const dialogOpen = useSelector(
        (state) => state.userOrders.state.addNewmembers
    )
    const fetchData = () => {
        dispatch(getUserOrders({ pageIndex, pageSize, sort, query, filterData,token }))
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Order',
                accessor: 'order_id',
                sortable: true,
                Cell: (props) => <OrderColumn row={props.row.original} open={openDialog}/>
            },
            {
                Header: 'Date',
                accessor: 'date',
                sortable: true,
                Cell: (props) => {
                    const row = props.row.original
                    return (
                        <span>{dayjs.unix(row.createdAt).format('DD/MM/YYYY')}</span>
                    )
                },
            },
            {
                Header: 'Team',
                accessor: 'storeInfo',
                sortable: false,
                Cell: (props) => <span>{props.row.original.storeInfo.name}</span>,
            },
            {
                Header: 'Status',
                accessor: 'print_action_by_vendor',
                sortable: true,
                Cell: (props) => {
                    const { print_action_by_vendor } = props.row.original
                    return (
                        <div className="flex items-center">
                            <Badge
                                className={orderStatusColor[print_action_by_vendor].dotClass}
                            />
                            <span
                                className={`ml-2 rtl:mr-2 capitalize font-semibold ${orderStatusColor[print_action_by_vendor].textClass}`}
                            >
                                {orderStatusColor[print_action_by_vendor].label}
                            </span>
                        </div>
                    )
                },
            },
            {
                Header: '',
                id: 'action',
                accessor: (row) => row,
                Cell: (props) => <ActionColumn row={props.row.original} />,
            },
        ],
        []
    )

    const onPaginationChange = (page) => {
        const newTableData = cloneDeep(tableData)
        newTableData.pageIndex = page;
        
        dispatch(setTableData(newTableData))
    }

    const onSelectChange = (value) => {
        const newTableData = cloneDeep(tableData)
        newTableData.pageSize = Number(value)
        newTableData.pageIndex = 1
        dispatch(setTableData(newTableData))
    }

    const onSort = (sort, sortingColumn) => {
        const newTableData = cloneDeep(tableData)
        newTableData.sort = sort
        dispatch(setTableData(newTableData))
        dispatch(setSortedColumn(sortingColumn))
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                skeletonAvatarColumns={[0]}
                skeletonAvatarProps={{ className: 'rounded-md' }}
                loading={loading}
                pagingData={tableData}
                onPaginationChange={onPaginationChange}
                onSelectChange={onSelectChange}
                onSort={onSort}
                
            />
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
                width={1200}
                overlayClassName="h-full"
                closable={true}
                contentClassName="order-data"
            >
                <h5 className="mb-4">{`Order #${orderId.orderId}`}</h5>
                <p>
                   <OrderDetails order={orderId} />
                </p>
                <div className="text-right mt-6">
                    
                    <Button variant="solid" onClick={onDialogOk}>
                    Okay
                </Button>
                </div>
            </Dialog>
        </>
    )
}

export default OrderTable
