
import React from 'react'
import reducer from './store'
import { injectReducer } from 'store/index'
// import { useSelector } from 'react-redux'
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { useParams } from 'react-router-dom'
// import { apiGetProducts, apiGetShopProductData } from 'services/ProductService';
import ProductPage from './components/ProductPage';
import useAuth from 'utils/hooks/useAuth';
import Login from 'pages/Login';
import { useEffect } from 'react';

injectReducer('productSelection', reducer)

const ProductInfo = () => {
    const { authenticated } = useAuth();
   
  return (
    authenticated?
    <Login />
    :
    <ProductPage />
  )
}

export default ProductInfo