
import React from "react";

import FooterTwo from "components/footers/FooterTwo";
import ExtraHeroSection from "features/common/ExtraHeroSection";
import FAQPage from "./FAQPage";
import FAQ from "features/common/FAQ";

const PrivacyPolicy = () => {
  return (
    <>
      <ExtraHeroSection title="Privacy Policy" subTitle="Last modified: April 17th, 2023" />
      <section className="pt-10 pb-[170px] bg-light">
        <div className="container pp">
        <p>Thank you for using USPF (or as we are legally known, U.S. Print Factory). Before using or accessing our platform, please read this Privacy Policy carefully, it explains how we protect your data and respect your privacy.</p>
<p>&nbsp;</p>
<p><u>A. Yours and Our Responsibilities</u></p>
<p>Summary: This policy applies to all registered USPF users and also visitors of our platform. When handling the data of end-customers of USPF users, we act as a &ldquo;data processor&rdquo; and USPF merchants are responsible for ensuring their data is protected and handled in accordance to all applicable laws.</p>
<h4><strong>Who does this policy apply to?</strong></h4>
<p>If you are a USPF customer or subscriber, or just visiting our website, this policy applies to you.</p>
<p>For your customers who are just making a purchase of your goods through USPF, but are not registered users, we act as the &ldquo;data processor&rdquo; for you, the merchant: this means we only process their data to help us provide our service to you, or in accordance with your instructions, or as required by law. You are responsible for making sure that their personal data is treated in accordance with applicable data protection laws. That includes informing them how service providers (like us) collect and use data on your behalf.</p>
<p>Our responsibilities</p>
<p>If you are a registered USPF customer or a visitor to our website we act as the &ldquo;data controller&rdquo; of personal data. This means we determine how and why your data are processed.</p>
<h4><strong>Your Responsibilities</strong></h4>
<p>Read this Privacy Policy</p>
<p>If you provide us with personal information about other people (i.e. if you are registering on behalf of someone else, or you are transmitting data from your customers), or if others give us your information, we will only use that information for the specific reason for which it was provided to us. By submitting the information, you confirm that you have the right to authorize us to process it on your behalf in accordance with this Privacy Policy.</p>
<p><u>B. When does USPF collect your data?</u></p>
<p>Summary: USPF collects your data in all of our interactions from the moment you visit our website. Some data is collected automatically, and some data is only collected with your consent.</p>
<p>With your consent we collect your data when:</p>
<p>You register for an account</p>
<p>You upload content to USPF using our Product Designer tool</p>
<p>You integrate USPF through API&rsquo;s (Ours, 3rd party storefronts, website builders, etc&hellip;.)</p>
<p>You publish products to 3rd party sales channels</p>
<p>We email each other</p>
<p>You contact our support and/or success team (we may also collect call audio, which we will disclose on the call);</p>
<p>When you connect with us on social media<br></br>You request a demo of USPF<br></br>You participate in a user research study<br></br>You opt-in to marketing messages<br></br>Enter a sweepstakes or contest, or register for a promotion;<br></br>Participate in our user referral program;<br></br>Automatically we collect your data when:</p>
<p>You browse any part of our website</p>
<p>You use USPF to create products</p>
<p>You read our help section</p>
<p>You order products from USPF</p>
<p><u>C. Categories of data collected</u></p>
<p>Summary: USPF collects different kinds of data and we process that data for different purposes.</p>
<p>Contact details and basic information</p>
<p>Your name, address, telephone number, email address&hellip;</p>
<p>Financial information</p>
<p>Your PayPal account email, credit/debit card details&hellip;</p>
<h4><strong>Data from your content and products</strong></h4>
<p>The products you have designed, the artwork you have uploaded&hellip;</p>
<h4><strong>Contractual Data</strong></h4>
<p>The sales you have made, your customers&rsquo; data, store name, order details&hellip;</p>
<h4><strong>Data that identifies you</strong></h4>
<p>Your IP address, login information, browser type and version, time zone setting, browser plug-in types, demographic information about where you might be, operating system and version&hellip;</p>
<h4><strong>Data on how you use USPF</strong></h4>
<p>Your URL clickstreams (the path you take through our site), products/services viewed, page response times, download errors, how long you stay on our pages, what you do on those pages, how often, and other actions&hellip;</p>
<h4><strong>What about really sensitive data?</strong></h4>
<p>We don&rsquo;t collect any &ldquo;sensitive data&rdquo; about you (like racial or ethnic origin, political opinions, religious/philosophical beliefs, trade union membership, genetic data, biometric data, health data, data about your sexual life or orientation, and offences or alleged offences).</p>
<h4><strong>What about children&rsquo;s data?</strong></h4>
<p>USPF is a business-to-business service directed to and intended for use only by those who are 18 years of age or over. We do not target USPF at children, and we do not knowingly collect any personal data from any person under 16 years of age.</p>
<p><u>D. How and why we use your data</u></p>
<p>Summary: According to Data Protection Law, we can only process your data for specific purposes and only when we have a legal basis to do so. The reasons are outlined below.</p>
<h4><strong>Keeping USPF running</strong></h4>
<p>Managing your requests (like creating products, integrating sales channels, completing transactions and fulfilling orders), login and authentication, remembering your settings, processing payments, hosting and back-end infrastructure.</p>
<p>Legal basis for processing data: contract, legitimate interests</p>
<h4><strong>Improving USPF</strong></h4>
<p>Testing features, interacting with feedback platforms and questionnaires, managing landing pages, heat mapping our site, traffic optimization and data analysis and research, including profiling and the use of machine learning and other techniques over your data and in some cases using third parties to do this.</p>
<p>Legal basis for processing data: contract, legitimate interests</p>
<h4><strong>Customer support</strong></h4>
<p>Notifying you of any changes to our service, solving issues via live chat support, phone or email including any bug fixing.</p>
<p>Legal basis for processing data: contract</p>
<h4><strong>Marketing purposes (with your consent)</strong></h4>
<p>Sending you emails and messages about new features, products and services, and content.</p>
<p>Legal basis for processing data: consent</p>
<p><u>E. What these legal bases mean</u></p>
<h4><strong>Consent:</strong></h4>
<p>You have given clear consent for us to process your information for a specific purpose.</p>
<p>You can withdraw your consent at any time by emailing info@usprintfactory.com or by navigating to your profile page and managing your data settings.</p>
<h4><strong>Contract:</strong></h4>
<p>Processing your data is necessary for a contract you have with us, or because we have asked you to take specific steps before entering into that contract.</p>
<h4><strong>Legitimate interests:</strong></h4>
<p>Processing your data is necessary for our legitimate interests or the legitimate interests of a third party, provided those interests are not outweighed by your rights and interests.</p>
<h4><strong>These legitimate interests are:</strong></h4>
<p>- Gaining insights from your behavior on our website or in our app<br></br>- Delivering, developing and improving the USPF service<br></br>- Enabling us to enhance, customize or modify our services and communication<br></br>- Determining whether marketing campaigns are effective<br></br>- Enhancing data security</p>
<p>In each case, these legitimate interests are only valid if they are not outweighed by your rights and interests.</p>
<p><u>F. Your privacy rights and choices</u></p>
<p>Summary: When it comes to your data and privacy, you have a lot of control for what USPF can do. All of your data settings can be managed from your USPF account page, or by emailing us at info@usprintfactory.com. These include your rights to review, correct and delete your data.</p>
<p>Your rights</p>
<p>You can exercise your rights at any time by sending us an email at info@usprintfactory.com or by navigating to your USPF account page.</p>
<p>You have the right to access information we hold about you</p>
<p>This includes the right to ask us supplementary information about:</p>
<p>- The categories of data we&rsquo;re processing</p>
<p>- The purposes of data processing</p>
<p>- The categories of third parties to whom the data may be disclosed</p>
<p>- How long the data will be stored (or the criteria used to determine that period)</p>
<p>- Your other rights regarding our use of your data</p>
<p>We will provide you with the information within one month of your request, unless doing so would adversely affect the rights and freedoms of other (e.g. another person&rsquo;s confidentiality or intellectual property rights). We&rsquo;ll tell you if we can&rsquo;t meet your request for that reason.</p>
<h4><strong>You have a right to see what personal information has been shared with third parties.</strong></h4>
<p>California Civil Code Section &sect; 1798.83 allows users of our Services that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. If you would like to make such a request, please send an email to info@usprintfactory.com</p>
<h4><strong>You have the right to make us correct any inaccurate personal data about you</strong></h4>
<p>If after accessing your information, you believe that we have incorrect or inaccurate data, you can request us to change or correct it.</p>
<h4><strong>You can object to us using your data for profiling you or making automated decisions about you</strong></h4>
<p>We may use your data to determine whether we should let you know information that might be relevant to you (for example, tailoring emails to you based on your behavior). Otherwise, the only circumstances in which we will do this is to provide the USPF service to you.</p>
<h4><strong>You have the right to port your data to another service</strong></h4>
<p>We will give you a copy of your data in CSV or JSON so that you can provide it to another service. If you ask us and it is technically possible, we will directly transfer the data to the other service for you. We will not do so to the extent that this involves disclosing data about any other individual.</p>
<h4><strong>You have the right to be &lsquo;forgotten&rsquo; by us</strong></h4>
<p>You can do this by asking us to delete any personal data we hold about you, if it is no longer necessary for us to hold the data for purposes of your use of USPF.</p>
<p>You have the right to lodge a complaint regarding our use of your data</p>
<p>Please tell us first, so we have a chance to address your concerns.</p>
<p>The US does not view privacy in the same way and has not established processes for lodging complaints for the type of data we are collecting. If you have a complaint about our use of your data, reach out to us at info@usprintfactory.com and we will address your concerns as best we can.</p>
<h4><strong>Your choices</strong></h4>
<p>You can choose not to provide us with personal data</p>
<p>If you choose to do this, you can continue to use the website and browse its pages, but we will not be able to process transactions or fulfill orders without personal data.</p>
<h4><strong>You can turn off cookies in your browser by changing its settings</strong></h4>
<p>You can block cookies by activating a setting on your browser allowing you to refuse cookies. You can also delete cookies through your browser settings. If you turn off cookies, you can continue to use the website and browse its pages, but USPF and certain services (like Intercom) will not work effectively.</p>
<h4><strong>You can ask us not to use your data for marketing</strong></h4>
<p>We will inform you (before collecting your data) if we intend to use your data for marketing and if third parties are involved. You can opt out from marketing by emailing us at info@usprintfactory.com, or by navigating to your account page and managing your data settings.</p>
<p><u>G. How secure is the data we collect?</u></p>
<p>Summary: We do a lot of things to keep your data safe on our end, but you are also responsible for keeping your account information safe and secure. If you feel that your data has been breached, immediately change your password and get in touch with our support team.</p>
<p>We have physical, electronic, and managerial procedures to safeguard and secure the information we collect. For more information on our efforts to ensure your data is held in a secure manner, please contact us at info@usprintfactory.com</p>
<h4><strong>Our Infrastructure</strong></h4>
<p>USPF operates on servers and cloud components that comply with strict international standards. Access to infrastructure at USPF is securely controlled and available from specific locations only. This access is limited to specific employees only and protected by 2-factor authentication.</p>
<p>Data is stored in the US region by default on AWS/Azure servers and is continuously backed up. Our infrastructure&rsquo;s data centers and cloud services from providers are protected by physical access controls, intrusion and fire detection systems and 24/7 professional security staff.</p>
<h4><strong>Monitoring</strong></h4>
<p>We have continuous resource and infrastructure access monitoring in operation 24/7, 365 days a year. Any alerts generated by our monitoring system are sent to team members immediately and actioned. USPF uses both third parties and in-house scans for vulnerability testing and app protection (WAF).</p>
<h4><strong>Training and audits</strong></h4>
<p>Regular third party audits are conducted at USPF to ensure we use the latest technologies and best practices to protect data. All employees at USPF are trained in data security and are bound by strict confidentiality provisions.</p>
<h4><strong>And please remember:</strong></h4>
<p>- You provide personal data at your own risk: unfortunately, no data transmission is guaranteed to be 100% secure</p>
<p>- You are responsible for the security of your username and password: keep them secret and safe.</p>
<p>- If you believe your privacy has been breached, please change your password as soon as possible and contact us immediately on info@usprintfactory.com or via Intercom using the chat icon.</p>
<p><u>H. Where do we store the data?</u></p>
<p>The personal data we collect is processed at our offices in San Francisco and Riga and in any data processing facilities operated by the third parties identified below.</p>
<p>By submitting your personal data, you agree to this transfer, storing or processing by us. If we transfer or store your information outside the EEA in this way, we will take steps to ensure that your privacy rights continue to be protected as outlined in this Privacy Policy.</p>
<p><u>I. How long do we store your data?</u></p>
<p>We will archive and stop actively using any personal identifiable information about you within 6 months from the last time you used USPF. We will delete your personal data from our archives no later than 6 years from the last time you used USPF or upon your request.</p>
<p><u>J. Third parties who process your data</u></p>
<p>Summary: To effectively run USPF we need to partner with various third parties around the world. These include our print providers that work with us to offer and fulfill products and services and also tools like Google Apps and Intercom that allow us to communicate and deliver our service to you. This section outlines who we work, for what purposes and where you can find more information about their privacy policies.</p>
<p>To deliver the best possible USPF experience we often have to partner with third parties to deliver services that we cannot. These include our print partners that we work with to offer products and fulfill orders, services that support our communications, both internal for our team and external when we communicate with you, tools that keep our web platform and API running, as well as services that allow us to study the use of our platform and learn how best to develop and design new features, messages and offers.</p>
<p>When we do this, sometimes it is necessary for us to share your data with them in order to get these services to work well. Your data is shared only when strictly necessary and according to the safeguards and good practices detailed in this Privacy Policy.</p>
<h4><strong>Our Print Partners</strong></h4>
<p>USPF partners with a wide and constantly expanding network of print providers all over the world. Current partners can be seen at https://app.USPF.com/print-providers.</p>
<p>These print providers offer various products and print on demand services to our users. Each of our print partners operates as a &ldquo;data processor&rdquo; when an order is submitted and they sign a Data Processing Agreement with USPF outlining how they handle and protect our users&rsquo; data.</p>
<p>This Data Processing Agreement outlines:</p>
<p>- What personal data is being sent</p>
<p>- How that data needs to be secured and kept confidential</p>
<p>- The purposes for which that data will be processed</p>
<p>- How long that data will be stored</p>
<p>- How they respond when users exercise their rights to privacy</p>
<p>- How they respond if a data breach occurs</p>
<p><u>K. Cookies</u></p>
<p>We use cookies. Unless you adjust your browser settings to refuse cookies, we (and these third parties) will issue cookies when you interact with USPF. These may be &lsquo;session&rsquo; cookies, meaning they delete themselves when you leave USPF, or &lsquo;persistent&rsquo; cookies which do not delete themselves and help us recognize you when you return so we can provide a tailored service.</p>
<h4><strong>How can I block cookies?</strong></h4>
<p>You can block cookies by activating a setting on your browser allowing you to refuse the setting of cookies. You can also delete cookies through your browser settings. If you use your browser settings to disable, reject, or block cookies (including essential cookies), certain parts of our website will not function fully. In some cases, our website may not be accessible at all. Please note that where third parties use cookies we have no control over how those third parties use those cookies.</p>
<p><u>L. What if we change this Policy?</u></p>
<p>We reserve the right to make changes to this Privacy Policy at any time to reflect changes in the law, our data collection and use practices, the features of our Services, or advances in technology. Please check this page periodically for changes. If we make any material changes to this Privacy Policy, we will notify you via the email address provided for Registered Users and we will also post a notice on our home page and post the updated Privacy Policy here.</p>
<p>The date the Privacy Policy was last revised is identified at the top of the page. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will mean you consent to and accept those changes. If you do not consent to such changes, you should stop using our Services.</p>
<p>Contact us at any time at info@usprintfactory.com if you have more questions about this privacy policy, your rights, or our privacy practices.</p>
<p></p>
    </div></section>
      <FooterTwo/>
    </>
  );
};

export default PrivacyPolicy;
