
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/ui'
import { HiCheck, HiCheckCircle, HiMinus, HiPlus } from 'react-icons/hi'

import { setActiveVariant } from '../store/dataSlice'
const ProductDetails = () => {
    const dispatch = useDispatch();
    const currentvariant = useSelector((state) => state.productDetailsInfo.data.activeVariant);
    
    const data = useSelector((state) => state.productDetailsInfo.data.productInfo)
    
    
   
    const changeColor = (pinfo=null)=>{
            
            dispatch(setActiveVariant(pinfo))
            
    }
    
    
    
  return (
    <div className='md:w-1/2 mt-5'>
    <div className='flex flex-col '>
    {<span className='mb-3 text-xl md:text-3xl font-bold'>{data.Title?data.Title:data.name}</span>}
    <span className='mb-3 text-2xl'>${data.StartingPrice?data.StartingPrice:data.sizeVariants[0]["price"]}</span>
    
    <div className='py-2'></div>
    <div className='flex flex-col mt-3'>
    <h3 className='font-bold'>Printing Options</h3>
    <div className='flex space-x-4 mt-3'>
        <span className='rounded-md px-3 py-2 border border-uspfredlow'>DTG</span>
    </div>
    </div>
    <div className='py-2'></div>
    <div className='flex flex-col mt-5'>
    <h3 className='font-bold'>Available Colors</h3>
    <div className='flex  mt-3 flex-wrap '>
    {(data["colorVariants"] && data["colorVariants"].map((color) => 
                        <div key={color.name} onClick={()=>changeColor(color)} title={`${color.name} - ${color.code}`} className={currentvariant.name === color.name?'w-8 h-8 rounded-full  mb-2 mr-3 flex items-center justify-center border-2 border-black':'w-8 h-8 rounded-full  mb-2 mr-3 flex items-center justify-center border-2 border-white'} style={color.imgcolor?{backgroundImage:`url("${color.imgcolor}")`}:{backgroundColor:`${color.code}`}}>
                            {currentvariant.name === color.name && <HiCheck className='' size={18} />}
                        </div>
                        
                        ))}
    </div>
    </div>
    <div className='py-2'></div>

    <div className='flex flex-col mt-5'>
    <h3 className='font-bold'>Available Sizes & Price</h3>
    <div className='flex space-x-4 mt-3'>
        {
            data.sizeVariants.map(s =>  <span className='rounded-md px-3 py-2 flex flex-col text-center font-semibold border border-uspfredlow'>
                <span>{s.name}</span>
                <span className='text-sm font-semibold'>${s.price}</span></span>)
        }
       
    </div>
    </div><div className='py-5'></div>
        <div>
        <a href={'https://usprintfactory.com/app/dashboard/product/create-design/'+data["_id"]} target='_blank'><Button  variant="twoTone" 
                     className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]">
                  Start Designing
        </Button>
        </a>
        </div>
    </div>
    </div>
  )
}

export default ProductDetails