import React from 'react'
import { useSelector } from 'react-redux'

const ProductImage = () => {
    const currentvariant = useSelector((state) => state.productSelection.data.activeVariant)
  return (
    <div className='border border-slate-200 rounded text-center '>
        { currentvariant.image && currentvariant.image_back
        ?<><img src={currentvariant.image} className='w-1/2 mx-auto max-w-[550px]' /><img className='w-1/2 mx-auto max-w-[550px]' src={currentvariant.image_back} /></>
        :currentvariant.image
        ?<><img src={currentvariant.image} className='w-full max-w-[550px]' /></>
        :<><img src={currentvariant.image_back} className='w-full max-w-[550px]' /></>
      }
        


    </div>
  )
}

export default ProductImage