import React from "react";
import aboutImg from '../../assets/images/about_page_img.jpg'

const AboutSection = () => {
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1">
            <div>
              <img
                src={aboutImg}
                className="rounded-[30px] mx-auto block"
                alt="about-image"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-16 gap-[30px]">
            <div>
              <h2 className="font-bold capitalize text-[32px]">
                We’re an aspiring team of coders and creatives
              </h2>
              <div className="mt-6">
                <p className="mb-1 text-muted">
                  <i className="mdi mdi-check-circle text-custom text-xl relative top-[2px]"></i>{" "}
                  We Commit To Our Craft.
                </p>
                <p className="mb-0 text-muted">
                  <i className="mdi mdi-check-circle text-custom text-xl relative top-[2px]"></i>{" "}
                  We Dig Deeper In Workspace.
                </p>
              </div>
            </div>
            <div>
              <p className="text-muted mb-4">
                Today, we're proud to empower individuals and small business
                owners around the world. Everyone deserves a website, and we're
                excited to see what you create. Simplify Communications, Elevate
                Experiences, Engage And Inspire People Everywhere.
              </p>
              <p className="text-muted">
                Good Design And Good Relationships Come From Collaboration.
                We're Excited To Start A Visual Dialogue, Learn About You, And
                Make Something Beautiful Together.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
