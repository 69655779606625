import chartBar from '../../images/icon/chart-bar.svg'
import dollar from '../../images/icon/dollar.svg'
import clock from '../../images/icon/clock.svg'
import flower from '../../images/icon/flower.svg'


const counter = [
    {
        id:1,
        image: chartBar,
        startAt : 0,
        endAt: 99.45,
        suffix: "%",
        prefix: "",
        description:'Their separate existence is a myth. For science, sport, etc, their pronunciation.'
    },
    {
        id:2,
        image: dollar,
        startAt : 0,
        endAt: 12,
        suffix: "M",
        prefix: "",
        description:'Their separate existence is a myth. For science, sport, etc, their pronunciation.'
    },
    {
        id:3,
        image: clock,
        startAt : 2800,
        endAt: 3200,
        suffix: "+",
        prefix: "",
        description:'Their separate existence is a myth. For science, sport, etc, their pronunciation.'
    },
    {
        id:4,
        image: flower,
        startAt : 22,
        endAt: 45,
        suffix: "",
        prefix: "R",
        description:'Their separate existence is a myth. For science, sport, etc, their pronunciation.'
    },
]

export default counter