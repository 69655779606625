import React from "react";
import FooterOne from "../components/footers/FooterOne";
import ContentSection from "../features/common/ContentSection";
import FAQ from "../features/common/FAQ";
import NeedHelp from "../features/common/NeedHelp";
import ServiciesSection from "../features/servicies/ServiciesSection";
import MainHeroSection from "../features/common/MainHeroSection";

const Servicies = () => {
  return (
    <>
      <MainHeroSection
        title="Services"
        subTitle="Our offer data science and analytics services."
      />
      <ServiciesSection />
      <ContentSection />
      <FAQ />
      <NeedHelp />
      <FooterOne />
    </>
  );
};

export default Servicies;
