import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import display1 from "assets/images/features/wslide-1.jpg";
import display2 from "assets/images/features/wslide-2.jpg";
import display3 from "assets/images/features/wslide-3.jpg";
import priceslide from "assets/images/features/priceslide-1.jpg";
import start1 from "assets/images/features/start-1.jpg";
import start2 from "assets/images/features/start-2.jpg";
import start3 from "assets/images/features/start-3.jpg";
import { Button, Dialog } from "components/ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import ConnectBtn from "components/buttons/ConnectBtn";

import { Table } from 'components/ui'



import {  IoChevronForwardCircleOutline } from "react-icons/io5";
const { Tr, Th, Td, THead, TBody } = Table;
const WhyContentTwo = () => {
   const [dialogIsOpen, setIsOpen] = useState(false)
   useEffect(() => {
    // openDialog()
   
     return () => {
      
     }
   }, [])
   

      // Custom navigation arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: 0,
    slidesToScroll: 1,
    arrows:false,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    
  };
  return (
    <>
    <div className="flex flex-col items-center mt-4 px-2">
  <div className="flex flex-col space-y-3 justify-center w-full items-center mx-2 ">
    <h4 className="text-2xl font-bold">Price Comparison- US Print Factory vs Industry Leading Competitor</h4>
    <Table className="border-2 md:!w-[850px]">
                <THead>
                    <Tr>
                        <Th className="!text-xl normal !normal-case">Product</Th>
                        <Th className="!text-xl normal !normal-case">Competitor</Th>
                        <Th className="!text-xl normal font-bold !text-uspfred !mx-4">USPF</Th>
                    </Tr>
                </THead>
                <TBody>
                    <Tr className="px-3">
                        <Td className="px-3">Glidan T-Shirt (5000)</Td>
                         <Td className="px-3">$11.95</Td>
                        <Td className="px-3 font-bold text-lg">$5.99</Td>
                        
                        
                        
                        
                        
                    </Tr>
                    <Tr>
                    <Td>Bella+Canvas T-Shirt (3001)</Td>
                    <Td className="">$12.95</Td>
                        <Td className="font-bold text-lg">$9.49</Td>
                    </Tr>
                    <Tr>
                    <Td>Next Level Cotton T-Shirt (3600)</Td>
                    <Td className="">$14.50</Td>
                        <Td className="font-bold text-lg">$8.29</Td>
                    </Tr>  <Tr>
                    <Td>Comfort Colors T-Shirt (1717)</Td>
                    <Td className="">$14.18</Td>
                        <Td className="font-bold text-lg">$11.19</Td>
                    </Tr>  <Tr>
                    <Td>Next Level CVC T-Shirt (6210)</Td>
                    <Td className="">$12.95</Td>
                        <Td className="font-bold text-lg">$8.99</Td>
                    </Tr>  <Tr>
                    <Td>Glidan Hooded Sweatshirt (18500)</Td>
                    <Td className="">$23.50</Td>
                        <Td className="font-bold text-lg">$14.99</Td>
                    </Tr>
                    <Tr>
                    <Td>Glidan Sweatshirt (18000)</Td>
                        
                    <Td className="">$21.95</Td>
                        
                        
                        
                        <Td className="font-bold text-lg">$13.99</Td>
                    </Tr>
                </TBody>
            </Table>
  </div>
  <div className="flex justify-center h-full flex-col pt-[16px] px-2  w-full mt-3 mb-3  ">
          <span className="font-extrabold text-2xl    normal-case mb-2 relative z-10 text-center ">Create your free account
          and start selling today</span>
          
          <div className="text-[18px] md:text-[30px] font-bold text-left ">
          <Link to={`https://usprintfactory.com/app/`} target="_blank" className=""><ConnectBtn btnClass="px-3 font-bold text-lg " text={"Start for free"} /></Link>
            </div>
           
        </div>
  
  </div>
<div className="slider-container">
    <Slider {...settings}>
      <div className="item mx-6">
      
        <div className="flex items-start justify-center h-full flex-col pt-[18px]">
          <span className="text-2xl font-bold normal-case mb-2 leading-normal relative z-10 ">
            Maximize Your Profits with Our Great Prices</span>
          
          <div className="text-lg leading-snug max-w-[600px]  mt-2">
          At USPF, we keep prices low to boost your business. Our strong supplier relationships and refined processes let us offer top-quality products at unbeatable prices.
            </div>
            
        </div>
        <div className="flex items-center justify-center"><img src={display1} className={"md:w-3/4  w-3/4"}/></div>
       
      </div>
      <div className="item mx-6 mt-3">
       
        <div className="flex items-start justify-center h-full flex-col pt-[18px]">
          
        <span className="text-2xl font-bold normal-case mb-2 leading-normal relative z-10 ">Super Fast Fullfillment</span>
        <div className="text-lg leading-snug max-w-[600px]  mt-2">
          At USPF, we prioritize speed and efficiency! We process and ship orders within 24-48 hours, often even sooner. Our quick fulfillment keeps your business running smoothly and your customers happy.
            </div>
            
        </div>
        <div className="flex items-center justify-center"><img src={display2} className={"md:w-3/4  w-3/4"}/></div>
       </div>
      <div className="item  mx-6  card">
       
        <div className="flex items-start justify-center h-full flex-col pt-[18px]">
        <span className="text-2xl font-bold normal-case mb-2 leading-normal relative z-10 ">Extensive Product Selection</span>
          
        <div className="text-lg leading-snug max-w-[600px]  mt-2">
          Discover a vast array of products with USPF! Our extensive catalog offers high-quality items, from trendy apparel to unique accessories, ensuring you find the perfect fit for your brand and meet your customers' diverse needs.
            </div>
            
        </div>
        <div className="flex items-center justify-center"><img src={display3} className={"md:w-3/4 w-3/4"}/></div>
       
      </div>
      
      
    </Slider>
  </div>
  
  <div className="flex px-3 md:flex-row flex-col md:!items-start items-center mt-8 w-full md:space-y-0 space-y-6 ">
    <div className="flex w-full  md:w-1/2 px-2 flex-col space-y-3">
        <div className="flex items-center justify-center space-x-3 flex-1 "><IoChevronForwardCircleOutline size={30} /><span className="text-2xl font-extrabold text-center">Create USPF Account</span></div>
        <div className="flex items-center justify-center h-[90px]"><img src={start1} className={"h-full"}/></div>
        <div className="text-center">Begin your journey by setting up a USPF
fulfillment store. Next, seamlessly connect your
integrated selling store with USPF.</div>
    </div>
    <div className="flex w-full  md:w-1/2 px-2 flex-col space-y-3">
        <div className="flex items-center justify-center space-x-3 flex-1 "><IoChevronForwardCircleOutline size={30} /><span className="text-2xl font-extrabold text-center">Design and sell Products</span></div>
        <div className="flex items-center justify-center h-[90px]"><img src={start2} className={"h-full"}/></div>
        <div className="text-center">Select products from our catalog, unleash your creativity
with unique designs, and effortlessly add them to your
store. Sit back and await incoming orders as your
personalized products charm your customers.</div>
    </div>
    <div className="flex w-full px-2  md:w-1/2 flex-col space-y-3">
        <div className="flex items-center justify-center space-x-3 flex-1 "><IoChevronForwardCircleOutline size={30} /><span className="text-2xl font-extrabold text-center">Fulfilled by US Print Factory</span></div>
        <div className="flex items-center justify-center h-[90px]"><img src={start3} className={"h-full"}/></div>
        <div className="text-center">Select products from our catalog, unleash your creativity
with unique designs, and effortlessly add them to your
store. Sit back and await incoming orders as your
personalized products charm your customers.</div>
    </div>
    
  </div>
  
  </>
  )
}

export default WhyContentTwo