import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'userOrders/state',
    initialState: {
        deleteConfirmation: false,
        addNewmembers:false,
        sortedColumn: false,
        selectedMemberId:null,
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        toggleAddNewmembers:(state,action)=>{
            state.addNewmembers = action.payload
        },
        setSelectedMemberId:(state,action)=>{
            state.selectedMemberId = action.payload
        }
        
    },
})

export const { toggleDeleteConfirmation,setSelectedMemberId, setSortedColumn,toggleAddNewmembers } =
    stateSlice.actions

export default stateSlice.reducer
