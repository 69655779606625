import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

export default MainLayout;
