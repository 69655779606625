import React from "react";
import overviewData from "../../assets/fake-data/overview";
import contact from "../../assets/fake-data/landingpage-three/contact";
import ContactForm from "./ContactForm";

const ContactSection = () => {
  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className=" contact-page ">
          <div className="grid grid-rows-2 md:grid-rows-2  info items-start">
            <div>
            Got a question? We'd love to hear from you. Send us a message and we'll respond as soon as possible. It is very important for us to keep in touch with you, so we are always ready to answer any question that interests you. Shoot!
            </div>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-[30px] items-start">
            {contact.map((x) => {
              return (
                <div key={x.id} className="text-center">
                  <div className="bg-custom/20 w-[50px] h-[50px] flex items-center mx-auto justify-center leading-[50px] rounded-lg">
                    <img
                      src={x.img}
                      alt={x.img}
                      className="mx-auto inline-block h-[30px]"
                    />
                  </div>
                  <h4 className="mt-4 font-bold text-xl">{x.name}</h4>
                  <p className="mt-2 text-muted">{x.text}</p>
                  <p className="mt-1 text-muted">{x.subText}</p>
                </div>
              );
            })}
          </div>
          </div>
          <ContactForm />
          </div>
          <div className="bg-dark/[0.04] h-[1px] my-[70px] w-full inline-block"></div>
          
        </div>
      </section>
    </>
  );
};

export default ContactSection;
