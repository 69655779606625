import React, { useState } from "react";
import feature from "../../assets/fake-data/landingpage-two/feature";
import feature3 from '../../assets/images/connect/choose-us.png'
import feature5 from '../../assets/images/connect/fullfilment-services.png'
import penruller from '../../assets/images/icon/pen&ruller.svg'
import code from '../../assets/images/icon/code.svg'
import headphones from '../../assets/images/icon/headphones.svg'
import groupchat from '../../assets/images/icon/groupchat.svg'
import { Button,Dialog } from "components/ui";
import ContactForm from "features/contact/ContactForm";
import ConnectBtn from "components/buttons/ConnectBtn";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
const LOGO_SRC_PATH = '/images'



const Features = () => {
  const [dialogIsOpen, setIsOpen] = useState(false)
  const [msgType, setmsgType] = useState("Embroidery")

    const [dataBlock] = useState({
        title: "We Offer a Wide Range of Printing Solutions",
        subTitle: "We are here to offer the highest quality printing services to you and your customers and the most competitive prices. The quick turnaround time makes us one of the leaders in the T-shirt printing business. We offer DTG, Screen-printing, Embroidery, Sublimation and Fulfillment services."
    })
    const openDialog = () => {
      setIsOpen(true)
  }

  const onDialogClose = (e) => {
      console.log('onDialogClose', e)
      setIsOpen(false)
  }

  const onDialogOk = (e) => {
      console.log('onDialogOk', e)
      setIsOpen(false)
  }
  return (
    <>
      <section className=" bg-center py-5 md:py-10 bg-white relative">
        <div className="container">
         
         
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            <div>
              <img
                src={feature3}
                alt="business"
                className="mx-auto"
              />
            </div>
            <div>
              <h3 className="md:text-[32px] text-2xl leading-[1.4] font-bold">
              Why Choose Us
              </h3>
              <div className="space-y-4 mb-4">
                <div className="flex items-start gap-5 mt-8">
                <div className="">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-0 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold capitalize  ">
                    high quality printing
                    </h3>
                  </div>
                </div>

                <div className="flex items-center gap-5 mt-4">
                  <div className="">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-0 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold  capitalize">
                    fast printing
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-4">
                  <div className="">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-0 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold  ">
                    Direct to Garment
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-4">
                  <div className="">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-0 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold  capitalize">
                    competitive prices
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-4">
                  <div className="">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-0 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold  capitalize">
                    fulfillment services
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-4">
                  <div className="">
                    <i className="mdi mdi-check bg-custom text-white text-lg leading-[26px] h-[26px] w-[26px] relative top-0 text-center rounded-full inline-block"></i>
                  </div>
                  <div>
                    <h3 className="text-lg font-bold  capitalize">
                    quick turnarounds
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] mt-16 items-center">
            <div>
              <h3 className="text-[32px] font-bold leading-[1.4] max-w-[520px]">
              Print on Demand Fulfillment Service
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-6">
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={penruller}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl ">Contract Dtg & Screen-printing</h5>
                    <p className="text-muted mt-4 mb-4">
                    Vibrant and long-lasting colors, making it suitable for bold and solid designs.
                    </p>
                    <Link to={"/fulfillment-services"}><button variant="solid" size="small" className="btn btn-sm btn-custom navbar-btn btn-rounded py-1 mb-2 px-[15px]">Learn More</button></Link>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={code}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">Embroidery</h5>
                    <p className="text-muted mt-4 mb-2">
                    Embroidery offers a high-quality and professional look, adding a three-dimensional texture to the design.
                    </p>
                    <button onClick={()=>{setmsgType("Embroidery");openDialog()}} variant="solid" size="small" className="btn btn-sm btn-custom navbar-btn btn-rounded py-1 mb-2 px-[15px]">Contact Us</button>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={headphones}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">Sublimation</h5>
                    <p className="text-muted mt-4 mb-2">
                    Sublimation works best on fabrics made of polyester or materials with a high polyester content.
                    </p>
                    <button onClick={()=>{setmsgType("Sublimation");openDialog()}} variant="solid" size="small" className="btn btn-sm btn-custom navbar-btn btn-rounded py-1 mb-2 px-[15px]">Contact Us</button>
                  </div>
                </div>
                <div className="">
                  <div className="bg-gradient-to-r from-white to-custom/20 w-[54px] h-[54px] flex items-center justify-center -rotate-45 leading-[54px] rounded-md ">
                    <img
                      src={groupchat}
                      alt="feture"
                      className="mx-auto inline-block h-[30px] rotate-45"
                    />
                  </div>
                  <div className="mt-6">
                    <h5 className="font-bold text-xl">Fulfillment Services</h5>
                    <p className="text-muted mt-4 mb-2">
                    It eliminates the need for maintaining warehouse space, hiring staff, and investing in shipping infrastructure.
                    </p>
                    <Link to={"/fulfillment-services"}><button variant="solid" size="small" className="btn btn-sm btn-custom navbar-btn btn-rounded py-1 mb-2 px-[15px]">Learn More</button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src={feature5}
                alt="business"
                className="mx-auto"
              />
            </div>
          </div> */}
          <div className="grid grid-cols-1 mt-1 md:mt-10">
            <div className="text-center">
              <h2 className="font-bold text-[28px] md:text-[40px]  mb-2 relative z-10">
                <span className=" h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                Connect your store
              </h2>
              <p className="text-muted mx-auto text-center text-lg max-w-[900px] mt-6 relative">
                {/* <div className="absolute right-1 bg-purple-300 w-[90px] h-[40px]"></div> */}
                <img className="w-100 mx-auto" src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/integration.svg`} />
              </p>
              <p className="text-muted mx-auto text-center text-lg max-w-[900px] mt-6">
              <Link to={`/connect/shopify`} target="_blank" className=" mx-auto mb-2 text-center">
              <ConnectBtn text="Check Integration" icon={<HiChevronRight className='bg-white text-red-600 rounded-full' size={28} />} />
              </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Dialog
    isOpen={dialogIsOpen}
    onClose={onDialogClose}
    onRequestClose={onDialogClose}
    width={700}
    height={640}
    closable={false}
    contentClassName	="!mt-2"
    
>
    <h5 className="mb-4 font-bold">Send Your Inquiry</h5>
    <ContactForm w={700} callback={onDialogClose} msgType={msgType}/>
               
    {/* <div className="text-right mt-6">
        <Button
            className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
            variant="plain"
            onClick={onDialogClose}
        >
            Okay
        </Button>
        
    </div> */}
</Dialog>
    </>
  );
};

export default Features;
