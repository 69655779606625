

import Loading from 'components/shared/Loading';
import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
// import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { getSelectedProductInfo } from '../store/dataSlice';
import MainHeroSection from 'features/common/MainHeroSection';
import ProductImage from './ProductImage';
import ProductDetails from './ProductDetails';
import { InformationTabs } from './InformationTabs';
import { Card } from 'components/ui';


const ProductPage = () => {
    const dispatch = useDispatch()
    const params = useParams();
    const loading = useSelector((state) => state.productDetailsInfo.data.loading)
    const token = useSelector((state) => state.auth.session.token)
    
    const data = useSelector((state) => state.productDetailsInfo.data.productInfo)
    const showShipping = useSelector((state) => state.productDetailsInfo.state.showShipping)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    const fetchData = async()=>{
      
        dispatch(getSelectedProductInfo({id:params.id}))
        
    }
   
   
  return (
    <section className="pt-10 pb-[10px] bg-light">
        <Loading loading={loading}>
        {!showShipping && <section className="md:pt-[80px] pt-[80px]  md:pb-[20px] relative">
        <div className="container">
          <div className="grid grid-cols-1">
           <h2 className="font-bold"><Link to={'../products'} className='text-blue-500'>Home</Link> / {data.Title?data.Title:data.name}</h2>
            
           
          </div>
        </div>
      </section>}
     <section className="pt-10 pb-[170px] bg-light">
        <div className="container">
        <div className='flex md:flex-row flex-col justify-between'>
      <ProductImage />
      <ProductDetails />
      </div>
    <div className='py-2'></div>

      <div className=' '>
        <InformationTabs />
      </div>
      <div className='py-4'></div>
      <div className='w-full text-center font-bold text-3xl'>Dropshipping Pricing</div>
      <div className='py-2'></div>
      <div className='w-full'>
        <Card className="border shadow-sm rounded-md p-5 card card-border"  > <h2 className='text-2xl font-bold'>Printing Cost</h2>
                <p className='p-4'><ul className='list-disc'>
                <li>Any one print (Front/Back/Neck Lebel/Sleeve) - <span className='font-semibold'>Inluded in product pricing</span></li>
                <li>Extra front or back print - <span className='font-bold'>$4.99</span></li>
                <li>Extra sleeve print - <span className='font-bold'>$2.99</span></li>
                <li>Extra neck lebel print - <span className='font-bold'>$1.99</span></li>
                
            </ul>

      <div className='py-2'></div>
          <span className='text-sm'>* Printing costs are subject to change without notice and may vary based on market conditions, availability, or other factors. Please check the latest pricing before making a purchase.</span>
                </p>
                </Card>
      <div className='py-3'></div>

                <Card className="border shadow-sm rounded-md p-5 card card-border"  > <h2 className='text-2xl font-bold'>Shipping Cost</h2>
                <p className='md:p-4 '>
                <div className='py-3 '></div>

                <div className='flex flex-1 flex-col md:flex-row w-full space-y-4 md:space-y-0 '>
                <div className='location flex md:flex-col w-100 md:space-y-3 md:w-1/4'>
                  <span className='font-bold text-base w-1/3 md:w-full'>
                    Location
                  </span>
                  <span className='w-1/3 md:w-full'>
                    US
                  </span>
                  <span className='w-1/3 md:w-full'>
                    Outside US
                  </span>
                </div>
                <div className='location flex md:flex-col w-100 md:space-y-3 md:w-1/4'>
                <span className='font-bold text-base w-1/3 md:w-full'>
                  Shipping Time *
                  </span>
                  <span className='w-1/3 md:w-full'>
                    3-5 days
                  </span>
                  <span className='w-1/3 md:w-full'>
                    10-15 days
                  </span>
                </div>
                <div className='location flex md:flex-col w-100 md:space-y-3 md:w-1/4'>
                <span className='font-bold text-base w-1/3 md:w-full'>
                  Single product **
                  </span>
                  <span className='w-1/3 md:w-full'>
                    $4.99
                  </span>
                  <span className='w-1/3 md:w-full'>
                  $14.99
                  </span>
                </div>
                <div className='location flex md:flex-col w-100 md:space-y-3 md:w-1/4'>
                <span className='font-bold text-base w-1/3 md:w-full'>
                  Additional product
                  </span>
                  <span className='w-1/3 md:w-full'>
                  $1.99
                  </span>
                  <span className='w-1/3 md:w-full'>
                  $4.99
                  </span>
                </div>
                </div>

      <div className='py-2'></div>
          <span className='text-sm'>*Shipping times are estimates and not guaranteed. They don’t account for the time required to produce your items.</span>
          <div className='py-2'></div><span className='text-sm'>* Shipping costs are subject to change without notice and may vary based on destination, package weight, and current carrier rates. For the most accurate and up-to-date shipping costs, please review the details at checkout.</span>
                </p>
                </Card>
      </div>
      </div>
      </section>
     
    </Loading>
    </section>
  )
}

export default ProductPage