import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetProductData, apiGetShopProductData } from 'services/ProductService'
// import { apiGetUserStores } from 'services/StoresService'


export const getSelectedProductInfo = createAsyncThunk(
    'productDetailsInfo/data/getSelectedProductInfo',
    async (data) => {
        const response = await apiGetProductData(data)
        return response.data
    }
)

export const initialFilterData = {
    status: '',
}

const dataSlice = createSlice({
    name: 'productDetailsInfo/data',
    initialState: {
        loading: true,
        productInfo: {},
        activeVariant:{},
        shippingDetails:{name:"",email:"",phone:"",address:"",address1:"",city:"",state:"",zip:"",country:""},
    },
    reducers: {
        setShippingDetails:(state,action) =>{
            state.shippingDetails = action.payload
        },
        setActiveVariant: (state, action) => {
            state.activeVariant = action.payload
        },
    },
    extraReducers: {
        [getSelectedProductInfo.fulfilled]: (state, action) => {
            state.productInfo = action.payload.data
            state.activeVariant = action.payload.data["colorVariants"][0]
            state.loading = false
        },
        [getSelectedProductInfo.pending]: (state) => {
            state.loading = true
        },
    },
})
export const { setShippingDetails,setActiveVariant } = dataSlice.actions
export default dataSlice.reducer
