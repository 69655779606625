import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Avatar ,FormContainer,FormItem,Input,Select,Button, Notification,toast} from 'components/ui'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { components } from 'react-select'

import FormDesription from './FormDesription'
import { HiOutlineCake,HiOutlineUserCircle ,HiCheck,HiOutlineMail,HiOutlinePhone,HiOutlineMap} from 'react-icons/hi'
import axios from 'axios'
import { API_PREFIX_PATH, APP_PREFIX_PATH } from 'constants/route.constant'
import { useEffect } from 'react'
import { apiCreateOrder } from 'services/ProductService'
import { setShoppginCart, toggleshowShipping } from '../store/stateSlice'
import { setActiveVariant } from '../store/dataSlice'
import { useNavigate, useParams } from 'react-router-dom'

const { Control } = components
const validationSchema = Yup.object().shape({
  name: Yup.string()
      .min(3, 'Too Short!')
      .max(120, 'Too Long!')
      .required('Name Required'),
  email: Yup.string().email('Invalid email').required('Email Required'),
  // title: Yup.string(),
  phone: Yup.string(),
  // avatar: Yup.string(),
  address: Yup.string().required('Address 1 is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
  country: Yup.string().required('Country is required'),
})
const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
  return (
      <div
          className={`flex items-center justify-between p-2 ${
              isSelected
                  ? 'bg-gray-100 dark:bg-gray-500'
                  : 'hover:bg-gray-50 dark:hover:bg-gray-600'
          }`}
          {...innerProps}
      >
          <div className="flex items-center">
             
              <span className="ml-2 rtl:mr-2">{label}</span>
          </div>
          {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
      </div>
  )
}

const CustomControl = ({ children, ...props }) => {
  const selected = props.getValue()[0]
  return (
      <Control {...props}>
         
          {children}
      </Control>
  )
}
const ProductShipping = () => {
  const [formData, setformData] = useState({name:"",email:"",phone:"",address:"",address1:"",city:"",state:"",zip:"",country:"",country_code:""})
  const [countries, setcountries] = useState([])
  const [states, setstates] = useState([])
  const [options, setOptions] = useState(countries)
  const [statesList, setstatesList] = useState(states)
  const [loading, setloading] = useState(false)
    const dispatch  = useDispatch()
    const navigate = useNavigate()
    const params = useParams();

  const token = useSelector((state) => state.auth.session.token)
  const user = useSelector((state) => state.auth.user)
  
  const currentvariant = useSelector((state) => state.productSelection.data.activeVariant)
  const data = useSelector((state) => state.productSelection.data.productInfo)
  const shoppginCart = useSelector((state) => state.productSelection.state.shoppginCart)
  
  // const selectedVarints = _.filter data.productVariants
  const selectedVarints = _.filter(data["productVariants"], d => d["variant1_id"] === shoppginCart['VariantInfo']["color"] && d["variant2_id"] === shoppginCart['VariantInfo']["size"])
  const selectedVarint = _.filter(data["variants"], d => d["variant1_id"] === shoppginCart['VariantInfo']["color"] && d["variant2_id"] === shoppginCart['VariantInfo']["size"])
  const name = selectedVarints[0].sku.split("DTG-A1-")[1].split("(")[0]
  const ctsr = shoppginCart['VariantInfo']["color"]+"_"+shoppginCart['VariantInfo']["size"]
  useEffect(() => {
        
    getCountries()
    // setformData(shippingAddress)
    if(user){
        setformData({...formData,name:user.name,email:user.email})
    }
    return () => {
      
    }
  }, [])
  const getCostForItem = (item,state,psku="",orderproducts=[],variants=[]) =>{
    if(state === "product"){
        
        const price = _.filter(item["variants"] ,(v) =>  v.sku === psku)
        const imgArts = item["artlayers"];
            if(imgArts["front"][0] && imgArts["back"][0]){
                return parseFloat(price[0]["cost"])+parseFloat(price[0]["cost"]);
            }
            else{
                return price[0]["cost"];
            }
        
    }
    else{
        let totalCost = 0;
        
        _.map(orderproducts , (item) =>{
            
            const price = _.filter(variants ,(v) =>  v.sku === item.psku)
            
            if(item["frontBackPrint"]){
                totalCost = (totalCost+parseFloat(price[0]["cost"])+parseFloat(4.99))*item["qty"];
            }
            else{
                totalCost = (totalCost+parseFloat(price[0]["cost"]))*item["qty"];
            }
            
        })
        return  totalCost;
    }
    
}
const getTotalShippingCost = (totalQty) =>{
    if(formData.country === "United States"){
        return  4.99 + ((totalQty-1)*1.99)
    }
    else{
        return  14.99 + ((totalQty-1)*4.99)
    }
}
  const getCountries = async()=>{
      const list = await axios.post(`${API_PREFIX_PATH}/utils/countries?twitter=true`, formData, {
      headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
          'Authorization': `Bearer ${token}`
      },
      });
      if(list.data["IsSuccessful"]){
          const newlist = _.map(list.data.list, (item) =>  ({...item,value: item.name, label: item.name}) )
          setcountries(newlist)
          if(formData.country){
              setloading(true)
      
      setstatesList([])
          const response = await axios.post(`${API_PREFIX_PATH}/utils/states?twitter=true`, {country:formData.country}, {
          headers: {
              'Content-Type': 'application/json', // Important for file uploads
              'Authorization': `Bearer ${token}`
          },
          });
          if(response.data["IsSuccessful"]){
              
              setstatesList(response.data["statesList"])
          }
          setloading(false)
          }
      }

  }
  const onFormSubmit = async(values,setSubmitting)=>{
    setSubmitting(false)
    
    let lineItems = [];
    let totalQty = 0;
    selectedVarints.map(item =>{
        
        const keys = item["variant1_id"]+"_"+item["variant2_id"]
        const varInfo = _.filter(data["variants"], d => d["variant1_id"] === item["variant1_id"] && d["variant2_id"] === item["variant2_id"])
        
        const color= varInfo[0]["color"]
        const size= varInfo[0]["size"]
       const qty = shoppginCart["activeQuntity"][keys]
       totalQty = totalQty + qty
        let line = {};
            line["sku"] = item["sku"];
            line["name"] = `${data["name"]} - ${name} / ${color} / ${size}`;
            line["psku"] = item["psku"];
            line["qty"] = qty;
            line["price"] = getCostForItem(data,'product',item["psku"]);
            line["grams"] = 5;
            line["vendor_product_id"] = item["vendor_product_id"];
            line["variant1_id"] = item["variant1_id"];
            line["variant2_id"] = item["variant2_id"];
            line["variant_title"] =  `${name} / ${color} / ${size}`;
            line["title"] =  `${data["name"]} - ${name}`
            line["image"] = item["image"]?item["image"]:item["image_back"];
            line["color"] = color;
            line["frontBackPrint"] = item["image"] && item["image_back"]?true:false
            line["size"] = size;
            line["rowImage"] = item["image"]?item["image"]:item["image_back"];
            lineItems.push(line)
    })
    const amount = getTotalShippingCost(totalQty)+getCostForItem("item","total","",lineItems,data["variants"]);
        const costdetails={
            "cost":getCostForItem("item","total","",lineItems,data["variants"]),
            "shippingCost":getTotalShippingCost(totalQty),
            "Subtotal":amount,
        }
        const orderData = {state:"Que",shippingInfo:values,products:data,line_items:lineItems,amount:amount,costdetails:costdetails}
    
            const orders = await apiCreateOrder(orderData)
            
            if(orders["data"]["IsSuccessful"]){
                toast(<Notification closable type="success" duration={2000}>
                    Your order has been sent.
                 </Notification>)
        
                dispatch(setShoppginCart({}))
                dispatch(toggleshowShipping(false))
                dispatch(setActiveVariant(0))
                
                navigate(`/${params.shop}/products`)
            }
            else{
                toast(<Notification closable type="danger" duration={2000}>
                {orderData["ErrorMessage"]?orderData["ErrorMessage"]:" Something went wrong. Try again."}
             </Notification>)
                
            }
            

  }
  const changeCountry = async(option,form,field)=>{
    console.log(option);
    setloading(true)
    setformData({...formData,country:option.name,country_code:option.code2})
    setstatesList([])
        const response = await axios.post(`${API_PREFIX_PATH}/utils/states?twitter=true`, {country:option.name}, {
        headers: {
            'Content-Type': 'application/json', // Important for file uploads
            'Authorization': `Bearer ${token}`
        },
        });
        if(response.data["IsSuccessful"]){
            
            setstatesList(response.data["statesList"])
        }
        setloading(false)
        form.setFieldValue(
            "state",
            ""
        )
    form.setFieldValue(
        field,
        option.name
    )
}


  return (
    <div className='flex flex-col w-full'>
      <div className='flex items-center space-x-6'>
      <div className='relative w-[90px]'>
      <span className='absolute rounded-full bg-black text-center text-white w-6 h-6 -right-1 -top-1'>{shoppginCart["activeQuntity"][ctsr]}</span>
      <Avatar size={60} src={selectedVarints[0]["image"]?selectedVarints[0]["image"]:selectedVarints[0]["image_back"]} className="bg-slate-100" />
      </div>
        <div className='ml-2 flex flex-col space-y-1'>
          <span className='text-lg font-semibold'>{data.name}</span>
          <span className=''>{name} / {selectedVarint[0].color.replaceAll("-"," ")} / {selectedVarint[0].size.replaceAll("-"," ")}</span>
        </div>
      </div>
      <div className='py-4 px-4 font-semibold text-2xl'></div>
      <div className='py-2'>
      <Formik
        className="w-full"
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                setTimeout(() => {
                    onFormSubmit(values, setSubmitting)
                }, 1000)
            }}
        >
            {({ values, touched, errors, isSubmitting, resetForm,setFieldValue }) => {
                const validatorProps = { touched, errors }
                return (
                    <Form>
                        <FormContainer>
                            
                            <div className='py-1'></div>

                             <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-2 gap-24">
                            <FormItem
                                name="name"
                                label="Name"
                                {...validatorProps}
                                invalid={
                                    errors.name &&
                                    touched.name
                                }
                                errorMessage={errors.name}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Name"
                                    component={Input}
                                    prefix={
                                        <HiOutlineUserCircle className="text-xl" />
                                    }
                                />
                            </FormItem>
                            <FormItem
                                name="email"
                                label="Email"
                                {...validatorProps}
                                invalid={
                                    errors.email &&
                                    touched.email
                                }
                                errorMessage={errors.email}
                            >
                                <Field
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMail className="text-xl" />
                                    }
                                />
                            </FormItem>
                            </div></div>
                            <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-2 gap-24">
                            <FormItem
                                name="phone"
                                label="Phone"
                                {...validatorProps}
                                
                            >
                                <Field
                                    type="phone"
                                    autoComplete="off"
                                    name="phone"
                                    placeholder="Phone"
                                    component={Input}
                                    prefix={
                                        <HiOutlinePhone className="text-xl" />
                                    }
                                />
                            </FormItem>
                          
                            </div></div>
                            
                       
                            <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-2 gap-24">
                                    <FormItem
                                name="address"
                                label="Address 1"
                                {...validatorProps}
                                invalid={
                                    errors.address &&
                                    touched.address
                                }
                                errorMessage={errors.address}
                            >
                                <Field
                                    type="address"
                                    autoComplete="off"
                                    name="address"
                                    placeholder="Address 1"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                                    <FormItem
                                name="address1"
                                label="Address 2"
                                {...validatorProps}
                                
                            >
                                <Field
                                    type="address1"
                                    autoComplete="off"
                                    name="address1"
                                    placeholder="Address 2"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                           
                           
                                       
                                           
                                        
                                    </div>
                                </div>
                                <div className='py-1 pr-8'>
                                    
                                    <div className="md:grid grid-cols-2 gap-24">
                                    <FormItem
                                name="city"
                                label="City"
                                {...validatorProps}
                                invalid={
                                    errors.city &&
                                    touched.city
                                }
                                errorMessage={errors.city}
                            >
                                <Field
                                    type="city"
                                    autoComplete="off"
                                    name="city"
                                    placeholder="City"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                            <FormItem
                                name="state"
                                label={"State"}
                                {...validatorProps}
                                invalid={
                                    errors.state &&
                                    touched.state
                                }
                                errorMessage={errors.state}
                            >
                                <Field name="state">
                                    {({ field, form }) => (
                                        <Select  
                                        
                                            field={field}
                                            form={form}
                                            options={statesList}
                                            isLoading={loading}
                                            
                                            components={{
                                                Option: CustomSelectOption,
                                                Control: CustomControl,
                                            }}
                                            
                                            value={statesList.filter(
                                                (option) =>
                                                    option.name ===
                                                    values?.state
                                            )}
                                            
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                            
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            </div></div>
                            <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-2 gap-24">
                                     <FormItem
                                name="country"
                                label={"Country"}
                                {...validatorProps}
                                invalid={
                                    errors.country &&
                                    touched.country
                                }
                                errorMessage={errors.country}
                            >
                                <Field name="country">
                                    {({ field, form }) => (
                                        <Select  
                                        cacheOptions
                                            field={field}
                                            form={form}
                                            options={countries}
                                           
                                            
                                            components={{
                                                Option: CustomSelectOption,
                                                Control: CustomControl,
                                            }}
                                            // component={AsyncSelect}
                                            value={countries.filter(
                                                (option) =>
                                                    option.name ===
                                                    values?.country
                                            )}
                                            // value={ options[0]}
                                            onChange={(option) => changeCountry(option,form,field.name)
                                                
                                            }
                                            // onInputChange={handleInputChange}
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            <FormItem
                                name="zip"
                                label="Zip Code"
                                {...validatorProps}
                                invalid={
                                    errors.zip &&
                                    touched.zip
                                }
                                errorMessage={errors.zip}
                            >
                                <Field
                                    type="zip"
                                    autoComplete="off"
                                    name="zip"
                                    placeholder="Zip Code"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem></div></div>
                            
                            <div className='py-1'></div>
                            
                           
                            <div className="mt-4 ltr:text-center">
                                
                                <Button
                                    variant="solid"
                                    loading={isSubmitting}
                                    type="submit"
                                    className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
                                >
                                    {isSubmitting ? 'Updating' : 'Place Order'}
                                </Button>
                            </div>
                        </FormContainer>
                    </Form>
                )
            }}
        </Formik>
      </div>
    </div>
  )
}

export default ProductShipping