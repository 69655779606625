import React, { useEffect } from 'react'
import {Elements,PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useState } from 'react';
import Loading from 'components/shared/Loading';
import axios from 'axios';
import { API_PREFIX_PATH } from 'constants/route.constant';
import CheckoutForm from './CheckoutForm';

const PaymentDialog = ({amount,backPayState}) => {
    const stripePromise = loadStripe("pk_live_51KuHjYIdjwW6pHmem9Bq4wadqydGgjPpnxA4IrUrY9otzAmmxDQigXBC3gA40M0jEruFWA87zYoRyCGbrzyEcBaZ001VZEp1zu",{locale: "en"});
    // const stripePromise = loadStripe("pk_test_51KuHjYIdjwW6pHmea2O2ve1VK5U7C9q2T9qFA7zq95XZOZCgTT6J98JWC3GVTji7hpc7wRg8BipG6nT349zDxUsh00pElugJjB",{locale: "en"});
    //pk_test_51KuHjYIdjwW6pHmea2O2ve1VK5U7C9q2T9qFA7zq95XZOZCgTT6J98JWC3GVTji7hpc7wRg8BipG6nT349zDxUsh00pElugJjB
    const [loading, setloading] = useState(true)
    const [paymentSecret, setpaymentSecret] = useState(null)
    useEffect(() => {
      
        getPaymentIntent()
      return () => {
        
      }
    }, [])
    const getPaymentIntent = async()=>{
      
      // const amnt = amount.toFixed(2);
      // console.log(amnt);
        const data = { "amount":parseInt((amount*100).toFixed(2)),
        "currency":"USD"}
        const res =  await axios.post(`${API_PREFIX_PATH}payment/create-payment-intent?twitter=true`, data, {
            headers: {
                'Content-Type': 'application/json', // Important for file uploads
                // 'Authorization': `Bearer ${token}`
            },
            });
            const resdata = res["data"];
            if(resdata["IsSuccessful"]){
                setloading(false)
                
            setpaymentSecret(resdata["clientSecret"])


            }
            else{
                setloading(false)
            }
    }
    const backPay = (info)=>{
        backPayState(info);
    }
  return (
    <Loading loading={loading}>
    
    <Elements stripe={stripePromise} options={{
        
        clientSecret: paymentSecret
      }} >
        <CheckoutForm amount={amount} callback={backPay} clientSecret={paymentSecret} setprocessingCall={setloading} />
      </Elements>
    </Loading>
  )
}

export default PaymentDialog