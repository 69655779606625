import React from 'react'

const CustomBranding = () => {
  return (
    <div>
    <div className='py-2'></div>
        
        <div className='grid grid-cols-2 md:grid-cols-2 grid-flow-row gap-x-4 gap-y-4'>
    <div className='text-xl font-semibold'>Neck Label</div>
    <div className='text-xl font-semibold'>Shipping Label</div>
    </div>
    <div className='py-3'></div>

    <span>
    Boosting brand exposure through custom branding options is a powerful way to convey your brand values to customers. Discover more about the process in this article.
    </span>
    </div>
  )
}

export default CustomBranding