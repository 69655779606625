
import { APP_PREFIX_PATH } from 'constants/route.constant'
import React from 'react'
import { Link } from 'react-router-dom'

const ExtraHeroSection = (props) => {
  return (
    <>
      <section className="md:pt-[120px] pt-[120px] pb-5 md:pb-[5px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <h1 className="font-bold text-[36px]">{props.title}</h1>
            <p className="mt-2">{props.subTitle}</p>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default ExtraHeroSection
