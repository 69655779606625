import React, { useState } from 'react'
import whatisimg from "assets/images/connect/what-is.jpg";

const WhatIs = () => {
    const [dataBlock] = useState({
        title:"What is Print on Demand?",
        subTitle: "Due to its widespread use as filler text for layouts, non-readability is of great importance."
    })
  return (
    <section className=" bg-center pb-[60px] bg-white relative">
        <div className="container">
        <h2 className="font-bold text-center textallhead mb-2 relative z-10">
                    <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                    {dataBlock.title}</h2>
          <div className="store-connect whatis">
          <div className='flex items-center justify-center p-[30px]'><img src={whatisimg}className={"w-[540px]"}/></div>
            <div className='flex items-center my-[40px]'>
            <div className="text-left">
                    
                    <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">Print-on-demand is a seller-friendly business model enabling customized products to be created on-demand and drop-shipped directly to customers.</p>
                    <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">In contrast to traditional dropshipping, print-on-demand items are manufactured only when an order is received, eliminating the need for minimum quantity requirements.</p>
                    <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">Opting for print on demand relieves you of concerns about manufacturing, storage, and shipping. All operational aspects, except for designs and promotions, are efficiently managed by print-on-demand providers.</p>
                
                </div>
            </div>

          </div>
          </div>
          </section>
  )
}

export default WhatIs