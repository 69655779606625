import mailIcon from '../../images/icon/mail.svg'
import callIcon from '../../images/icon/active-call.svg'
import timeSchedule from '../../images/icon/time-schedule.svg'

const contact = [
    {
        id: 1,
        img: mailIcon,
        name: "Email",
        text: "info@usprintfactory.com",
        subText: null
    },
    {
        id: 2,
        img: callIcon,
        name: "Telephone",
        text: "(1) 877-399-6777",
        subText: null
    },
    // {
    //     id: 3,
    //     img: timeSchedule,
    //     name: "Business Hours",
    //     text: "Monday To Friday",
    //     subText: "9:00am To 6:00pm (GMT)"
    // },
]

export default contact