import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from 'react-helmet';
import App from "./App";
import "../src/assets/css/themify-icons.css";
import "../src/assets/css/materialdesignicons.min.css";
import "../node_modules/swiper/swiper-bundle.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/tailwind.css";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <div className="antialiased text-base font-nunito">
         <Helmet>
        <title>US Print Factory | Print on Demand</title>
        <meta name="description" content="High-quality print-on-demand T-shirts. Custom apparel printing with no minimum order. Fast shipping and eco-friendly options available." />
        <meta name="keywords" content="Print-on-demand T-shirts, Custom T-shirt printing, POD T-shirt service, POD T-shirt dropshipping, Print-on-demand T-shirt provider, Custom apparel printing, Design your own T-shirt, T-shirt printing on demand, POD apparel service, Custom T-shirts with no minimum order, Fast shipping POD T-shirts, Eco-friendly POD T-shirts, Affordable custom T-shirts, High-quality POD T-shirts, POD T-shirt fulfillment" />
        <meta name="author" content="US Print Factory" />
      </Helmet>
    <App/>
    </div>
);
