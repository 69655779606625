const feature = [
    {
        id:1,
        title: "1. Create Your Store",
        subTitle: "Signup and setup your team store, add payment method and done."
    },
    {
        id:2,
        title: "2. Design Your Products",
        subTitle: "Create your team t-shirts with a logo and different artworks."
    },
    {
        id:3,
        title: "3. Invite Your Team",
        subTitle: "Invite your team to join your store and get customize products."
    },
]

export default feature