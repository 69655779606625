import React from 'react'
import { useParams } from 'react-router-dom'
import ConnectStore from './components/ConnectStore';
import FooterTwo from 'components/footers/FooterTwo';

import Instructions from './components/Instructions';
import WhyStore from './components/WhyStore';

const StoreConnect = () => {
    const { store } = useParams();
    
  return (
    <>
    <section className="md:pt-[80px] md:pb-[50px] pt-[80px] pb-[20px] relative bg-cover bg-center bg-white">
        <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full "></div>
        <ConnectStore store={store} />
    </section>
    <Instructions store={store} />
    <WhyStore store={store} />
    <div className='pb-[10px]'></div>
    <FooterTwo />
    </>
  )
}

export default StoreConnect