import React from "react";
import logoLight from "assets/images/logo-light.png";
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "constants/route.constant";
import Lottie from 'lottie-react';
import animationData from 'assets/images/json/contactus.json'
import { Button } from "components/ui";
import ConnectBtn from "components/buttons/ConnectBtn";
const LOGO_SRC_PATH = '/images'
const FooterTwo = ({hideExtra=false}) => {
  return (
    <>
    
    {!hideExtra && <div className="flex px-3 mb-20 md:flex-row flex-col md:!items-start items-center mt-8 w-full md:space-y-0 space-y-6 ">
         <div className=" whystore flex flex-col ">
         <div className="flex items-center justify-center h-[120px]">
         <img className="h-full" src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/profit.jpg`} />
         </div>
            <div className="flex flex-col text-center">
            <h1 className="texthead  font-bold ">Higher Profits with USPF</h1>
            <div>Maximize earnings with USPF’s POD pricing. With printed t-shirt prices starting at $5.99, it is guaranteed to be the most affordable POD solution in the marketplace.</div>
            </div>
         </div>
         <div className=" whystore flex flex-col ">
         <div className="flex items-center justify-center h-[120px]">
         <img className="h-full" src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/seamless.jpg`} />
         </div>
            <div className="flex flex-col text-center">
            <h1 className="texthead  font-bold ">Seamless Fulfillment</h1>
            <div>Let us handle fulfillment seamlessly—our extensive network and automatic routing ensure smooth operations, even during peak holiday seasons.</div>
            </div>
         </div>
         <div className=" whystore flex flex-col ">
         <div className="flex items-center justify-center h-[120px]">
         <img className="h-full" src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/quick.jpg`} />
         </div>
            <div className="flex flex-col text-center ">
            <h1 className="texthead  font-bold ">Quick Turnarounds</h1>
            <div>Experience quick turnarounds with USPF. With expected production times between 24-48 hours, ensure your custom merchandise is produced and delivered promptly.</div>
            </div>
         </div>
        </div>}
      <footer className="bg-themedark pt-20 pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-[30px] items-start">
            <div className="order-2 lg:order-1 mt-4 md:mt-0 md:col-span-2 lg:col-auto">
              <img
                src={logoLight}
                alt="logo light"
                className="img-fluid w-[120px] block text-md-center text-sm-center"
              />
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              <h6 className="text-white text-lg">Quick Access</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/products/catalogue"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Product Catalog
                  </a>
                </li>
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/fulfillment-services"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/pages/privacy-policy"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/pages/usage-policy"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Usage Policy
                  </a>
                </li>
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/pages/terms-of-service"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              <h6 className="text-white text-lg">Help Center</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/pages/faq"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Billing
                  </a>
                </li>
                <li>
                  <a
                    href={APP_PREFIX_PATH+"/pages/faq"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Accounting
                  </a>
                </li>
                <li>
                  <a
                   href={APP_PREFIX_PATH+"/pages/faq"}
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    General Question
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:col-span-3 md:col-span-6 order-1 lg:order-2">
              <div className="bg-white rounded max-w-[460px] space-x-3 shadow-top-md shadow-2xl flex flex-row mx-auto relative py-10 px-5 space-y-8 -mt-[140px]">
              <div className="max-w-[160px]"><Lottie animationData={animationData}  /></div>
              <div className="flex space-y-3 flex-col">
               <div className="text-left">
                  <span className="font-bold">Need more info?</span>
                  <p className=" mx-auto text-lg max-w-[600px] mt-6">
                  Are you looking for custom solutions for your business?
                  </p>
                </div>
                <Link
                    to={APP_PREFIX_PATH+"/pages/contact" }
                    className=""
                  ><Button variant="solid" size="sm" className="rounded-full bg-red-600">Contact Us</Button></Link>
              </div>
              </div>
            </div>
          </div>
          <div className="bg-white/[7%] h-px w-full mt-12 mb-8"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div>
              <p className="mb-0 text-white/50 text-center md:text-left">
              2023 &copy; US Print Factory | All rights reserved.
              </p>
            </div>
            <div>
              <ul className="flex items-center space-x-3 md:justify-end justify-center">
                <li>
                  <Link
                    to="https://www.facebook.com/profile.php?id=100088512571319" target="_blank"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/usprintfactory/" target="_blank"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-twitter"></i>
                  </Link>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
