import React, { useState } from "react";

import ContentSection from "../features/common/ContentSection";

import Feature from "../features/landingpage-three/Feature";

import ModalVideo from "react-modal-video";
import '../../node_modules/react-modal-video/scss/modal-video.scss'

import FooterTwo from "components/footers/FooterTwo";


const LandingPageThree = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [dataBlock] = useState({
    title: "You Design & We Print For Your Team.",
    subTitle:
      "A simple and easy-to-use Print on Demand platform for managing the merchandise for your team.",
  });
  return (
    <>
      <section className="bg-white pt-[90px] pb-5 md:pt-[120px] md:pb-20  ">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="bg-light text-center py-10 md:py-[60px] px-[30px] bg-cover bg-center bg-no-repeat bg-[url('../images/home_sec_layer-2.png')]">
            <div className="mt-2 mb-3">
               
                  <a href="https://usprintfactory.com/app/sign-up?redirectUrl=/dashboard/team/shop" className="btn-custom rounded-full cursor-pointer">
                  Get Started
                </a>
                </div>
              {/* <div className="max-w-[430px] mx-auto">
                <form className="relative">
                  
                  <button className="absolute tracking-[1px] font-normal text-sm rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">
                    Get Started
                  </button>
                </form>
              </div> */}
              {/* <div className="h-[3px] bg-custom w-14 inline-block mt-10 mb-5 mx-0"></div> */}
              <div className="max-w-[550px] mx-auto">
                <h1 className="font-semibold leading-[1.4] text-[32px] md:text-[46px]">
                  {dataBlock.title}
                </h1>
                <p className="text-muted mx-auto md:text-lg mt-4 max-w-[500px]">
                  {dataBlock.subTitle}
                </p>
                <div className="mt-6">
                  <button
                    onClick={() => setIsOpen(true)}
                    className="lightbox text-white"
                  >
                    <i className="mdi mdi-play bg-custom h-[66px] w-[66px] leading-[66px] text-[34px] shadow-[0px_0px_0px_6px_rgb(10,161,149,0.15)] rounded-full inline-block text-center"></i>
                  </button>
                  <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="E1_iR0ink6I"
                    onClose={() => setIsOpen(false)}
                  />
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <Feature />
      <ContentSection />
      {/* <LogoSection /> */}
      {/* <PricingPlan /> */}
      {/* <GetInTouch /> */}
      <div className="py-6"></div>
      <div className="py-6"></div>
      <FooterTwo />
    </>
  );
};

export default LandingPageThree;
