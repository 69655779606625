import {
    NAV_ITEM_TYPE_TITLE,
    NAV_ITEM_TYPE_COLLAPSE,
    NAV_ITEM_TYPE_ITEM,NAV_ITEM_TYPE_CUSTOM
} from 'constants/navigation.constant'
import { APP_PREFIX_PATH } from 'constants/route.constant'

const productsNav = [
    {
        key: 'productlist',
        path: APP_PREFIX_PATH+'/products',
        title: 'Men\'s Clothing',
        translateKey: "Men's Clothing",
        mobilepath: APP_PREFIX_PATH+'/products/mens-clothing/all',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [
            {
                key: 'productlist.item1',
                path: APP_PREFIX_PATH+'/products/mens-clothing/Mens%20T-Shirt',
                title: 'T-Shirts',
                translateKey: 'T-Shirts',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productlist.item2',
                path: APP_PREFIX_PATH+'/products/mens-clothing/Mens%20V-Neck',
                title: 'V-Neck T-Shirts',
                translateKey: 'V-Neck T-Shirts',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productlist.item3',
                path: APP_PREFIX_PATH+'/products/mens-clothing/Mens%20Tank%20Top',
                title: 'Tank Top',
                translateKey: 'Tank Top',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productlist.item4',
                path: APP_PREFIX_PATH+'/products/mens-clothing/Unisex Hoodie',
                title: 'Hoodies',
                translateKey: 'Hoodies',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productlist.item5',
                path: APP_PREFIX_PATH+'/products/mens-clothing/Unisex Crewneck Sweatshirt',
                title: 'Crewneck Sweatshirt',
                translateKey: 'Crewneck Sweatshirt',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productlist.item6',
                path: APP_PREFIX_PATH+'/products/mens-clothing/long-sleeve',
                title: 'Long Sleeve',
                translateKey: 'Long Sleeve',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
        ],
    },
    {
        key: 'productkids',
        path: '/dashboard/default',
        title: 'Kid\'s Clothing',
        translateKey: "Kid's Clothing",
        mobilepath: APP_PREFIX_PATH+'/products/kids-clothing/all',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [
            {
                key: 'productkids.item1',
                path: APP_PREFIX_PATH+'/products/kids-clothing/Kids%20T-Shirts',
                title: 'T-Shirts',
                translateKey: 'T-Shirts',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            
        ],
    },
    {
        key: 'productwomen',
        path: '/dashboard/default',
        title: 'Women\'s Clothing',
        translateKey: "Women's Clothing",
        mobilepath: APP_PREFIX_PATH+'/products/womens-clothing/all',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [
            {
                key: 'productwomen.item1',
                path: APP_PREFIX_PATH+'/products/womens-clothing/Womens%20T-Shirt',
                title: 'T-Shirts',
                translateKey: 'T-Shirts',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productwomen.item2',
                path: APP_PREFIX_PATH+'/products/womens-clothing/Womens%20V-Neck',
                title: 'V-Neck T-Shirts',
                translateKey: 'V-Neck T-Shirts',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productwomen.item3',
                path: APP_PREFIX_PATH+'/products/womens-clothing/Womens%20Tank%20Top',
                title: 'Tank Top',
                translateKey: 'Tank Top',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productwomen.item4',
                path: APP_PREFIX_PATH+'/products/womens-clothing/Unisex Hoodie',
                title: 'Hoodies',
                translateKey: 'Hoodies',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productwomen.item5',
                path: APP_PREFIX_PATH+'/products/womens-clothing/Unisex Crewneck Sweatshirt',
                title: 'Crewneck Sweatshirt',
                translateKey: 'Crewneck Sweatshirt',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'productwomen.item6',
                path: APP_PREFIX_PATH+'/products/womens-clothing/long-sleeve',
                title: 'Long Sleeve',
                translateKey: 'Long Sleeve',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
        ],
    },
    {
        key: 'homeliving',
        path: '/dashboard/default',
        title: 'Home & Living',
        translateKey: "Home & Living",
        mobilepath: APP_PREFIX_PATH+'/products/home-living/all',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [
            {
                key: 'homeliving.item1',
                path: APP_PREFIX_PATH+'/products/home-living/decoration',
                title: 'Decoration',
                translateKey: 'Decoration',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'homeliving.item2',
                path: APP_PREFIX_PATH+'/products/home-living/lightings',
                title: 'Lightings',
                translateKey: 'Lightings',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'homeliving.item3',
                path: APP_PREFIX_PATH+'/products/home-living/mugs',
                title: 'Mugs',
                translateKey: 'Mugs',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'homeliving.item4',
                path: APP_PREFIX_PATH+'/products/home-living/home-kitchen',
                title: 'Home & Kitchen',
                translateKey: 'Home & Kitchen',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },

        ],
    },
    {
        key: 'accessories',
        path: '/dashboard/default',
        title: 'Accessories',
        translateKey: "Accessories",
        mobilepath: APP_PREFIX_PATH+'/products/accessories/all',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [
            {
                key: 'accessories.item1',
                path: APP_PREFIX_PATH+'/products/accessories/phone-cases',
                title: 'Phone Cases',
                translateKey: 'Phone Cases',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'accessories.item2',
                path: APP_PREFIX_PATH+'/products/accessories/office-stationery',
                title: 'Office & Stationery',
                translateKey: 'Office & Stationery',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'accessories.item3',
                path: APP_PREFIX_PATH+'/products/accessories/hats',
                title: 'Hats',
                translateKey: 'Hats',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
        ],
    },
    
]

export default productsNav
