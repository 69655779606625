import { Button } from 'components/ui'
import React from 'react'

const ConnectBtn = ({text,icon,block=false,btnClass="px-8 font-extrabold text-base md:text-2xl uppercase"}) => {
  return (
    <Button variant="solid" block={block}
                    color="yellow-600" className="bg-red-600 mx-auto !rounded !px-3 !h-12 !py-3 flex items-center justify-between "> <span className='flex-1 text-center'>
                    <span className={btnClass}>{text}</span>
                </span>{icon}</Button>
  )
}

export default ConnectBtn