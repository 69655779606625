import axios from 'axios'
import Spinner from 'components/ui/Spinner'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { apiGetupdateUSPFProduct } from 'services/ProductService'
// import { CardImg, Spinner } from 'reactstrap'

const ProductColorList = ({data,productSanmarData,item,productId}) => {
    const [dataIn, setdata] = useState(null)
    const [sanmarData, setsanmarData] = useState([])
    const [colorGrpd, setcolorGrpd] = useState(null)
    const [sizeed, setsizeGrpd] = useState(null)
    const [sizeListed, setsizeListed] = useState([])
    const [selectedColor, setselectedColor] = useState("")
    const [loading, setloading] = useState(true)
    const [sanmarInventory, setsanmarInventory] = useState([])
    const [sanmarFilterColor, setsanmarFilterColor] = useState("")
    useEffect(() => {
      
       if(item.isSanmar){
        
        var grouped = _.mapValues(_.groupBy(data, 'attributeColor'),
        clist => clist.map(car => _.omit(car, 'attributeColor')));
        var groupedSized = _.mapValues(_.groupBy(data, 'attributeSize'),
                          clist => clist.map(car => _.omit(car, 'attributeSize')));
                          groupedSized = {...groupedSized,"M":{},"L":{},"XL":{},"2XL":{},"3XL":{},"4XL":{},"5XL":{},"6XL":{}}
        setcolorGrpd(grouped)
        setsizeGrpd(groupedSized)

        let slist = []
        
        Object.keys(groupedSized).map(d =>slist.push(d))
        let colorVariants = [];
        Object.keys(grouped).map(key =>{
          
          // const clr = grouped[key][0]["color1"]
          // const clr2 = grouped[key][0]["color2"]
          
          colorVariants.push({name:grouped[key][0]["color"],shortName:key,code:"",imgcolor:grouped[key][0]["imgcolor"],textColor:grouped[key][0]["imgcolor"],productImage:grouped[key][0]["productImage"]})
        })
        // const lastElement = _.pullAt(slist, slist.length - 1);
        // console.log(lastElement);
        // if(lastElement[0] === "XS"){
        //   slist.unshift(...lastElement);      
        
        // }
        const sizeOrder = { "XS": 0, "S": 1, "M": 2, "L": 3, "XL": 4 , "2XL": 5, "2XL": 6, "3XL": 7, "4XL": 8, "5XL": 9, "6XL": 10  };
        slist.sort((a, b) => sizeOrder[a] - sizeOrder[b]);
        
        setsizeListed(slist)
        let sizeVariants = [];
        slist.map(ss=>{
          sizeVariants.push({name:ss,code:""})
        })
        
        // updatedb({productId:item.productId,sizeVariants,colorVariants})
        setselectedColor(item && item.inventory?item["colorList"][0]["colorName"]:Object.keys(grouped)[0])
        setdata(data)
        setsanmarData(productSanmarData)
        getSelectedColorQty(item && item.inventory?item["colorList"][0]["colorName"]:Object.keys(grouped)[0]);
       }
       else{
      var grouped = _.mapValues(_.groupBy(data, 'colorName'),
        clist => clist.map(car => _.omit(car, 'colorName')));
        var groupedSized = _.mapValues(_.groupBy(data, 'sizeName'),
                          clist => clist.map(car => _.omit(car, 'sizeName')));
        setcolorGrpd(grouped)
        setsizeGrpd(groupedSized)
        
        let slist = []
        let slist1 = []
        Object.keys(groupedSized).map(d =>slist.push(d))
        // Object.keys(grouped).map(d =>slist1.push(d))
        
        
        // const lastElement = _.pullAt(slist, slist.length - 1);
        // if(lastElement[0] === "XS"){
        //   slist.unshift(...lastElement);      
        
        // }
        // else{
        //   slist = []
        // Object.keys(groupedSized).map(d =>slist.push(d))
          
        // }
        // 
        const sizeOrder = { "XS": 0, "S": 1, "M": 2, "L": 3, "XL": 4 , "2XL": 5, "2XL": 6, "3XL": 7, "4XL": 8, "5XL": 9, "6XL": 10  };
        slist.sort((a, b) => sizeOrder[a] - sizeOrder[b]);
        let colorVariants = [];
        Object.keys(grouped).map(key =>{
          const clr = grouped[key][0]["color1"]
          const clr2 = grouped[key][0]["color2"]
          
          colorVariants.push({name:key,code:clr,code2:clr2,textColor:grouped[key][0]["colorSwatchTextColor"],frontImage:grouped[key][0]["colorFrontImage"],backImage:grouped[key][0]["colorBackImage"],styleID:grouped[key][0]["styleID"]})
        })
        
        setsizeListed(slist)
        let sizeVariants = [];
        slist.map(ss=>{
          sizeVariants.push({name:ss,code:""})
        })
        
        // updatedb({productId:item.productId,sizeVariants,colorVariants})
        setselectedColor(item && item.inventory?item["colorList"][0]["colorName"]:Object.keys(grouped)[0])
        setdata(data)
        setsanmarData(productSanmarData)
        getSelectedColorQty(item && item.inventory?item["colorList"][0]["colorName"]:Object.keys(grouped)[0]);
       }
       
        
      return () => {
        
      }
    }, [data])
    const updatedb = async(val)=>{
      await apiGetupdateUSPFProduct(val)
    }
    const getSelectedColorQty = async(clr)=>{
      setsanmarFilterColor(clr);
      setloading(true)
     
     let clrin = clr === "Safety Orange"?"S. Orange":clr;
     const currentColordata = _.filter(sanmarData, sn => sn.color === clrin)
      
      const configclr = currentColordata[0]?currentColordata[0]["attributeColor"]:clrin;
      let data = JSON.stringify({
        "color": configclr,productId:item.StyleName
      });
      setsanmarFilterColor(configclr)
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://localhost:3011/api/1.0/extra/products/inventory?twitter=true',
        url: 'https://usprintfactory.com/api/1.0/extra/products/inventory?twitter=true',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        // setsanmarInventory
        
        if(response["data"]){
          
          setsanmarInventory(response["data"]["list"])
        }
        setloading(false)
      })
      
      
    }
    const updateSelectedColor = (clr)=>{
      if(clr === "Dark Heather Grey"){
        setselectedColor('Dark Hthr Grey')
      }
      else if(clr === "Athletic Heather"){
        setselectedColor('Athl Heather')
      }
      else if(clr === "Olive Drab Green"){
        setselectedColor('Olive Drab Grn')
      }
      else {
        setselectedColor(clr)
      }
      getSelectedColorQty(clr)
    }
    const renderQty = (qty,dl=null) =>{
     
      if(item.isSanmar){
          let sanmarQty = 0;
          
        const sanmarQtyList = _.filter(sanmarInventory, (s) => s.size === dl)
        
       
        if(sanmarQtyList[0]){
          // const ware = _.filter(sanmarQtyList[0]["whse"], (s) => s.whseName === "Robbinsville")
          // console.log(sanmarQtyList[0],ware);
          sanmarQty = sanmarQtyList[0]["whse"]['qty']
        }
        
        return  parseInt(sanmarQty);
      }
      else{
        
        let ssin = _.filter(dl["warehouses"], wh => wh.warehouseAbbr === "NJ");
        
        const ssinqty = dl["qty"];//ssin[0]?ssin[0]["qty"]:0;
        
        let sanmarQty = 0;
       
        const sanmarQtyList = _.filter(sanmarInventory, (s) => s.color === sanmarFilterColor && s.size === dl['sizeName'])
        // console.log(sanmarQtyList);
        if(sanmarQtyList[0]){
          
          sanmarQty = sanmarQtyList[0]["whse"]["qty"];
        }
       
        
        return parseInt(ssinqty) + parseInt(sanmarQty);
      }
      
    }
    const renderQtyNS = (AllQty,dl=null) =>{
      const forsize = _.filter(AllQty ,wty => wty.sizeName === dl);
      // console.log(forsize);
      if(forsize[0]){
      // return dl;
      let ssin = _.filter(forsize[0]["warehouses"], wh => wh.warehouseAbbr === "NJ");
      
      const ssinqty = forsize[0]["qty"];//ssin[0]?ssin[0]["qty"]:0;
      
      let sanmarQty = 0;
     
      const sanmarQtyList = _.filter(sanmarInventory, (s) => s.color === sanmarFilterColor && s.size === forsize[0]['sizeName'])
      // console.log(sanmarQtyList);
      if(sanmarQtyList[0]){
        
        sanmarQty = sanmarQtyList[0]["whse"]["qty"];
      }
     
      
      return parseInt(ssinqty) + parseInt(sanmarQty);

      }
      else{
        return 0;
      }

      
      
    }
  return (
    dataIn ?<div className='flex flex-col'>
        <span className='h7 py-3'>Color - {selectedColor}</span>
       {item && item.inventory ?<div className='itemColors float-left'>
{
    _.map(item["colorList"], (cpr,key )=> <a variant="top" onClick={()=>updateSelectedColor(cpr["colorName"])} className='imgg' style={{backgroundImage:'url("'+(cpr["img"])+'")', color:cpr["colorName"]==="White"?"#000000":cpr["textColor"],textAlign:'center'}} ><span className='mt-1 float-left w-100 px-2'>{cpr["color"]?cpr["color"]:cpr["colorName"]}</span></a>)
}

    </div>: <div className='itemColors float-left'>
{
    _.map(colorGrpd, (cpr,key )=> <a variant="top" onClick={()=>updateSelectedColor(key)} className='imgg' style={{backgroundImage:'url("https://www.ssactivewear.com/'+(cpr[0]["colorSwatchImage"])+'")', color:cpr[0]["colorSwatchTextColor"]}} >{key}</a>)
}

    </div>}
    <div className=' flex flex-col py-3'>
    <span className='h5'>Inventory - (Qty)</span>
    <>{ loading ? <Spinner size={"sm"} />:<>{item && item.inventory && !item.isSanmar ?<table className="table w-100 text-left">
  <thead>
    <tr>
      {Object.keys(item.inventoryCount).map(d =><th scope="col">{d}</th>)}
    </tr>
  </thead>
  <tbody>
    <tr>
        {
            _.map(item.inventoryCount,dl => <td>{dl}</td>)
        }
      
    </tr>
  
  </tbody>
</table>:<table className="table w-100 text-left">
  <thead>
    <tr>
      {sizeListed.map(d =><th scope="col">{d}</th>)}
    </tr>
  </thead>
  <tbody>
    <tr>
        {
// renderQty()
item.isSanmar ?_.map(sizeListed,dl => <td>{ renderQty(dl.quantityAvailable,dl)}</td>):
 
sizeListed.map(d => <td>{renderQtyNS(colorGrpd[selectedColor],d) }</td>)

// _.map(colorGrpd[selectedColor],dl => <td>{renderQty( _.sumBy(dl.warehouses,'qty'),dl) }</td>)
          
        }
      
    </tr>
  
  </tbody>
</table>}</>
}</>
    </div>
    </div>
    :<></>
  )
}

export default ProductColorList