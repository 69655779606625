import FooterTwo from 'components/footers/FooterTwo'
import React from 'react'


const USPFSolutions = () => {
  return (
    <>
    <section className="md:pt-[80px] md:pb-[100px] pt-[80px] pb-[100px] relative bg-cover bg-center bg-white">
    <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full bg-[url('../images/home_sec_layer-2.png')]"></div>
        
        
    </section>
  
    <FooterTwo />
    </>
  )
}

export default USPFSolutions