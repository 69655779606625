import React, { useState,useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import loginLogo from '../assets/images/logo-light.png'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import CryptoJS from "crypto-js";
import { Button, Checkbox, FormContainer, FormItem, Input ,toast,Notification} from 'components/ui'
import { PasswordInput } from 'components/shared'
import useAuth from 'utils/hooks/useAuth'

const validationSchema = Yup.object().shape({
    
    email: Yup.string().email('Invalid email').required('Please enter your email'),
    password: Yup.string().required('Please enter your password').min(8, 'Password must be at least 8 characters'),
    // rememberMe: Yup.bool(),
})
const Login = ({callback}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const currentShop = params.shop;
    const [inviteToken, setinviteToken] = useState(null);
    const disableSubmit = false;
    const [dataBlock] = useState({
        title:"Sign in",
        subTitle:"Sign in to your account using email and password provided during registration."
    })
    const { signIn } = useAuth()
    const param = searchParams.get('token');
    // console.log(searchParams.get());
    useEffect(() => {
        const param = searchParams.get('token');
        // console.log(searchParams.get());
        if (param) {
          
          // 👇️ delete each query param
          setinviteToken(param)
          // searchParams.delete('token');
          // searchParams.delete('setmark');
    
          // 👇️ update state after
          setSearchParams(searchParams);
        }
        
      
        return () => {
          
        }
      }, [])
    const onSignIn = async (values, setSubmitting) => {
        const { email, password } = values;
        const encryptedPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_SECRET).toString();
        const encpass =  CryptoJS.AES.encrypt(encryptedPassword, process.env.REACT_APP_SECRET_ADDON).toString();
        setSubmitting(true)

        const result = await signIn({ email, encpass,inviteToken,activepath:currentShop })

        if (result.status === 'failed') {
            toast(<Notification closable type="danger" duration={2000}>
            {result.message}
  </Notification>)
        }

        setSubmitting(false)
    }
   
  return (
    <>
         <section className="items-center flex bg-[url('../images/bg-cta-1.png')] bg-cover bg-center relative py-20 min-h-screen">
        <div className="bg-[#0d0f14]/25 absolute inset-0 backdrop-blur-md"></div>
        <div className="container relative">
            {/* <div className="grid grid-cols-1">
                <div className="max-w-[450px] mx-auto">
                    <Link to="/landing/">
                        <img src={loginLogo} alt="logo" className="mx-auto h-10"/>
                    </Link>
                </div>
            </div> */}
            <div className="grid grid-cols-1 mt-16">
                <div className="max-w-[450px] mx-auto py-10 px-8 rounded-xl bg-white w-full">
                    <h4 className="font-bold text-2xl mb-2 text-center capitalize">{dataBlock.title}</h4>
                    <p className="text-muted text-center">{dataBlock.subTitle}</p>
                    <Formik
                // Remove this initial value
                initialValues={{
                    email: '',
                    password: '',
                    rememberMe: true,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    if (!disableSubmit) {
                        onSignIn(values, setSubmitting)
                    } else {
                        setSubmitting(false)
                    }
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form className="mt-6 space-y-4">
                        <FormContainer className="mt-6 space-y-4">
                            
                        <FormItem
                                label="Email"
                                invalid={errors.email && touched.email}
                                errorMessage={errors.email}
                            >
                                <Field
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    component={Input}
                                />
                            </FormItem>
                            <FormItem
                                label="Password"
                                invalid={errors.password && touched.password}
                                errorMessage={errors.password}
                            >
                                <Field
                                    autoComplete="off"
                                    name="password"
                                    placeholder="Password"
                                    component={PasswordInput}
                                />
                            </FormItem>
                            {/* <div className="flex justify-between mb-6">
                                <Field
                                    className="mb-0"
                                    name="rememberMe"
                                    component={Checkbox}
                                    children="Remember Me"
                                />
                                
                            </div> */}
                            <div className="mt-4 text-center py-4">
                              <Button variant="solid" loading={isSubmitting}  type="submit" className="btn bg-green btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]" >Sign In</Button>
                            </div>
                        </FormContainer>
                    </Form>
                )}
                    </Formik>
                    {/* <form className="mt-6 space-y-4">
                        <div>
                            <label className="font-bold">Email Address</label>
                            <input type="text" className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom" placeholder="Email Id"/>
                        </div>
                        <div>
                            <label className="font-bold">Password</label>
                            <input type="password" className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom" placeholder="Password"/>
                        </div>
                        <div>
                            <input type="checkbox" className="form-checkbox border-[#ced4da] rounded focus:ring-transperent focus:outline-none h-4 w-4 text-custom" id="sign"/>
                            <label htmlFor="sign" className="ml-1 relative top-[2px]">Keep me signed in</label>
                        </div>
                        <div>
                            <button type="submit" className="btn-custom w-full">Sign In</button>
                        </div>
                    </form> */}
                    <div className="mt-4 text-center">
                   Forgot Password? <Button className="ml-3" type="link" onClick={()=>callback("reset")} >Reset</Button>
                        {/* <Link to="/account/pwreset" className="font-bold">Contact team admin to reset password</Link> */}
                    </div>
                </div>
               
            </div>
        </div>
    </section>
    </>
  )
}

export default Login
