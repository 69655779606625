import React, { useEffect, useState } from "react";
import { NavLink, useLocation, matchPath, Link } from "react-router-dom";
import { AddMinusInput, Button, Drawer,Menu } from 'components/ui'
import { ActionLink } from 'components/shared'
import logo from "assets/images/logo-dark.png";
import mainNavConfig from 'configs/navigation.config/MainNav'
import productsNav from 'configs/navigation.config/products'
import {
  NAV_ITEM_TYPE_TITLE,
  NAV_ITEM_TYPE_COLLAPSE,NAV_ITEM_TYPE_CUSTOM,
  NAV_ITEM_TYPE_ITEM,
} from 'constants/navigation.constant'
import { APP_PREFIX_PATH } from "constants/route.constant";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "utils/hooks/useAuth";
import Dropdown from "components/ui/Dropdown";
import { BsBellFill, BsCart3, BsCart4 } from "react-icons/bs";
import { DecreaseQuantity, DeleteCart, IncreaseQuantity, ToggleCartBar } from "store/cart/actions";
const Header = ({shop}) => {
  const { signOut ,authenticated} = useAuth()
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false)
  // const [showCart, setshowCart] = useState(false)
  const shopInfo = useSelector((state) => state.auth.shop)
  const user = useSelector((state) => state.auth.user)
  const carts = useSelector((state) => state.cart.todoProduct);
  const showCart = useSelector((state) => state.cart.todoProduct);
  const dispatch = useDispatch();
  
  // console.log(showCart.showCart);
  // console.log(carts.numberCart);
    const openDrawer = () => {
      // setshowCart(true)
      dispatch(ToggleCartBar(true))
    }
    const closeCart = ()=>{
      // setshowCart(false)
      dispatch(ToggleCartBar(false))
    }
    
    const onDrawerClose = (e) => {
        
        setIsOpen(false)
    }



  const menuActive = () => {
    // const menu = document.querySelector(".mobile-menu");
    // menu.classList.toggle("hidden");
    setIsOpen(true)

  };
  const handleToggle = (expanded, e) => {
    console.log('expanded', expanded)
    console.log('event', e)
}
const renderCollaps = (nav,index) => {
  return <Menu.MenuCollapse
  eventKey={`iten-${index}`}
  label="Item 3"
  
>
  <Menu.MenuItem eventKey="item-3-11">Item 3.1</Menu.MenuItem>
  <Menu.MenuItem eventKey="item-3-21">Item 3.2</Menu.MenuItem>
</Menu.MenuCollapse>
}
const Footer = (
  !shopInfo.admin_payment ? <div className="text-left w-full space-y-2">
      <span className="text-sm text-gray-400">Taxes and shipping calculated at checkout</span>
      <NavLink to="./checkout"><Button size="sm" disabled={!carts.numberCart} variant="solid" className="w-100" block onClick={() => closeCart()}>
          Checkout
      </Button>
      </NavLink>
  </div>:
  <div className="text-left w-full space-y-2">
 
  <NavLink to="./checkout"><Button size="sm" disabled={!carts.numberCart} variant="solid" className="w-100" block onClick={() => closeCart()}>
      Complete Order
  </Button>
  </NavLink>
</div>

)
const removeItem = (key)=>{
  
  dispatch(DeleteCart(key))
}
const handleSize = (qty,key,state)=>{
  if(state === "plus"){
    dispatch(IncreaseQuantity(key))
  }
  else{
    dispatch(DecreaseQuantity(key))

  }
  
}
  return (
    <div>
      <Drawer
                title="Menu"
                isOpen={isOpen}
                onClose={onDrawerClose}
                onRequestClose={onDrawerClose}

                placement={"left"}
            >
                <Menu>
                <a href={"/"+shopInfo?shopInfo.shop_path:""} themeColor={false}><Menu.MenuItem eventKey={`item-home`}>Home</Menu.MenuItem></a>
                <a href={"/"+shopInfo?shopInfo.shop_path+"/orders":""} themeColor={false}><Menu.MenuItem eventKey={`item-home`}>Orders</Menu.MenuItem></a>
                  

                
            </Menu>
            </Drawer>
            <Drawer
                title="Your Cart"
                isOpen={showCart.showCart}
                onClose={closeCart}
                onRequestClose={closeCart}
                footer={Footer}
                placement={"right"}
            >
              {
                carts.Carts.length === 0 && <div className="w-100 text-center text-slate-400">Cart is empty</div>
              }
               <div className="flex flex-col space-y-3 justify-center">{
                carts.Carts.map((item,key) => 
                <div key={key} className="flex flex-row space-x-2 text-gray-400">
                  <div > <img src={item.image} className="w-28" /> </div>
                  <div className="flex flex-1 flex-col space-y-1">
                  <div>{item.name}</div>
                  {!shopInfo.admin_payment && <div>${item.price}</div>}
                  <div className="flex space-x-2 text-sm"><div className="font-bold">Color:</div> <div>{item.color}</div></div>
                  <div className="flex space-x-2 text-sm"><div className="font-bold">Size:</div> <div>{item.size}</div></div>
                  <div className="flex flex-col space-y-2 text-sm">
                  <div className="font-bold">Quantity:</div>
                  <div className="flex space-x-2 ">
                    <AddMinusInput value={item.quantity} name="textsize" itemkey={key} increameent={1} handleValueChange={handleSize}  />
                    <Button variant="plain" onClick={()=>removeItem(key)}>Remove</Button></div>
                    </div>

                  </div>
                 </div> )

               }</div>
            </Drawer>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed w-full horizontal-nav flex items-center">
          <div className="container h-full sm:px-0">
            <div className="flex items-center justify-between h-full">
              <div className="flex items-center space-x-3">
            <button
                onClick={() => menuActive()}
                className="navbar-toggler hidden text-end mobile-menu-button"
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu icon-align-right"></i>
                </span>
              </button>
              {shopInfo && <NavLink className="navbar-brand" to={`/${shopInfo.shop_path}`}>
                {shopInfo && shopInfo.shop_logo_pic?<img src={shopInfo.shop_logo_pic} className="mr-2 max-h-[60px]" alt="logo" />:<span>{shopInfo.shop_name}</span>}
              </NavLink>}
              </div>

              <div
                className="navbar-collapse flex-1 mobile-menu hidden min-[991px]:flex space-x-3 items-end justify-end"
                id="navbarNav"
              >
                <ul className="navbar-nav justify-end horizontal-menu flex-grow px-4">
                {authenticated &&   <li className={"scroll dropdown group/menu"} key={0}>
                  {/* {user.teams} */}
                  <a
                     href={"#"}
                    className={"active dropdown-toggle"}
                    >{shopInfo.shop_name}</a> 
                  <ul className="dropdown-menu hidden group-hover/menu:block" >
                    <li className="p-1 px-3 font-semibold">Change Team</li>
                  {
                    user.teams.map((subnav,k)=>
                    (
                      <li key={k}>
                      <a className="dropdown-toggle" href={"/"+subnav.path} target={"_self"}>{subnav.name}</a>
                    </li>
                    ))
                  }
                  </ul>
                </li>

                }
                  <li className="scroll  ">
                    <NavLink
                     to="./products"
                      className={({ isActive }) => {
                        return location.pathname.match("/proucts")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Products</span>
                    </NavLink>
                    </li>
                 
                {authenticated && <>  <li className="scroll  ">
                
            </li>
                  <li className="scroll  ">
                    <NavLink
                     to="./orders"
                      className={({ isActive }) => {
                        return location.pathname.match("/documentation")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Orders</span>
                    </NavLink>
                   
                  </li>
                  {/* <li className="scroll  ">
                    <NavLink
                     to="./orders"
                      className={({ isActive }) => {
                        return location.pathname.match("/documentation")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Profile</span>
                    </NavLink>
                   
                  </li> */}
                  </>}
                  <li className="scroll  ">
                    <div className="absolute right-3 top-3 rounded-full w-6 h-6 text-white bg-red-600 flex items-center justify-center p-2 text-xs font-bold" >{carts.numberCart}</div>
                    <NavLink
                     to="#"
                      onClick={openDrawer}
                      className={({ isActive }) => {
                        return location.pathname.match("/proucts")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <BsCart4 size={20} />
                    </NavLink>
                    </li>
                </ul>
               {authenticated && <Button variant="solid"  onClick={signOut}
                     className="">
                  Logout
                  </Button>}
                  
               
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
