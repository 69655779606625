import React, { useEffect } from 'react'

import reducer from './store'
import { injectReducer } from 'store/index'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'

import { Outlet,useParams,useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/shopheader/Header'
import Loading from 'components/shared/Loading'
import { useState } from 'react'
import { API_PREFIX_PATH } from 'constants/route.constant'
import axios from 'axios'
import { setShopData } from './store/stateSlice'
import { setShop } from 'store/auth/shopSlice'
injectReducer('teamShop', reducer)

const ShopPageLayout = () => {
  const [loading, setloading] = useState(true)
  const dispatch = useDispatch()
  const params = useParams();
  const navigate = useNavigate()
  
  useEffect(() => {
    getShopDetails(params.shop);
    return () => {
      
    }
  }, [])
  const getShopDetails = async(shopId)=>{
    let data = JSON.stringify({
      shopId:shopId
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API_PREFIX_PATH+'shop/get?twitter=true',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      if(response.data.shop){
        dispatch(setShop(response.data.shop))
      }
      else{
        navigate("/")
      }
      
      setloading(false)


    })
    .catch((error) => {
      console.log(error);
    });
  }
  return (
    <>
    <Loading loading={loading}>
      <Header shop={null}/>
      <Outlet/>
      </Loading>
    </>
  )
}

export default ShopPageLayout
