import React, { useEffect } from 'react'
import { injectReducer } from 'store/index'
import reducer from './store'
import { useLocation,useSearchParams ,useParams} from 'react-router-dom'
import ProductTable from './components/ProductTable'
import { useDispatch, useSelector } from 'react-redux'
import MenuList from './components/MenuList'
import classNames from 'classnames'
import { setFilterData } from './store/dataSlice'
import { cloneDeep } from 'lodash'
import FooterTwo from 'components/footers/FooterTwo'




injectReducer('productlist', reducer)
const ProductsList = () => {
  // const [searchParams,setSearchParams] = useSearchParams();
  // const dispatch = useDispatch()
  
  
  // const type = useSelector((state) => state.theme.layout.type)
  const selectedCategory = useSelector(
    (state) => state.productlist.state.selectedCategory
)
const selectedSubCategory = useSelector(
    (state) => state.productlist.state.selectedSubCategory
)


  // const sideBarExpand = true;
  
  
  return (
    <>
      
            <div className="lg:flex items-center justify-center mb-4 py-2 !pt-[90px]">
            <h3 className="mb-4 lg:mb-0 text-center">{selectedCategory.label}{selectedCategory.submenu?` - ${selectedSubCategory.label}`:""}</h3>
            
            <ProductTable />
                
            </div>

       <FooterTwo />
    </>
  )
}

export default ProductsList