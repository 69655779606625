import React from "react";
import {appeal, designtips, faq,shiipping} from "../../assets/fake-data/landingpage-one/faq";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import 'react-accessible-accordion/dist/fancy-example.css';

const FAQ = () => {
  return (
    <>
      <section className="pt-10 pb-[170px] bg-light">
        <div className="container">
         
          <div className="faqs-page ">
            <div className="mx-auto w-full max-w-[650px]">
            
            <h2 className="font-bold text-[32px] md:text-[32px] pl-[20px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                How do I?
              </h2>
              <Accordion className="faq space-y-2.5">
                {faq.map((x) => {
                  return (
                    <AccordionItem
                      key={x.id}
                      className="ac mt-0 bg-white border-none"
                    >
                      <AccordionItemHeading >
                        <AccordionItemButton
                          type="button"
                          className="ac-trigger !p-5 relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                        >
                          <span className="leading-[22px]">{x.question}</span>
                          <div className="leading-[22px]">
                            <i className="mdi mdi-plus plus text-xl font-bold leading-[0px]"></i>
                            <i className="mdi mdi-minus minus text-xl font-bold leading-[0px]"></i>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="ac-panel">
                        <p className="ac-text !p-[26px] !pt-[6px] !font-nunito !text-[15px] !font-normal !leading-[22px] !text-muted">
                          {x.answer}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
            <div className="mx-auto w-full max-w-[650px]">
            <h2 className="font-bold text-[32px] md:text-[32px] pl-[20px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                Shipping FAQs
              </h2>
              <Accordion className="faq space-y-2.5">
                {shiipping.map((x) => {
                  return (
                    <AccordionItem
                      key={x.id}
                      className="ac mt-0 bg-white border-none"
                    >
                      <AccordionItemHeading >
                        <AccordionItemButton
                          type="button"
                          className="ac-trigger !p-5 relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                        >
                          <span className="leading-[22px]">{x.question}</span>
                          <div className="leading-[22px]">
                            <i className="mdi mdi-plus plus text-xl font-bold leading-[0px]"></i>
                            <i className="mdi mdi-minus minus text-xl font-bold leading-[0px]"></i>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="ac-panel">
                        <p className="ac-text !p-[26px] !pt-[6px] !font-nunito !text-[15px] !font-normal !leading-[22px] !text-muted">
                          {x.answer}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>
          <div className="faqs-page mt-20">
            <div className="mx-auto w-full max-w-[650px]">
            
            <h2 className="font-bold text-[32px] md:text-[32px] pl-[20px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                Appael FAQs
              </h2>
              <Accordion className="faq space-y-2.5">
                {appeal.map((x) => {
                  return (
                    <AccordionItem
                      key={x.id}
                      className="ac mt-0 bg-white border-none"
                    >
                      <AccordionItemHeading >
                        <AccordionItemButton
                          type="button"
                          className="ac-trigger !p-5 relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                        >
                          <span className="leading-[22px]">{x.question}</span>
                          <div className="leading-[22px]">
                            <i className="mdi mdi-plus plus text-xl font-bold leading-[0px]"></i>
                            <i className="mdi mdi-minus minus text-xl font-bold leading-[0px]"></i>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="ac-panel">
                        <p className="ac-text !p-[26px] !pt-[6px] !font-nunito !text-[15px] !font-normal !leading-[22px] !text-muted">
                          {x.answer}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
            <div className="mx-auto w-full max-w-[650px]">
            <h2 className="font-bold text-[32px] md:text-[32px] pl-[20px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                Design FAQs
              </h2>
              <Accordion className="faq space-y-2.5">
                {designtips.map((x) => {
                  return (
                    <AccordionItem
                      key={x.id}
                      className="ac mt-0 bg-white border-none"
                    >
                      <AccordionItemHeading >
                        <AccordionItemButton
                          type="button"
                          className="ac-trigger !p-5 relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                        >
                          <span className="leading-[22px]">{x.question}</span>
                          <div className="leading-[22px]">
                            <i className="mdi mdi-plus plus text-xl font-bold leading-[0px]"></i>
                            <i className="mdi mdi-minus minus text-xl font-bold leading-[0px]"></i>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="ac-panel">
                        <p className="ac-text !p-[26px] !pt-[6px] !font-nunito !text-[15px] !font-normal !leading-[22px] !text-muted">
                          {x.answer}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
