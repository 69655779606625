
import React, { useState } from "react";
import feature from "assets/fake-data/landingpage-two/feature";
import feature3 from 'assets/images/connect/choose-us.png'
import feature5 from 'assets/images/connect/fullfilment-services.png'
import penruller from 'assets/images/icon/pen&ruller.svg'
import code from 'assets/images/icon/code.svg'
import headphones from 'assets/images/icon/headphones.svg'
import groupchat from 'assets/images/icon/groupchat.svg'
import { Avatar, Steps } from "components/ui";
import { HiCheck, HiChevronRight, HiCreditCard } from "react-icons/hi";
import ConnectBtn from "components/buttons/ConnectBtn";
import { Link } from "react-router-dom";

const LOGO_SRC_PATH = '/images'


const WhyStore = ({store}) => {
    const [dataBlock] = useState({
        title: "Why",
        subTitle: "We are here to offer the highest quality printing services to you and your customers and the most competitive prices. The quick turnaround time makes us one of the leaders in the T-shirt printing business. We offer DTG, Screen-printing, Embroidery, Sublimation and Fulfillment services."
    })
    const storeList = {
        shopify:"Shopify",
        etsy:"Etsy",
        woocommerce:"WooCommerce",
        amazon:"Amazon",
    }
    const whyInfo = {
        shopify:[{
            "title":"User-Friendly Interface",
            "desc":"Shopify's user-friendly interface is perfect for beginners, requiring no coding or design skills. With its drag-and-drop feature, customizing your store ' s layout, adding products, and managing inventory is a breeze."
        },
        {
            "title":"Robust Features",
            "desc":"Shopify offers diverse features to simplify e-commerce. With customizable themes, mobile adaptability, secure payments, and integrated SEO tools, it empowers you to build a standout online store."
        },
        {
            "title":"Global Reach",
            "desc":"Shopify facilitates global outreach with multilingual, multicurrency, and international shipping capabilities. Its built-in localization simplifies adapting your store to diverse markets, ensuring a seamless shopping experience worldwide."
        }],
        etsy:[{
            "title":"Established Marketplace",
            "desc":"Etsy is a well-established marketplace with a strong reputation, which can help build trust with potential customers."
        },
        {
            "title":"Community and Support",
            "desc":"Etsy offers a supportive community of sellers and resources to help you grow your business, including seller education and marketing tools."
        },
        {
            "title":"Ease of Use",
            "desc":"Setting up a shop on Etsy is relatively easy, and the platform provides tools for managing your inventory, processing orders, and communicating with customers."
        }],
        woocommerce:[{
            "title":"Flexibility and Customization",
            "desc":"WooCommerce, a WordPress plugin, provides extensive flexibility and customization. With numerous themes and extensions, you can personalize your online store to reflect your brand's identity and meet specific functional needs. Whether you sell physical items, digital downloads, or services, WooCommerce equips you with the tools for a unique shopping experience."
        },
        {
            "title":"Scalability and Growth Potential",
            "desc":"WooCommerce grows with your business, catering to both startups and established enterprises. Its modular design and vast plugin options allow you to expand functionality as you scale. Whether you require advanced inventory management, subscription services, or third-party integrations, WooCommerce adapts to your evolving needs."
        },
        {
            "title":"Extensive Customization Options",
            "desc":"WooCommerce allows extensive customization with various themes and plugins, enabling you to create a unique and brand-specific shopping experience for your customers."
        }],
        amazon:[{
            "title":"Massive Customer Base",
            "desc":"Amazon has a vast customer base, providing you with access to millions of potential customers worldwide."
        },
        {
            "title":"Trust and Credibility",
            "desc":"Being associated with Amazon can lend credibility to your brand, as customers trust the Amazon platform for secure transactions and reliable customer service."
        },
        {
            "title":"Global Reach",
            "desc":"Amazon operates in many countries, offering you the opportunity to sell your products internationally and reach a global audience."
        }]
    }

  return (
    <>
     
      <section className=" bg-center pb-[30px] bg-white relative">
        <div className="container-fluid px-[30px]">
         
          <div className="grid grid-cols-1">
            
            <div className="text-center flex items-center justify-center space-x-6">
              <h2 className="font-bold text-[28px] md:text-[40px]  mb-2 relative z-10">
                <span className=" h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {`${dataBlock.title} ${storeList[store]}?`}
              </h2>
              {
                store === "shopify"?
                <div className="w-16 h-16 md:w-32 md:h-32 rounded-full flex border-shopify  border-2 items-center justify-center p-1 bg-white  overflow-hidden"><div className="w-full h-full flex rounded-full items-center justify-center bg-neutral-800"><img className="w-20  " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/${store}.svg`} /></div></div>
                : store === "etsy"?<div className="w-16 h-16 md:w-32 md:h-32 rounded-full flex border-etsy  border-2 items-center justify-center p-1 bg-white  overflow-hidden"><div className="w-full h-full flex rounded-full items-center justify-center bg-etsy"><img className="w-20  " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/${store}.svg`} /></div></div>
                :store === "woocommerce"?<div className="w-16 h-16 md:w-32 md:h-32 rounded-full flex border-woocommerce  border-2 items-center justify-center p-1 bg-white  overflow-hidden"><div className="w-full h-full flex rounded-full items-center justify-center bg-woocommerce"><img className="w-20  " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/${store}.svg`} /></div></div>
                :<div className="w-16 h-16 md:w-32 md:h-32 rounded-full flex border-amazon  border-2 items-center justify-center p-1 bg-white  overflow-hidden"><div className="w-full h-full flex rounded-full items-center justify-center bg-white"><img className="w-20  " src={`${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/${store}.svg`} /></div></div>
            }
            </div>
          </div>
          <div className="flex md:!flex-row flex-col md:!items-start items-center md:!space-y-0 space-y-8 justify-center  mt-16 ">
            {
                whyInfo[store].map(item =>
                    <div className="flex flex-col whystore md:w-3/4">
                    <div className="flex items-start flex-col flex-1">
                       <div className="flex flex-row space-x-3 items-center">
                        <HiCheck  color="" className={store==="shopify"?"text-shopify textheadicon":store==="etsy"?"text-etsy textheadicon":store==="woocommerce"?"text-woocommerce textheadicon":store==="amazon"?"text-amazon":"textheadicon"}/><h1 className="textallhead font-bold">{item.title}</h1></div> 
                       
                    </div>
                    <div className="text-lg">{item.desc}</div>
                    </div>

                )
            }
           
            
          </div>
          
        </div>
      
      </section>
      <div className="w-full mt-8  mb-8 flex " style={{backgroundImage:`url("${process.env.PUBLIC_URL}${LOGO_SRC_PATH}/bg-screen.png")`, backgroundPosition:"bottom"}}>
        <div className="bg-white/90 bg-opacity-25 w-full flex items-center justify-center space-y-10 py-8 flex-col h-full ">
            <h1 className="textallhead  text-center font-bold mb:px-10 px-3 " >
            {`Connect your ${storeList[store]} Store with us Today!`}
            </h1>
        <div><Link to={`https://usprintfactory.com/app/dashboard/connect/${store}`} target="_blank">
              <ConnectBtn text="CONNECT" icon={<HiChevronRight className='bg-white text-red-600 rounded-full' size={28} />} />
              </Link></div>
        </div>
        </div>

        
    </>
  );
};

export default WhyStore;
