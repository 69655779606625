import Login from 'pages/Login'
import PWReset from 'pages/PWReset'
import React, { useState } from 'react'

const ShopAccount = () => {
    const [view, setview] = useState("login")
    const callback = (state) =>{
        setview(state)
    }
  return (
    view === "login"?
    <Login callback={callback}/>
    :
    <PWReset callback={callback}/>
  )
}

export default ShopAccount