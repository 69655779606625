import React from 'react'
import { Avatar, Card, Button } from 'components/ui'
import NumberFormat from 'react-number-format'
const LOGO_SRC_PATH = '/img/others/UPS_logo.svg'
const LOGO_SRC_USPS = '/img/others/USPS-Logo.png'


const ShippingInfo = ({ data }) => {
    let shippingInfo;
    try {
         shippingInfo = JSON.parse(data.webhook_response);
    } catch (error) {
         shippingInfo = null;
    }
    
    // console.log(shippingInfo.ShipmentInfo.CarrierCode);
    return (
        <Card className="mb-4">
            <h5 className="mb-4">Shipping</h5>
            {data.tracking_id !== "" && <div className="flex items-center justify-between mb-6">
                <div className="flex items-center justify-start">
                    <Avatar size={60} src={shippingInfo.ShipmentInfo.CarrierCode === "ups"?`${process.env.PUBLIC_URL}/${LOGO_SRC_PATH}`:`${process.env.PUBLIC_URL}/${LOGO_SRC_USPS}`} />
                    <div className="ltr:ml-2 rtl:mr-2 flex flex-col gap-y-1">
                        <h6 className='capitalize'>{shippingInfo.ShipmentInfo.CarrierCode === "ups"?"UPS":"USPS"}</h6>
                        <span>
                            <strong>Tracking - </strong> #{data.tracking_id}
                        </span>
                        <span>
                        {shippingInfo.ShipmentInfo.ShipDate}
                        </span>
                    </div>
                </div>
                {/* <span className="font-semibold">
                    <NumberFormat
                        displayType="text"
                        value={(
                            Math.round(data.deliveryFees * 100) / 100
                        ).toFixed(2)}
                        prefix={'$'}
                        thousandSeparator={true}
                    />
                    
                </span> */}
            </div>}
            {data.tracking_id !== "" &&  
            <>
            {
               shippingInfo.ShipmentInfo.CarrierCode === "ups"?
               <a href={`https://www.ups.com/track?loc=en_US&tracknum=${data.tracking_id}&requester=ST/trackdetails`} target="_blank" class="text-primary" alt="Track shipment" title="Track shipment" ><Button block>Track Shipment</Button></a>
               :
             <a href={`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${data.tracking_id}&tABt=true`} target="_blank" class="text-primary" alt="Track shipment" title="Track shipment" ><Button block>Track Shipment</Button></a>
            }
            </>}
            
        </Card>
    )
}

export default ShippingInfo
