import React from 'react'
import { AdaptableCard, Container } from 'components/shared'
import { useState } from 'react'
import Loading from 'components/shared/Loading'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API_PREFIX_PATH } from 'constants/route.constant'
import axios from 'axios'
import { Button, Notification, toast } from 'components/ui'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const OrderInfo = () => {
    const [loading, setloading] = useState(true);
    const [orderDetails, setorderDetails] = useState();
    const [shippingInfo, setshippingInfo] = useState();
    const shopInfo = useSelector((state) => state.auth.shop)

    const params = useParams();
    const navigate = useNavigate()

    useEffect(() => {
      getOrderInfo(params.id)
    
      return () => {
        
      }
    }, [])
    const getOrderInfo = async(id)=>{
        let data = JSON.stringify({
            id:id
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: API_PREFIX_PATH+'user/orders-details?twitter=true',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
            if(response.data){
             if(response.data.data[0]){
                setorderDetails(response.data.data[0])
                if(response.data.data[0]["webhook_response"]){
                    setshippingInfo(JSON.parse(response.data.data[0]["webhook_response"]))
                }
             }
             else{
                toast(<Notification closable type="warning" duration={2000}>
                Order not found.
             </Notification>)

                navigate("/"+params.shop)
             }
             
            }
            else{
              
            }
            
            setloading(false)
      
      
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const itemtotal = (item)=>{
        if(item.salecost){
            return (item.salecost * item.qty).toFixed(2)
        }
        else{
            return (15 * item.qty).toFixed(2)
        }
        
    }
    const getFullTotal = (product)=>{
        if(product){
            const total = parseFloat(product["Subtotal"])+parseFloat(product["shippingCost"]);
            return (total).toFixed(2);
        }
        else{
            return "0.00"
        }
    }
    const renderSubtitle  = (orderDetails)=>{
        switch(orderDetails.print_action_by_vendor){
       
            case "Que":
                return "Order received"
            case "Pending":
                return "Order in processing"
            case "Fulfilled":
                return "Order shipped"
                default:
            return  "Order received";
        }
    }
    const renderFulltitle  = (orderDetails)=>{
        switch(orderDetails.print_action_by_vendor){
       
            case "Que":
                return "Order has been received"
            case "Pending":
                return "Order has been sent for processing"
            case "Fulfilled":
                return "Order has been shipped"
                default:
            return  "Order has been received";
        }
    }
    const renderFullSubtitle  = (orderDetails)=>{
        switch(orderDetails.print_action_by_vendor){
       
            case "Que":
                return `We have recieved your order on ${dayjs.unix(orderDetails["updatedAt"]).format('MMM D, YYYY')}.`
            case "Pending":
                return `Order has been sent for processing on ${dayjs.unix(orderDetails["updatedAt"]).format('MMM D, YYYY')}.`
            case "Fulfilled":
                return `Order has been shipped on ${dayjs.unix(orderDetails["updatedAt"]).format('MMM D, YYYY')}.`
                default:
            return  `We have recieved your order on ${dayjs.unix(orderDetails["updatedAt"]).format('MMM D, YYYY')}.`
        }
    }
    
  return (

    <section className=" relative min-h-screen">
    <AdaptableCard className="h-full min-h-screen" bodyClass="h-full min-h-screen">
        <Loading loading={loading}>
        { orderDetails && <div bodyClass="h-full min-h-screen">
        <div className='flex w-full min-h-screen'>
        <div className='w-2/3 flex p-8 justify-end'>
         <div className='flex flex-1 flex-col space-y-2 p-8'>
            {orderDetails["storeInfo"] && <a href={`/${params.shop}`}><h2 className='text-4xl'>{orderDetails["storeInfo"]["name"]}</h2></a>}
            <div className='flex flex-col space-y-3 p-8  '>
            <div className='flex flex-col space-y-1 p-3 px-0 '>
                <div>Order {orderDetails["order_id"]}</div>
                <div className='text-xl font-semibold'>{renderSubtitle(orderDetails)}</div>

            </div>
            <div className='flex flex-col space-y-1 p-3 rounded-md border border-gray-200'>
                <div className='text-lg font-semibold'>{renderFulltitle(orderDetails)}</div>
                <div className='text-sm'>{renderFullSubtitle(orderDetails)}</div>

            </div>
            {
                orderDetails["print_action_by_vendor"] === "Fulfilled" &&
                <div className='flex flex-col space-y-3 p-3 rounded-md border border-gray-200'>
                <div className='text-lg font-semibold'>Shipping Information</div>
                <div className='flex justify-between items-center'> <div className='flex flex-col space-y-1'>
                <div className=' font-semibold'>Tracking #</div>
                {orderDetails["tracking_id"] && <div className='text-sm'>{orderDetails["tracking_id"]}</div>}
                {orderDetails["tracking_id"] && <div className='text-sm font-bold'>{orderDetails["tracking_info"]["crName"]}</div>}

            </div>
            <div>
            {
               
               <a href={orderDetails["tracking_info"]["link"]} target="_blank" class="text-primary" alt="Track shipment" title="Track shipment" >
                <Button
                                    variant="solid"
                                    
                                    
                                    className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[5px]"
                                >
                                   Track Shipment
                                </Button></a>
            
            }</div>
            </div>
          
            </div>

            }
            <div className='flex flex-col space-y-3 p-3 rounded-md border border-gray-200'>
                <div className='text-lg font-semibold'>Order Details</div>
                <div className='flex flex-col space-y-1'>
                <div className=' font-semibold'>Contact information</div>
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["email"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["phone"]}</div>}

            </div>
            <div className='flex justify-between'>
            <div className='flex flex-col space-y-1'>
                <div className=' font-semibold'>Shipping address</div>
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["name"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["address"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["address1"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{`${orderDetails["shipping_address"]["city"]} ${orderDetails["shipping_address"]["state"]} ${orderDetails["shipping_address"]["zip"]}`}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["country"]}</div>}


            </div>
            <div className='flex flex-col space-y-1'>
                <div className=' font-semibold'>Billing address</div>
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["name"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["address"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["address1"]}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{`${orderDetails["shipping_address"]["city"]} ${orderDetails["shipping_address"]["state"]} ${orderDetails["shipping_address"]["zip"]}`}</div>}
                {orderDetails["shipping_address"] && <div className='text-sm'>{orderDetails["shipping_address"]["country"]}</div>}


            </div>
            </div>
            </div>
            <div className='flex flex-row justify-between space-y-3 p-3 items-center '>
            <div>Need help? <a href="mailto:support@usprintfactory.com" className='text-blue-500'>Contact us</a></div>
            <div> <a href={`/${params.shop}`}><Button
                                    variant="solid"
                                    
                                    
                                    className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
                                >
                                    {shopInfo.admin_payment.toString() === "false" ? "Continue shopping":"Continue to Store"}
                                </Button></a></div>
            </div>
            </div>
         </div>
        </div>
        <div className='w-1/2 min-h-full  bg-gray-100'>
        <div className='flex flex-col p-5'>
        <div className="flex flex-col w-full p-8 space-y-3 justify-center">{
                !loading && orderDetails && orderDetails["orderProducts"].map((item,key) => 
                <div key={key} className="flex flex-row space-x-2 text-gray-400">
                  <div > <img src={item.image} className="w-16" /> </div>
                  <div className="flex flex-1 flex-col space-y-1">
                  <div>{item.qty} x {item.name.split(" - ")[0]}</div>
                  <div>{item.name.split(" - ")[1]}</div>
                  {/* <div>${item.price}</div> */}
                  <div className="flex space-x-2 text-sm"><div className="font-bold">Color:</div> <div>{item.color}</div></div>
                  <div className="flex space-x-2 text-sm"><div className="font-bold">Size:</div> <div>{item.size}</div></div>
                  {/* <div className="flex flex-col space-y-2 text-sm">
                  <div className="font-bold">Quantity:</div>
                  <div className="flex space-x-2 ">
                    <AddMinusInput value={item.quantity} name="textsize" itemkey={key} increameent={1} handleValueChange={handleSize}  />
                    <Button variant="plain" onClick={()=>removeItem(key)}>Remove</Button></div>
                    </div> */}

                  </div>
                  {shopInfo.admin_payment.toString() === "false" &&<div className='text-gray-600 font-semibold'>${itemtotal(item)}</div>}
                 </div> )

               }</div>
        {  shopInfo.admin_payment.toString() === "false" &&<div className='flex space-y-3 flex-col p-3 border rounded-sm border-gray-200'>
                <div className='flex  justify-between '>
                    <span>Subtotal</span>
                    <span>${orderDetails && orderDetails["orderProducts"]&&orderDetails["costdetails"]?orderDetails["costdetails"]["Subtotal"]:""}</span>
            </div>
            <div className='flex  justify-between '>
                    <span>Shipping</span>
                    <span>${orderDetails && orderDetails["orderProducts"]&&orderDetails["costdetails"]?orderDetails["costdetails"]["shippingCost"]:""}</span>
            </div>
            </div>  }
            {shopInfo.admin_payment.toString() === "false" && <div className='flex space-y-3 flex-col p-3 border rounded-sm border-gray-200'>
            <div className='flex  justify-between items-center '>
                <span>Total(USD)</span>
                <span className='text-3xl font-semibold'>${getFullTotal(orderDetails && orderDetails["orderProducts"]&&orderDetails["costdetails"]?orderDetails["costdetails"]:null)}</span>
        </div>
        
        </div> }
        </div>
        </div>
        </div>
      
        </div>}
        </Loading>
    </AdaptableCard>
    </section>
  )
}

export default OrderInfo