import React from 'react'
import reducer from './store'
import { injectReducer } from 'store/index'
import { AdaptableCard, Container } from 'components/shared'

import OrderTable from './components/OrderTable'
import OrderTableTools from './components/OrderTableTools'



injectReducer('userOrders', reducer)

const UserOrders = () => {
    return (
        <section className="md:pt-[120px] pt-[80px]  md:pb-[20px] relative">
        <AdaptableCard className="h-full" bodyClass="h-full">
            <Container>
            <div className="lg:flex items-center justify-between mb-4">
                <h3 className="mb-4 lg:mb-0">Orders</h3>
                {/* <OrderTableTools /> */}
            </div>
            <OrderTable />
            </Container>
        </AdaptableCard>
        </section>
    )
}

export default UserOrders
