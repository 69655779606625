import React from "react";
import FooterTwo from "../components/footers/FooterTwo";
import AboutSection from "../features/about/AboutSection";
import FunFact from "../features/about/FunFact";
import TeamSection from "../features/about/TeamSection";
import ClientLogo from "../features/common/ClientLogo";
import GetStarted from "../features/common/GetStarted";
import MainHeroSection from "../features/common/MainHeroSection";

const About = () => {
  return (
    <>
      <MainHeroSection
        title="About Us"
        subTitle="A company turning ideas into beautiful things."
      />
      <AboutSection />
      <GetStarted />
      <FunFact />
      <TeamSection />
      <ClientLogo />
      <FooterTwo />
    </>
  );
};

export default About;
