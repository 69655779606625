import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'userCheckout/state',
    initialState: {
        shippingCost: 0,
       
    },
    reducers: {
        updateShippingCost: (state, action) => {
            state.shippingCost = action.payload
        },
       
        
    },
})

export const { updateShippingCost } =
    stateSlice.actions

export default stateSlice.reducer
