import React, { useState } from "react";
import CountUp from "react-countup";
import counter from "../../assets/fake-data/landingpage-four/counter";

const FunFact = () => {
  const [dataBlock] = useState({
    title: "Trust Us And Feel Free To Try Our Service",
    subTitle:
      "Due to its widespread use as filler text for layouts, non-readability is of great importance.",
  });
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subTitle}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-16 gap-[30px]">
            {counter.map((x) => {
              return (
                <div key={x.id} className="text-center">
                  <div className="h-14 w-14 leading-[56px] bg-custom/20 rounded-[5px] inline-block">
                    <img
                      src={x.image}
                      alt={x.image}
                      className="mx-auto inline-block w-7"
                    />
                  </div>
                  <div className="fun_content mt-6">
                    <h1 className="font-bold mb-0 text-[46px]">
                      <CountUp
                        start={x.startAt}
                        end={x.endAt}
                        suffix={x.suffix}
                        prefix={x.prefix}
                      />
                    </h1>
                    <p className="mt-4 text-muted">{x.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default FunFact;
