import React from 'react'

const WashingCare = () => {
  return (
    <div> <div className='py-2'></div>
    
    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4'>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Washing</div>
        <span>Machine wash cold, inside-out, gentle cycle with mild detergent & similar colors.</span>
        </div>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Bleaching</div>
        <span>Employ non-chlorine bleach solely when it is essential.</span>
        </div>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Drying</div>
        <span>Tumble dry low, or hang-dry in shade for the longest life, don’t dry clean.</span>
        </div>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Ironing</div>
        <span>Cool iron inside-out if necessary. Do not iron on decoration.</span>
        </div>
    </div>
    </div>
  )
}

export default WashingCare