import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../components/headers/Header'


const PageLayout = () => {
  return (
    <>
      <Header/>
      <Outlet/>
    </>
  )
}

export default PageLayout
