

import Loading from 'components/shared/Loading';
import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
// import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { getSelectedProductInfo } from '../store/dataSlice';
import MainHeroSection from 'features/common/MainHeroSection';
import ProductImage from './ProductImage';
import ProductDetails from './ProductDetails';
import { setActiveVariant, setShoppginCart, toggleshowShipping } from '../store/stateSlice';
import ProductShipping from './ProductShipping';
import ProductPreview from './ProductPreview';
// import { apiGetProducts, apiGetShopProductData } from 'services/ProductService';


const ProductPage = () => {
    const dispatch = useDispatch()
    const params = useParams();
    const loading = useSelector((state) => state.productSelection.data.loading)
    const token = useSelector((state) => state.auth.session.token)
    
    const data = useSelector((state) => state.productSelection.data.productInfo)
    const showShipping = useSelector((state) => state.productSelection.state.showShipping)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    const fetchData = async()=>{
      dispatch(setShoppginCart({}))
      dispatch(toggleshowShipping(false))
        dispatch(getSelectedProductInfo({id:params.id}))
        
    }
   
  return (
    <section className="pt-10 pb-[10px] bg-light">
        <Loading loading={loading}>
        {!showShipping && <section className="md:pt-[80px] pt-[80px]  md:pb-[20px] relative">
        <div className="container">
          <div className="grid grid-cols-1">
           <h2 className="font-bold"><Link to={'../'} className='text-blue-500'>Home</Link> / {data.name}</h2>
            
           
          </div>
        </div>
      </section>}
     {!showShipping && <section className="pt-10 pb-[170px] bg-light">
        <div className="container">
        <div className='flex md:flex-row flex-col justify-between'>
      <ProductImage />
      <ProductDetails />
      </div>
      </div>
      </section>}
      {showShipping && <section className="md:pt-[80px] pt-[80px]  md:pb-[20px] relative">
        <div className="container m-w-[750px] px-[40px]">
        <div className='flex md:flex-row flex-col justify-start px-[40px]'>
        <ProductShipping />
        
      </div>
      </div>
      </section>}
    </Loading>
    </section>
  )
}

export default ProductPage