import { Button, Input, Select, Tag } from "components/ui";
import React, { useState, useRef } from "react";
import { HiOutlineSearch, HiX, HiCheck } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import {
  getProducts,
  setActiveFilter,
  setFilterData,
  setTableData,
} from "../store/dataSlice";
import { cloneDeep, debounce } from "lodash";

const { Control } = components;

const options = [
  // { value: 'All', label: 'All Brands', color: 'bg-purple-500' },
  { value: "BELLA + CANVAS", label: "BELLA + CANVAS", color: "bg-amber-500" },
  { value: "Comfort Colors", label: "Comfort Colors", color: "bg-purple-500" },
  { value: "District Tee", label: "District Tee", color: "bg-emerald-500" },
  { value: "Gildan", label: "Gildan", color: "bg-red-500" },
  { value: "Next Level", label: "Next Level", color: "bg-gray-500" },
  { value: "MINERAL", label: "MINERAL", color: "bg-gray-500" }, //Port & Company
  { value: "Port & Company", label: "Port & Company", color: "bg-gray-500" }, //Port & Company
];
const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
  return (
    <div
      className={`flex items-center justify-between p-2 cursor-pointer ${
        isSelected
          ? "bg-gray-100 dark:bg-gray-500"
          : "hover:bg-gray-50 dark:hover:bg-gray-600"
      }`}
      {...innerProps}
    >
      <div className="flex items-center gap-2">
        <span>{label}</span>
      </div>
      {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
    </div>
  );
};

const CustomControl = ({ children, ...props }) => {
  const selected = props.getValue()[0];
  return <Control {...props}>{children}</Control>;
};
const ProductsTools = () => {
  const searchInput = useRef();
  const dispatch = useDispatch();
  const selectedCategory = useSelector(
    (state) => state.productlist.state.selectedCategory
  );
  const { pageIndex, pageSize, sort, query, total } = useSelector(
    (state) => state.productlist.data.tableData
  );
  const debounceFn = debounce(handleDebounceFn, 500)
  const [filterText, setfilterText] = useState();
  const filterData = useSelector((state) => state.productlist.data.filterData);
  const { brand } = useSelector((state) => state.productlist.data.filterData);
  const activeFilter = useSelector(
    (state) => state.productlist.data.activeFilter
  );
  const tableData = useSelector(
    (state) => state.productlist.data.tableData
)
  const ClearFilters = () => {
    setfilterText("");
    dispatch(setActiveFilter(null));
    const initialFilterDataIn = {
      name: "",
      category: ["Men", "Women", "Kids", "Youth", "Unisex"],
      status: [0, 1, 2],
      brand: "All",
      productStatus: 0,
    };
    dispatch(setFilterData(initialFilterDataIn));
    const newTableData = cloneDeep(tableData)
    newTableData.query = ""
    newTableData.pageIndex = 1
    
        fetchData(newTableData)
    
  };
  function handleDebounceFn(val) {
    const newTableData = cloneDeep(tableData)
    newTableData.query = val
    newTableData.pageIndex = 1
    if (typeof val === 'string' && val.length > 1) {
        fetchData(newTableData)
    }

    if (typeof val === 'string' && val.length === 0) {
        fetchData(newTableData)
    }
}

const fetchData = (data) => {
    dispatch(setTableData(data))
    console.log(data);
    dispatch(getProducts({...data,filterData:filterData}))
}

const onEdit = (e) => {
    setfilterText(e.target.value)
    debounceFn(e.target.value)
}
  const filterList = (v) => {
    // setfilterText(v)
    // const newList = filterByNameAndLastName(data,v,v)
    // setproductsIn(newList)
    // dispatch(updateProductList(newList))
  };
  
  const onStatusFilterChange = (selected) => {
    dispatch(setActiveFilter({ brand: selected.value }));

    const newFilterData = cloneDeep(filterData);
    newFilterData.brand = selected.value;
    dispatch(setFilterData(newFilterData));

    dispatch(
      getProducts({
        pageIndex,
        pageSize,
        sort,
        query,
        filterData: newFilterData,
        token: null,
        isFav: false,
      })
    );
  };
  return (
    <div className="filters ">
      <div className="flex flex-col mb-4  lg:flex-row lg:items-center gap-4">
        <Input
          ref={searchInput}
          className="lg:w-64"
          size="sm"
          value={filterText}
          prefix={<HiOutlineSearch className="text-lg" />}
          onChange={onEdit}
          placeholder="Search by brand name or style name"
          suffix={
            <HiX
              className={
                filterText
                  ? "text-lg cursor-pointer ml-1"
                  : "text-lg cursor-pointer ml-1 hidden"
              }
              onClick={() => {
                filterList("");
              }}
            />
          }
        />
        <Select
          options={options}
          size="sm"
          placeholder="Brand"
          className=" min-w-[200px]"
          onChange={onStatusFilterChange}
          components={{
            Option: CustomSelectOption,
            Control: CustomControl,
          }}
          value={options.filter((option) => option.value === brand)}
        />
        {activeFilter && activeFilter.type && (
          <Tag
            className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-0 rounded"
            suffix={<HiX className="ml-1 rtl:mr-1 cursor-pointer" />}
          >
            {activeFilter.type}
          </Tag>
        )}
        <Button size="sm" onClick={() => ClearFilters()}>
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default ProductsTools;
