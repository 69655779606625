import React from 'react'
import process1 from "assets/images/connect/process-1.svg";
import process2 from "assets/images/connect/process-2.svg";
import process3 from "assets/images/connect/process-3.svg";
import ConnectBtn from 'components/buttons/ConnectBtn';
import { HiChevronRight } from 'react-icons/hi';

const ProcessFlow = () => {
  return (
    <section className=" bg-center pb-[160px] bg-white mt-3 relative">
    <div className="container-fluid px-[30px]">
    <div className="grid grid-cols-3 gap-5">
    <div className='flex items-center justify-center'>
    <img src={process2}className={"w-[150px]"}/>
    </div>
    <div className='flex items-center justify-center'>
    <img src={process1}className={"w-[150px]"}/>
    </div>
    <div className='flex items-center justify-center'>
    <img src={process3}className={"w-[150px]"}/>
    </div>
    
    
    </div>
    
    <div className="process-flow">
<div className="slide-steps-box ">
<div className="item-step text-center">
<div className="wrap-item">
<div className="image">
</div>
<div className="number fs">
<span>1</span>
</div>
<div className="wrap-content space-y-2 flex flex-col">
<h3 className="title">Create USPF account</h3>
<span className="description mb-2">Begin your journey by setting up a USPF fulfillment store. Next, seamlessly connect your integrated selling store with USPF.</span>
<span className='py-2'>
<a href={"https://usprintfactory.com/app/sign-up"} target="_blank">
  <ConnectBtn text={"Get STarted"} icon={<HiChevronRight className='bg-white text-red-600 rounded-full' size={28} />}  />
  </a>
</span>
</div>

</div>
</div>
<div className="item-step text-center">
<div className="wrap-item">
<div className="image">
</div>
<div className="number fa fs">
<span>2</span>
</div>
<div className="wrap-content space-y-2 flex flex-col">
<h3 className="title">Design  &amp; Sell Products</h3>
<span className="description">Select products from our catalog, unleash your creativity with unique designs, and effortlessly add them to your store. Sit back and await incoming orders as your personalized products charm your customers.</span>
</div>
</div>
</div>
<div className="item-step text-center">
<div className="wrap-item">
<div className="image">
</div>
<div className="number fa">
<span>3</span>
</div>
<div className="wrap-content space-y-2 flex flex-col">
<h3 className="title">Fulfilled by US Print Factory</h3>
<span className="description">USPF handles the entire fulfillment process with utmost care. We take charge of production and shipping, ensuring your orders are delivered seamlessly to your customers.</span>
</div>
</div>
 </div>
</div>
</div>
    </div>

    
    </section>
  )
}

export default ProcessFlow