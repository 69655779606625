import React, { useState } from "react";
import feature1 from "../../assets/images/feature-1.jpeg";
import feature2 from "../../assets/images/feature-2.jpeg";
import { Link } from "react-router-dom";

const ContentSection = () => {
  const [designDataBlock] = useState({
    name: "Perfect Design",
    title: "Print on demand, no dead stock.",
    description:
      "Choose a suitable product for your shop, club, team etc. Create your custmizable product with a design like a brand logo or player name and number.",
  });

  const [userDataBlock] = useState({
    name: "Team Orders",
    title: "Get perfect fit merchandise.",
    description:
      "Invite your team to join your team account and let them choose what and how they want to be their team merchandise.",
  });
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {designDataBlock.name}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {designDataBlock.title}
              </h3>
              <p className="text-muted mt-6">{designDataBlock.description}</p>
              <div className="mt-10">
                <Link to="/products/catalogue" className="btn-custom rounded-full">
                  Explore
                </Link>
              </div>
            </div>
            <div>
              <img src={feature2} alt="com" className="mx-auto rounded-[30px]" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px] mt-16">
            <div>
              <img src={feature1} alt="com" className="mx-auto rounded-[30px]" />
            </div>
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {userDataBlock.name}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {userDataBlock.title}
              </h3>
              <p className="text-muted mt-6">{userDataBlock.description}</p>
              <div className="mt-10">
                <Link to="/products/catalogue" className="btn-custom rounded-full">
                  Explore
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentSection;
