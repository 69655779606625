import React from 'react'
import reducer from './store'
import Details from './components/Details'
import { injectReducer } from 'store/index'
import { AdaptableCard } from 'components/shared'

const CheckoutShop = () => {
    injectReducer('userCheckout', reducer)
  return (
    <AdaptableCard className="h-full" bodyClass="h-full">
    <Details />
    </AdaptableCard>
  )
}

export default CheckoutShop