import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiDeleteUserOrder, apiGetuserOrders } from 'services/ProductService'



export const getUserOrders = createAsyncThunk(
    'teamMembers/data/getProducts',
    async (data) => {
        const response = await apiGetuserOrders(data)

        return response.data
    }
)

export const deleteProduct = async (data) => {
    const response = await apiDeleteUserOrder(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: "Que",
    productStatus: 0,
}

const dataSlice = createSlice({
    name: 'userOrders/data',
    initialState: {
        loading: false,
        memberList: [],
        tableData: initialTableData,
        filterData: initialFilterData,
    },
    reducers: {
        updateTeamList: (state, action) => {
            state.memberList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
    },
    extraReducers: {
        [getUserOrders.fulfilled]: (state, action) => {
            state.memberList = action.payload.data
            state.tableData.total = action.payload.total
            state.loading = false
        },
        [getUserOrders.pending]: (state) => {
            state.loading = true
        },
    },
})

export const {
    updateTeamList,
    setTableData,
    setFilterData,
    setSortedColumn,
} = dataSlice.actions

export default dataSlice.reducer
