import React from 'react'
import { useSelector } from 'react-redux'

const DesignGuidelines = () => {
    const data = useSelector((state) => state.productDetailsInfo.data.productInfo)
  return (
    <div> <div className='py-2'></div>
    
    <div className='grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4 px-10'>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Print Placements</div>
        <span>
        <ul className='list-disc'>
                <li>Front</li>
                <li>Back</li>
                <li>Right & left pockets</li>
                <li>Neck label</li>
            </ul>
        </span>
        </div>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Max Printable Area</div>
        <ul className='list-disc'>
                <li>Front - {data.PrintAreas.front} inches</li>
                <li>Back - {data.PrintAreas.back} inches</li>
                <li>Pockets – {data.PrintAreas.pocket} inches</li>
                
            </ul>
        </div>
        <div className='flex flex-col space-y-3'>
        <div className='text-2xl font-semibold'>Design Format</div>
        <ul className='list-disc'>
                <li>File – PNG (Transperent)</li>
                <li>Resolution – 300 DPI</li>
                <li>Trim extra spaces</li>
                
            </ul>
        </div>
       
    </div>
    </div>
  )
}

export default DesignGuidelines