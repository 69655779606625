import Loading from 'components/shared/Loading';
import { Button, Card } from 'components/ui'
import _ from 'lodash';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ApiService from 'services/ApiService';
import { apiGetShopProducts } from 'services/ProductService';

const ThemeTwo = ({shopInfo}) => {
  const [loading, setloading] = useState(true);
  const navigate = useNavigate()
  const [productlist, setproductlist] = useState([])
  useEffect(() => {
    getProducts()
  
    return () => {
      
    }
  }, [])
  const getProducts = async()=>{
    const pdlist = await apiGetShopProducts({shop_id:shopInfo.shop_id})
    setproductlist(pdlist.data?.products)
    setloading(false)

  }
  const onClick = (e,id) =>{
    navigate(`/${shopInfo.shop_path}/product/${id}`)
  }
  const cardHeader = (p,userFavorites=[]) => {
      const as = _.uniqBy(p.variants, 'color')
      
      const variantsColor = _.sample(p.variants)["color"];
      let  image;
      if(p.artlayers["back"][0]){
        image = `https://uspf.s3.us-east-1.amazonaws.com/products/${p["userId"]}/${p["_id"]}_back_${variantsColor.replace(/ /g,"-")}.png`
     }
     else{
       image = `https://uspf.s3.us-east-1.amazonaws.com/products/${p["userId"]}/${p["_id"]}_front_${variantsColor.replace(/ /g,"-")}.png`
     }
      
      return(<div className="rounded-tl-lg rounded-tr-lg overflow-hidden relative bg-gray-100">
      <img src={image} alt="image" />
  </div>)
  }
  return (
    <Loading loading={loading}><section className="md:pt-[70px] md:pb-[70px] pt-[110px] pb-[70px] relative bg-cover bg-center bg-white">
      <div className="fixed  bg-cover bg-center bg-no-repeat w-full md:h-1/2 h-screen " style={{"backgroundImage":`url("${shopInfo.shop_cover_pic}")`}}>
        
      </div>
      <section className='bg-center pb-[160px] md:pt-[80px] relative'>
      <div className='w-[90%] md:w-3/4 mx-auto'>
        {shopInfo.content &&  <div className=' bg-slate-50 bg-opacity-70 p-6 rounded-lg'>
          <h2 className='h2 text-3xl font-bold'>{shopInfo.content.title}</h2>
          <p className='text-lg pt-3'>{shopInfo.content.description}</p>
        </div>}
        </div>
        <div className='container md:pt-[10px]'><div className='mt-7 py-5 relative z-1 '>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6 px-2">
            
            { productlist.map((p)=>(
              
                  <Card key={p.id} className='mb-2 shadow hover:shadow-lg bg-slate-50 rounded-md transition duration-150 ease-in-out'
                  clickable
                  headerClass="p-0"
                  header={cardHeader(p)}
                  onClick={(e) => onClick(e,p._id)}
                  bodyClass="px-4"
                  
                  >
                  <div className='flex gap-y-2 flex-col'>
                  <span className="text-emerald-600 font-semibold">
                     {p.name}
                  </span>
                  <span className=" font-extrabold">
                     
                  </span>
                  <div className='flex gap-1 flex-wrap'>{
                          _.uniqBy(p.variants, 'color').map((color) => <div key={color.color} title={`${color.color} - ${color.colorCode}`} className='w-3 h-3 border rounded-sm' style={{backgroundColor:`${color.colorCode}`}}></div>)
                      }</div>
                  <span className="font-extralight py-2">
                          {p.variants[0]["size"]} - {_.last(p.variants)["size"]}
                  </span>
                  <div className='w-full text-center mb-3'><Button variant="solid"
                                    
                                    className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]">Order Now</Button></div>
                  </div>
                  </Card>
              
              
             ))}</div>
             </div>
             
    </div>
    </section>
    </section>
    </Loading>
  )
}

export default ThemeTwo