import React from "react";
import footerlogo from '../../assets/images/logo-light.png'
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      <footer className="bg-themedark pt-[60px] pb-[30px]">
        <div className="container">
          <div className="items-center grid md:grid-cols-2 grid-cols-1 gap-[30px]">
            <div>
              <img
                src={footerlogo}
                alt={footerlogo}
                className="h-[30px] mx-auto md:ml-0"
              />
            </div>
            <div>
              <form className="relative mx-auto md:mr-0 max-w-[380px]">
                <input
                  type="text"
                  className="py-[14px] px-[15px] w-full text-sm outline-none pr-[150px] pl-5 rounded-[6px] text-white bg-white/[0.04] border border-white/[0.001] focus:!ring-transparent focus:!border-transparent"
                  placeholder="Email"
                />
                <button
                  type="submit"
                  className="absolute tracking-[1px] font-normal text-[15px] rounded-[5px] capitalize top-1 right-1 outline-none text-sm py-[10px] px-[30px] bg-custom text-white border border-custom"
                >
                  Subcribe
                </button>
              </form>
            </div>
          </div>
          <div className="bg-white/[0.07] h-[1px] w-full my-10 mt-12"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] items-center">
            <div>
              <ul className="flex md:flex-row flex-col gap-y-2.5 items-center text-center md:text-left">
                <li className="text-white hover:text-custom after:hidden duration-300 md:after:inline-block md:after:text-white md:after:mx-5 md:after:top-1 md:after:relative md:after:content-['*']">
                  <Link to="#">Demo</Link>
                </li>
                <li className="text-white hover:text-custom after:hidden duration-300 md:after:inline-block md:after:text-white md:after:mx-5 md:after:top-1 md:after:relative md:after:content-['*']">
                  <Link to="#">Pages</Link>
                </li>
                <li className="text-white hover:text-custom duration-300">
                  <Link to="#">Support</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="flex items-center space-x-3 md:justify-end justify-center">
                <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-reddit"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-4">
            <div className="col-lg-12">
              <div className="text-center mt-4 bg-white/[0.03] py-3.5 px-3 rounded">
                <p className="text-white">
                  &copy; {`${new Date().getFullYear()} Landik. Design and coded by ThemesBoss.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
