import client1 from '../../assets/images/clients/1.png'
import client2 from '../../assets/images/clients/2.png'
import client3 from '../../assets/images/clients/3.png'
import client4 from '../../assets/images/clients/4.png'
import client5 from '../../assets/images/clients/5.png'
import client6 from '../../assets/images/clients/6.png'
import client7 from '../../assets/images/clients/7.png'
import client8 from '../../assets/images/clients/8.png'

const allImageOfClient = [
    {
        id:1,
        image:client1
    },
    {
        id:2,
        image:client2
    },
    {
        id:3,
        image:client3
    },
    {
        id:4,
        image:client4
    },
    {
        id:5,
        image:client5
    },
    {
        id:6,
        image:client6
    },
    {
        id:7,
        image:client7
    },
    {
        id:8,
        image:client8
    },
]

export default allImageOfClient