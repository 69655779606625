const pricingplanPerMonth = [
    {
        id: 1,
        name:"Fulfillment Plan",
        starArray: [1],
        description:"Pay only for the products you buy or sell after an order is made.",
        price:"$0",
        subText:"Per Month",
        planTable:    [
            {
                id: 0,
                text1: "Multistore Integrations",
                text2: "Order Management",
                text3: "Artwork Management",
                text4: "Mockup Generator",
                text5: "Efficient global order fulfillment",
            },
            
        ] 
    },
    {
        id: 2,
        name:"Social Promotion",
        starArray: [1, 2],
        description:"Use additional features whenever you want for an extra fee.",
        price:"$19.99",
        subText:"Per Month",
        planTable:   [
            {
                id: 0,
                text1: "Fulfillment Plan Features",
                text2: "Social Channel Integration",
                text3: "AI Generated Static Posts",
                text4: "Scheduled Posting",
                text5: "Analytics",
            },
            
        ]     
    },
    {
        id: 3,
        name:"Promotion Plus",
        starArray: [1, 2, 3],
        description:"Use additional features whenever you want for an extra fee.",
        price:"$39.99",
        subText:"Per Month", 
        planTable:       [
            {
                id: 0,
                text1: "Social Promotion Plan",
                text2: "AI Videos",
                text3: "AI Voice Over",
                text4: "Multi Language Support",
                text5: "Dedicated Customer support",
            },
            
        ]
    },
]

const pricingplanPerYear = [
    {
        id: 3,
        name:"Landik Starter",
        starArray: [1],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$199.99",
        subText:"Per Year",        
    },
    {
        id: 4,
        name:"Landik Enterprice",
        starArray: [1, 2],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$299.99",
        subText:"Per Year",        
    },
    {
        id: 5,
        name:"Landik Unlimited",
        starArray: [1, 2, 3],
        description:"Nulla metus metus vel tincidunt sed euismod nibh volutpat.",
        price:"$499.99",
        subText:"Per Year",        
    },
]

const pricingPlanPolicy = [
    {
        id:0,
        name:  "Monthly"
    },
    {
        id:1,
        name:  "Yearly"
    },
]

export {pricingplanPerMonth, pricingplanPerYear, pricingPlanPolicy}