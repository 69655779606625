import LandingHeader from 'components/headers/LandingHeader'
import Loading from 'components/shared/Loading'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'


const LandingLayput = () => {
  
  
  return (
    <>
    <Loading loading={false}>
      <LandingHeader shop={null}/>
      <Outlet/>
      </Loading>
    </>
  )
}

export default LandingLayput
