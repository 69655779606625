import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const stateSlice = createSlice({
    name: 'productSelection/state',
    initialState: {
        startDate: dayjs().subtract(3, 'month').toDate(),
        activeVariant:0,
        loading:true,
        shoppginCart:null,
        showShipping:false,
      
    },
    reducers: {
        setShoppginCart:(state, action) => {
            state.shoppginCart = action.payload
        },
        toggleshowShipping:(state, action) => {
            state.showShipping = action.payload
        },
        setActiveVariant:(state, action) => {
            state.activeVariant = action.payload
        },
    },
})

export const {setActiveVariant,toggleshowShipping ,setShoppginCart} = stateSlice.actions

export default stateSlice.reducer
