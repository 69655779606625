import React from "react";
import FooterOne from "../components/footers/FooterOne";
import Pricing from "../features/common/Pricing";
import PricingPlan from "../features/common/PricingPlan";
import RightPlan from "../features/common/RightPlan";
import MainHeroSection from "../features/common/MainHeroSection";
import FooterTwo from "components/footers/FooterTwo";

const PricingPage = () => {
  
  return (
    <>
      <MainHeroSection title="Pricing" subTitle="We offer great prices, premium products and quality service for your business."/>
      {/* <Pricing/> */}
      {/* <RightPlan/> */}
      <PricingPlan/>
      <FooterTwo/>
    </>
  );
};

export default PricingPage;
