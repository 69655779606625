import React, { useEffect } from "react";
import FooterThree from "../components/footers/FooterThree";
import ContactSection from "../features/contact/ContactSection";
import MainHeroSection from "../features/common/MainHeroSection";
import FooterTwo from "components/footers/FooterTwo";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  
    return () => {
      
    }
  }, [])
  
  return (
    <>
      <MainHeroSection title="We’re Here To Help" subTitle="We'd love to talk about how we can help you." />
      <ContactSection />
      <FooterTwo />
    </>
  );
};

export default ContactPage;
