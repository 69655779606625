import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import logoLight from '../assets/images/logo-light.png'
import Loading from "components/shared/Loading";
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import CryptoJS from "crypto-js";
// import CryptoJS from "crypto-js";
import { Button, Checkbox, FormContainer, FormItem, Input, Notification, toast } from 'components/ui'
import { PasswordInput } from 'components/shared'
import axios from "axios";
import { API_PREFIX_PATH } from "constants/route.constant";
import useAuth from "utils/hooks/useAuth";
const validationSchema = Yup.object().shape({

  name: Yup.string().required('Please enter your name'),
  email: Yup.string().email('Invalid email').required('Please enter your email'),
  password: Yup.string().required('Please enter your password').min(8, 'Password must be at least 8 characters')
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    'Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character'
  )
})
const Ragister = () => {
  const disableSubmit = false;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setloading] = useState(true)
  const [inviteToken, setinviteToken] = useState();
  const params = useParams();
  const currentShop = params.shop;
  const { signUp,signOutOnly } = useAuth()
  useEffect(() => {
    const param = searchParams.get('token');
    // console.log(searchParams.get());
    if (param) {
      signOutOnly();
      // 👇️ delete each query param
      setinviteToken(param)
      // searchParams.delete('token');
      // searchParams.delete('setmark');

      // 👇️ update state after
      setSearchParams(searchParams);
    }
    verifyToken(param)
  
    return () => {
      
    }
  }, [])
  const verifyToken = async(token) =>{
    let data = JSON.stringify({
      token:token
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API_PREFIX_PATH+'team/verify-invite?twitter=true',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      if(response.data.shop){
        
      }
      else{
        // navigate("/")
      }
      
      setloading(false)


    })
    .catch((error) => {
      console.log(error);
    });
  }
  const onSignIn = async(values,setSubmitting)=>{
    const { email,name, password } = values;
    const encryptedPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_SECRET).toString();
    const encpass =  CryptoJS.AES.encrypt(encryptedPassword, process.env.REACT_APP_SECRET_ADDON).toString();
    setSubmitting(true)
    const result = await signUp({
      token:inviteToken,
      email:email,
      name:name,
      password:encpass
    })

        if (result.status === 'failed') {
            // setMessage(result.message)
            toast(<Notification closable type="danger" duration={2000}>
             {result.message}
   </Notification>)
        }
    let data = JSON.stringify({
      token:inviteToken,
      email:email,
      name:name,
      password:encpass
    });

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: API_PREFIX_PATH+'team/register-invite?twitter=true',
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    
    // axios.request(config)
    // .then((response) => {
    //   const info = response.data.IsSuccessful;
    //   if(info){
    //     if(!info.inviteActive){
    //       toast(<Notification closable type="danger" duration={2000}>
    //         This invite has expired or a different email was used.
    // </Notification>)
    //     }

    //   }
    //   else{
        
    //     // navigate("/")
    //   }
    
      setloading(false)


    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  }
  const [dataBlock] = useState({
    title: "Join Now!",
    subTitle:
      "Join your team to visit your team store.",
  });
  return (
    <Loading loading={loading}>
      <section className="items-center flex bg-[url('../images/login_bg.jpg')] bg-cover bg-center relative py-20 min-h-screen">
        <div className="bg-[#0d0f14]/25 absolute inset-0 backdrop-blur-md"></div>
        <div className="container relative">
          <div className="grid grid-cols-1">
           
          </div>
          <div className="grid grid-cols-1 mt-16">
            <div className="max-w-[450px] mx-auto py-10 px-8 rounded-xl bg-white w-full">
              <h4 className="font-bold text-2xl mb-2 text-center capitalize">
                {dataBlock.title}
              </h4>
              <p className="text-muted text-center">{dataBlock.subTitle}</p>
              <Formik
                // Remove this initial value
                initialValues={{
                    name:'',
                    email: '',
                    password: '',
                    
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    if (!disableSubmit) {
                        onSignIn(values, setSubmitting)
                    } else {
                        setSubmitting(false)
                    }
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form className="mt-6 space-y-4">
                        <FormContainer className="mt-6 space-y-4">
                        <FormItem
                                label="Name"
                                invalid={errors.name && touched.name}
                                errorMessage={errors.name}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Your Name"
                                    component={Input}
                                />
                            </FormItem>
                            <div className="pt-1"></div>
                        <FormItem
                                label="Email"
                                invalid={errors.email && touched.email}
                                errorMessage={errors.email}
                            >
                                <Field
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    component={Input}
                                />
                                <span className="text-sm px-2 font-semibold">Use same email on which invite was sent.</span>
                            </FormItem>
                            <div className="pt-1"></div>
                            <FormItem
                                label="Password"
                                invalid={errors.password && touched.password}
                                errorMessage={errors.password}
                                className="passerror my-3"
                            >
                                <Field
                                    autoComplete="off"
                                    name="password"
                                    placeholder="Password"
                                    component={PasswordInput}
                                />
                            </FormItem>
                            <div className="mt-4 text-center py-4">
                              <Button variant="solid"  type="submit" className="btn bg-green btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]" >Join Now</Button>
                            </div>
                            <div>
                              If you already have an account with email, <a className="text-blue-600 font-semibold" href={`/${currentShop}?token=${inviteToken}`} >login here</a>.
                            </div>
                        </FormContainer>
                    </Form>
                )}
                    </Formik>
            
            </div>
           
          </div>
        </div>
      </section>
    </Loading>
  );
};

export default Ragister;
