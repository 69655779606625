import React, { useEffect } from 'react'
import reducer from './store'
import { injectReducer } from 'store/index'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'

import { Outlet,useParams,useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/shopheader/Header'
import Loading from 'components/shared/Loading'
import { useState } from 'react'
import { API_PREFIX_PATH } from 'constants/route.constant'
import axios from 'axios'
import { setShopData } from './store/stateSlice'
import { setShop } from 'store/auth/shopSlice'
injectReducer('teamShop', reducer)

const OrderInfoLayout = () => {
  
  
  
  
  
  return (
    <>
    <Loading loading={false}>
      
      <Outlet/>
      </Loading>
    </>
  )
}

export default OrderInfoLayout
