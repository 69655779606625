import React,{useState} from 'react'
import shopify from "assets/images/connect/shopify-connect.png";
import etsy from "assets/images/connect/etsy-connect.png";
import woocommerce from "assets/images/connect/woo-connect.png";
import amazon from "assets/images/connect/amazon-connect.png";
import { Link } from 'react-router-dom';
import { Button } from 'components/ui';
import { HiArrowCircleRight, HiChevronRight, HiCollection } from 'react-icons/hi';
import ConnectBtn from 'components/buttons/ConnectBtn';
const ConnectStore = ({store}) => {

    const [dataBlock] = useState({
        shopify:{ subtitle:
          "With our print-on-demand Shopify integration, fulfilling custom products becomes incredibly easy.",
        title: "Shopify Integration with USPF",
        satisfiedGuarantee: "Satisfied Guarantee",
        image:shopify,
        awosomeSupport: "Awesome Support 24/7"},
        etsy:{ subtitle:
            "With our print-on-demand Etsy integration, fulfilling custom products becomes incredibly easy.",
          title: "Etsy Integration with USPF",
          satisfiedGuarantee: "Satisfied Guarantee",
            image:etsy,

          awosomeSupport: "Awesome Support 24/7"},
          woocommerce:{ subtitle:
            "With our print-on-demand WooCommerce integration, fulfilling custom products becomes incredibly easy.",
          title: "WooCommerce Integration with USPF",
          satisfiedGuarantee: "Satisfied Guarantee",
          image:woocommerce,
          awosomeSupport: "Awesome Support 24/7"},
          amazon:{ subtitle:
            "With our print-on-demand Amazon integration, fulfilling custom products becomes incredibly easy.",
          title: "Amazon Integration with USPF",
          image:amazon,
          satisfiedGuarantee: "Satisfied Guarantee",
          awosomeSupport: "Awesome Support 24/7"}
      });
  return (
    <div className="store-connect grid grid-cols-2 px-[38px] relative z-1">
        <div className="flex !md:items-start justify-center h-full flex-col px-4 pt-[0px] md:pt-[38px] md:pl-[48px]">
          
          <h1 className="font-extrabold md:text-[40px] w-[70%] md-w-[100%] text-[28px] capitalize mb-2 relative z-10 text-uspfblue">{
          dataBlock[store]["title"]}
          </h1>
          <p className="text-base md:text-lg max-w-[600px] mt-6">
          {dataBlock[store]["subtitle"]}
            </p>
            <div className="mt-4">
            <div className="max-w-[450px] mt-[36px] text-center w-full md:text-right ">
            
            <Link to={`https://usprintfactory.com/app/dashboard/connect/${store}`} target="_blank">
              <ConnectBtn text="CONNECT" icon={<HiChevronRight className='bg-white text-red-600 rounded-full' size={28} />} />
              </Link>
            </div>
            </div>
        </div>
        <div className='flex items-center justify-center'><img src={dataBlock[store]["image"]} className={"w-[500px]"}/></div>
       </div>
      
  )
}

export default ConnectStore