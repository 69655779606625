import React,{useState} from 'react'
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button,Notification, toast } from 'components/ui';
import Loading from 'components/shared/Loading';

const CheckoutForm = ({amount,callback}) => {
    const stripe = useStripe();
    const elements = useElements();
  const [processing, setprocessing] = useState(false)
  const toastNotification = (
    <Notification title="Mesasge">
        The fat cat sat on the mat bat away with paws annoy owner.
    </Notification>
)
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setprocessing(true)
    // setprocessingCall(true)
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect:"if_required",
      confirmParams: {
        return_url: "http://localhost:3000/creator/hashmine",
      },
    });

    if (result.error) {
        console.log(result.error?.message);
        setprocessing(false)
        // 
        

        toast.push(
            <Notification
                title={"Payment failed"}
                type={"danger"}
            >
                {result.error?.message}
            </Notification>
        )

    }
    else{
        
        setprocessing(false)
        callback(result.paymentIntent);

    }
    }
    const payoptions = {
        fields: {
            billingDetails: {
              name: 'auto',
              email: 'auto',
              country:'never'
            }
          }
    }
  return (
    <div className='w-75 mx-auto relative'>
        <div className='w-full p-3 font-semibold '>{`Make payment of $${(amount)} to confirm this order.`}</div>
    {processing && <div className="h-full w-full flex flex-col items-center justify-center absolute bg-opacity-50 bg-gray-100 z-20">
                <Loading className="h-[100px]" loading={processing} />
                <span className='p-3'>Please wait while we process your payment...</span>
              </div>}
              <div className='w-full py-3'></div>
    <form onSubmit={handleSubmit} className='flex flex-col '>
      <PaymentElement options={payoptions} />
      
      <span className='p-1 mt-4 text-right'>
        <Button size='large'  disabled={!stripe} htmlType="submit" variant="solid"  className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]" >Pay Now</Button></span>
    </form>
    </div>
  )
}

export default CheckoutForm