const appConfig = {
    apiPrefix:  process.env.REACT_APP_API,
    authenticatedEntryPath: '/dashboard/default',
    unAuthenticatedEntryPath: '/sign-in',
    appHome: '/',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
