import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


import { Artworks, apiGetProducts } from 'services/ProductService'

export const getProducts = createAsyncThunk(
    'productlist/data/getProducts',
    async (data) => {
        const response = await apiGetProducts(data)
        
        return response.data
    }
)
export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 30,
    query: '',
    sort: {
        order: '',
        key: '',
    },
}

export const initialFilterData = {
    name: '',
    category:[ "Men","Women","Kids","Youth","Unisex"],
    status: [0, 1, 2],
    brand:"All",
    productStatus: 0,
}

const dataSlice = createSlice({
    name: 'productlist/data',
    initialState: {
        loading: false,
        productList: [],
        userFavorites:[],
        tableData: initialTableData,
        filterData: initialFilterData,
        activeFilter:null
    },
    reducers: {
        updateProductList: (state, action) => {
            state.productList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setuserFavoritesData: (state, action) => {
            state.userFavorites = action.payload
        },
        setActiveFilter:(state,action) =>{
            state.activeFilter = action.payload
        }
    },
    extraReducers: {
        [getProducts.fulfilled]: (state, action) => {
            state.productList = action.payload.data
            state.tableData.total = action.payload.total
            state.userFavorites = action.payload.userFavorites
            state.loading = false
        },
        [getProducts.pending]: (state) => {
            state.loading = true
        },
    },
})

export const {
    updateProductList,
    setTableData,
    setFilterData,
    setuserFavoritesData,
    setSortedColumn,
    setActiveFilter
} = dataSlice.actions

export default dataSlice.reducer
