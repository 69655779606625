import React, { useMemo, Fragment } from 'react'
import { AdaptableCard } from 'components/shared'
import { Table, Avatar } from 'components/ui'
import { useTable } from 'react-table'
import NumberFormat from 'react-number-format'
import isLastChild from 'utils/isLastChild'

const { Tr, Th, Td, THead, TBody } = Table

const ProductColumn = ({ row ,index,variants}) => {
    const pname = (row.name).split(" / ");
    // console.log(pname.split(" / "));
    
    if(row.title){
        return (
            <div className="flex">
                <Avatar size={60} src={row.image} />
                <div className="ltr:ml-2 rtl:mr-2">
                    <h6 className="mb-2">{pname[0]}</h6>
                    <div className="mb-1" >
                            <span className="capitalize">Color: </span>
                            <span className="font-semibold">{pname[1]}</span>
                        </div>
                        <div className="mb-1" >
                            <span className="capitalize">Size: </span>
                            <span className="font-semibold">{pname[2]}</span>
                        </div>
                   
                </div>
            </div>
        )
    }
    else{
        return (
            <div className="flex">
                <Avatar size={60} src={variants[index]["image"]} />
                <div className="ltr:ml-2 rtl:mr-2">
                    <h6 className="mb-2">{pname[0]}</h6>
                    <div className="mb-1" >
                            <span className="capitalize">Color: </span>
                            <span className="font-semibold">{pname[1]}</span>
                        </div>
                        <div className="mb-1" >
                            <span className="capitalize">Size: </span>
                            <span className="font-semibold">{pname[2]}</span>
                        </div>
                   
                </div>
            </div>
        )
    }
   
}

const PriceAmount = ({ amount }) => {
    return (
        <NumberFormat
            displayType="text"
            value={(Math.round(amount * 100) / 100).toFixed(2)}
            prefix={'$'}
            thousandSeparator={true}
        />
    )
}

const OrderProducts = ({ data,variants }) => {
    
    const columns = useMemo(
        () => [
            {
                Header: 'Product',
                accessor: 'name',
                Cell: (props) => {
                    const row = props.row.original
                    return <ProductColumn row={row} index={props.row.index} variants={variants} />
                },
            },
           
            {
                Header: 'Quantity',
                accessor: 'qty',
            },
            
        ],
        []
    )

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data })

    return (
        <AdaptableCard className="mb-4">
            <Table {...getTableProps()}>
                <THead className="!bg-transparent">
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </THead>
                <TBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </TBody>
            </Table>
        </AdaptableCard>
    )
}

export default OrderProducts
