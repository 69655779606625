import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import MainLayoutOuter from "MainLayout";

const App = () =>  {
  
  return <>
  <Provider store={store} >
  <PersistGate loading={null} persistor={persistor}>
      <MainLayoutOuter />
      </PersistGate>
      </Provider>
  </>
}

export default App;
