import React from 'react'
import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';
import { Description } from './Description';
import WashingCare from './WashingCare';
import DesignGuidelines from './DesignGuidelines';
import CustomBranding from './CustomBranding';
export const InformationTabs = () => {
    const productInfo = [
        { name: 'Description', info: <Description /> },
        { name: 'Design Guidelines', info: <DesignGuidelines /> },
        { name: 'Custom Branding', info: <CustomBranding /> },
        { name: 'Wash Care', info: <WashingCare /> },
      ];
      const getTabs=() =>{
        return productInfo.map((prod, index) => ({
          title: prod.name,
          getContent: () => prod.info,
          /* Optional parameters */
          key: index,
          tabClassName: 'tab',
          panelClassName: 'panel',
        }));
      }
  return (
    <>
    <Tabs items={getTabs()} transformWidth={800} showInkBar={true}  tabClassName="bg-uspfredlow" />
    <div className='bg-uspfredlow'></div>
    </>
  )
}
