import React from 'react'
import products from "../assets/json/products.json"
import { Container } from 'components/shared';
import { Card, Avatar, Button,Dialog, Input } from 'components/ui'
import MainHeroSection from "../features/common/MainHeroSection";
import { useState } from 'react';
import axios from 'axios';
import Spinner from 'components/ui/Spinner';
import ProductColorList from './ProductColorList';
import _ from 'lodash';
import { HiX } from 'react-icons/hi';
import Tooltip from 'components/ui/Tooltip';
import { useEffect } from 'react';
import { apiGetUSPFProducts } from 'services/ProductService';

const ProductsCatalogue = () => {
 
  products.sort((a, b) => {
    const nameA = a.BrandName.toLowerCase();
    const nameB = b.BrandName.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const [producstData, setproducstData] = useState([])
  const [filterText, setfilterText] = useState()
  useEffect(() => {
    getUSPFLIst()
  
    return () => {
      
    }
  }, [])
  const getUSPFLIst = async()=>{
    const list = await apiGetUSPFProducts({query:""})
    setproducstData(list["data"]["data"]);
  }
  const filterByNameAndLastName = (array, nameTerm, lastNameTerm) =>{
    
    return array.filter(item => {
        const name = item.BrandName.toLowerCase();
        const lastName = item.StyleName.toString().toLowerCase();
        const searchName = nameTerm.toLowerCase();
        const searchLastName = lastNameTerm.toLowerCase();
        return name.includes(searchName) || lastName.includes(searchLastName);
    });
}
// console.log(filterByNameAndLastName(producstData,"bella","1566"));
const filterList =(v)=>{
    setfilterText(v)
    const newList = filterByNameAndLastName(products,v,v)
    setproducstData(newList)
}
  const [showDialog, setshowDialog] = useState(false)
    
  const [modalParams, setmodalParams] = useState({
    
      showmodal: false,
      selectedId:0,
      title:"",
      StyleName:"",
      selectedImg:"",
      loading:false,
      getPrice:false,
      productData:[],
      productSanmarData:[],
      formSubmited:false,name:"",email:"",phone:"",invalidEmail:true,invalidName:true,
      capchaDone:false,
      showToast:false,
      mailError:false,selectedItem:null
      
    })
    const openDialog = () => {
      setshowDialog(true)
  }

  const onDialogClose = (e) => {
      
      setshowDialog(false)
  }

    const onDialogOk = (e) => {
        
        setshowDialog(false)
    }


    const showModalView = (id,title,img,StyleName,item) =>{
      
        if(item.inventory  && !item.isSanmar){
          setmodalParams({...modalParams,loading:false,StyleName:StyleName,getPrice:false,mailError:false,capchaDone:false,selectedId:id,showmodal:true,title:title,selectedImg:img,selectedItem:item})
          openDialog()
        }
        else{
          setmodalParams({...modalParams,loading:true,StyleName:StyleName,getPrice:false,mailError:false,capchaDone:false,selectedId:id,showmodal:true,title:title,selectedImg:img,selectedItem:item})
          openDialog()
          
          getList(id,item.isSanmar?item.isSanmar:false,item.StyleName,{...modalParams,loading:true,StyleName:StyleName,getPrice:false,mailError:false,capchaDone:false,selectedId:id,showmodal:true,title:title,selectedImg:img,selectedItem:item})
    
        }
      }
      const renderSizes = ()=>{
        
        var grouped = _.mapValues(_.groupBy(modalParams.productData, 'sizeName'),
                              clist => clist.map(car => _.omit(car, 'sizeName')));
        const keysArray = Object.keys(grouped);
    
        // Get the first and last keys
        const firstKey = keysArray[0];
        const lastKey = keysArray[keysArray.length - 1];
        return firstKey + " - "+lastKey;
      }
    const  getList = async(id,isSanmar,StyleName,dd)=>{
        let data = JSON.stringify({
            "id": id,
            isSanmar:isSanmar,
            StyleName:StyleName
          });
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://localhost:3011/api/1.0/extra/products?twitter=true',
            url: 'https://usprintfactory.com/api/1.0/extra/products?twitter=true',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
            
            setmodalParams({...dd,productData:response.data.list,productSanmarData:response.data.sanmarList,loading:false})
          
           
      
      
          })
          .catch((error) => {
            console.log(error);
          });
    }

  return (
    <>
    <section className="md:pt-[120px] pt-[120px] pb-10 md:pb-[10px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <h1 className="font-bold text-[36px]">Products Catalogue</h1>
            
           
          </div>
         <div className='py-3 md:w-1/2'> <Input onChange={(v)=>filterList(v.target.value)} value={filterText} placeholder="Search by brand name or style name"  suffix={
                        
                            <HiX className={filterText?"text-lg cursor-pointer ml-1":"text-lg cursor-pointer ml-1 hidden"} onClick={()=>{filterList("")}} />
                        
                    } /></div>
        </div>
      </section>
    <Container fluid className="px-1">
         <div className="grid md:grid-cols-4 gap-4">
       
        {
            producstData.map((p,i ) => <div className="max-w-xs bg-slate-100 shadow" key={i}>
            <Card
                clickable
                className="transition duration-150 ease-in-out dark:border dark:border-gray-600 dark:border-solid"
                header={<div className="rounded-tl-lg text-center rounded-tr-lg overflow-hidden max-h-[250px]">
                <img className='h-full mx-auto ' src={p.inventory?p.StyleImage:`https://www.ssactivewear.com/${(p.StyleImage).replace("_fm","_fs")}`} alt="image" />
            </div>}
                footer={<div className="flex items-center justify-center p-2">
                <button onClick={()=>showModalView(p.StyleID,`${p.BrandName} - ${p.Title} (${p.StyleName})`,p.StyleImage,p.StyleName,p)} className="mt-3 btn btn-sm btn-custom navbar-btn btn-rounded ">Check Inventory</button>
            </div>}
                headerClass="p-0"
                footerBorder={false}
                headerBorder={false}
            >
                <span className="text-emerald-600 font-semibold">
                
                </span>
                <h4 className="font-bold my-3 px-2">{`${p.BrandName}`}</h4>
                <p className='text-sm px-2 min-h-[2.5rem]'>
                {`${p.Title} (${p.StyleName})`}
                
                </p>
            </Card>
        </div>
          )
          }
      </div>
          
          </Container>
          
          <Dialog
                isOpen={showDialog}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
                width={1200}
                
                bodyOpenClassName="overflow-hidden"
                closable={false}
            >
                <div className="flex flex-col h-full justify-between">
                    <h5 className="mb-4">{modalParams.title}</h5>
                    {
                        modalParams.loading && <div className='flex items-center justify-center h-screen'><Spinner /></div>
                    }
                    {
                        !modalParams.loading && <div className="flex flex-row space-x-4 max-h-96 overflow-y-auto">
                            <div className="rounded-tl-lg rounded-tr-lg overflow-hidden " style={{width:"25%"}}>
                <img className='card-img' src={modalParams.selectedItem && modalParams.selectedItem.inventory?modalParams.selectedItem.StyleImage:`https://www.ssactivewear.com/${modalParams.selectedImg}`} alt="image" />
            </div>

            <div className="flex flex-col p-3 w-75 ">
            <span className="h5">Sizes: {modalParams.selectedItem && modalParams.selectedItem.inventory?modalParams.selectedItem.sizeTitle:renderSizes()}</span>

            
              <ProductColorList data={modalParams.productData} productSanmarData={modalParams.productSanmarData} item={modalParams.selectedItem} />
            
          </div>        
          </div>
                    }
                    {/* <p>
                        {modalParams.loading && <Spinner /> }
                    </p> */}
                    <div className="text-right mt-6">
                    {/* <Button
                    className="mr-2 mb-2 btn btn-sm btn-custom navbar-btn btn-rounded"
                    variant="twoTone"
                    color="blue-600"
                >
                    Get Price
                </Button> */}
                        <Button className="mt-3 btn btn-sm btn-custom navbar-btn btn-rounded" variant="plain" onClick={onDialogOk}>
                          Close
                        </Button>
                    </div>
                </div>
            </Dialog> 
          </>
  )
}

export default ProductsCatalogue

{/* <Col key={i}>
<Card>
<CardImg variant="top" src={p.inventory?p.StyleImage:`https://www.ssactivewear.com/${(p.StyleImage).replace("_fm","_fs")}`} />
<CardBody className="p-2">
<CardTitle className="d-flex flex-column">
<span>{`${p.BrandName}`}</span>
<span className="h7"><small>{`${p.Title} (${p.StyleName})`}</small></span>
</CardTitle>

<Button variant="primary" onClick={()=>this.showModalView(p.StyleID,`${p.BrandName} - ${p.Title} (${p.StyleName})`,p.StyleImage,p.StyleName,p)}>Check Inventory</Button>
</CardBody>
</Card></Col> */}