import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const stateSlice = createSlice({
    name: 'teamShop/state',
    initialState: {
        startDate: dayjs().subtract(3, 'month').toDate(),
        endDate: new Date(),
        showShopSetup:false,
        shopData:null,
        activeStep:0,
        selectedTheme:null
    },
    reducers: {
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload
        },
        toggleShopSetup: (state, action) => {
            state.showShopSetup = action.payload
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload
        },
        setShopData: (state, action) => {
            state.shopData = action.payload
        },
        setSelectedTheme:(state, action) => {
            state.selectedTheme = action.payload
        },
    },
})

export const { setStartDate, setShopData,setEndDate,toggleShopSetup,setActiveStep,setSelectedTheme } = stateSlice.actions

export default stateSlice.reducer
