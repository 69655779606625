import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import shop from './shopSlice'

const reducer = combineReducers({
    session,
    user,
    shop
})

export default reducer
