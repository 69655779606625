import FooterTwo from 'components/footers/FooterTwo'
import React from 'react'
import ConnectStore from './components/ConnectStore'
import WhatIs from './components/WhatIs'
import ProcessFlow from './components/ProcessFlow'
import HomeDisplay from 'features/home/HomeDisplay'
import ServiceDisplay from 'features/home/ServiceDisplay'

const FullfillmentService = () => {
  return (
    <>
    <section className="md:pt-[80px] md:pb-[100px] pt-[80px] pb-[100px] relative bg-cover bg-center bg-white">
    <div className="absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full bg-[url('../images/home_sec_layer-2.png')]"></div>
        <ServiceDisplay />
        
    </section>
    <ProcessFlow />
    <WhatIs />
    <FooterTwo />
    </>
  )
}

export default FullfillmentService