import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select'

import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash';
import { HiCheck, HiOutlineMail, HiOutlineMap, HiOutlineUserCircle ,HiOutlinePhone} from 'react-icons/hi';
import { Button, Dialog, FormContainer, FormItem, Input, Notification, Select, toast } from 'components/ui';
import axios from 'axios'
import { API_PREFIX_PATH, APP_PREFIX_PATH } from 'constants/route.constant'
import PaymentDialog from './PaymentDialog';
import { updateShippingCost } from '../store/stateSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ClearCart } from 'store/cart/actions';
import { apiCreateOrder } from 'services/ProductService';
const { Control } = components
const validationSchema = Yup.object().shape({
  name: Yup.string()
      .min(3, 'Too Short!')
      .max(120, 'Too Long!')
      .required('Name Required'),
  email: Yup.string().email('Invalid email').required('Email Required'),
  // title: Yup.string(),
  phone: Yup.string(),
  // avatar: Yup.string(),
  address: Yup.string().required('Address  is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required'),
  country: Yup.string().required('Country is required'),
})
const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
    return (
        <div
            className={`flex items-center justify-between p-2 ${
                isSelected
                    ? 'bg-gray-100 dark:bg-gray-500'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-600'
            }`}
            {...innerProps}
        >
            <div className="flex items-center">
               
                <span className="ml-2 rtl:mr-2">{label}</span>
            </div>
            {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
        </div>
    )
  }
  
  const CustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0]
    return (
        <Control {...props}>
           
            {children}
        </Control>
    )
  }
const Details = () => {
  const [countries, setcountries] = useState([])
  const [states, setstates] = useState([])
  const [options, setOptions] = useState(countries)
  const [statesList, setstatesList] = useState(states)
  const [loading, setloading] = useState(false)
  const [dialogIsOpen, setIsOpen] = useState(false)
  const [AddressInfo, setAddressInfo] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const params = useParams();
  const shopInfo = useSelector((state) => state.auth.shop)
    const [addingOrder, setaddingOrder] = useState(false)
  
    const [formData, setformData] = useState({name:"",email:"",phone:"",address:"",address1:"",city:"",state:"",zip:"",country:"",country_code:""})
  const carts = useSelector((state) => state.cart.todoProduct);
  const shippingcost = useSelector((state) => state.userCheckout.state.shippingCost)
  useEffect(() => {
        
    getCountries()
    // setformData(shippingAddress)
    getGeoInfo()
    return () => {
      
    }
  }, [])
  const openDialog = () => {
    setIsOpen(true)
}
useEffect(() => {
        
    
    
    // setformData(shippingAddress)
   
    return () => {
      
    }
  }, [shippingcost])
const getGeoInfo = async()=>{
   await axios.get('https://ipapi.co/json/').then( async (response) => {
        let data = response.data;
        let shcost = 4.99;
        let additionalcost = 1.99;
        if(data.country_code !=="US"){
            shcost = 14.99;
            additionalcost = 4.99;
        }
        const shippingcostin = (shcost+((carts.numberCart-1)*additionalcost)).toFixed(2)
        
        dispatch(updateShippingCost(shippingcostin))
        setformData({...formData,country:data.country_name,country_code:data.country_code,state:data.region})
        setstatesList([])
            const responsest = await axios.post(`${API_PREFIX_PATH}/utils/states?twitter=true`, {country:data.country_name}, {
            headers: {
                'Content-Type': 'application/json', // Important for file uploads
                // 'Authorization': `Bearer ${token}`
            },
            });
            if(responsest.data["IsSuccessful"]){
                
                setstatesList(responsest.data["statesList"])
            }
        // this.setState({
        //     countryName: data.country_name,
        //     countryCode: data.country_calling_code
        // });
    }).catch((error) => {
        console.log(error);
    });
}
const onDialogClose = (e) => {
    
    setIsOpen(false)
    setaddingOrder(false);

}

const onDialogOk = (e) => {
    
    setIsOpen(false)
}
    const getFullTotal = ()=>{
        let subtotal = 0;
        carts.Carts.map((item,key) => {
            subtotal = subtotal+(item.price * item.quantity)
        })
        
        return (parseFloat(subtotal)+parseFloat(shippingcost)).toFixed(2);
    }
    const getCostTotal = ()=>{
        let subtotal = 0;
        carts.Carts.map((item,key) => {
            subtotal = subtotal+(parseFloat(item.colorCost) * item.quantity)
        })
        // subtotal = subtotal+shippingcost;
        return subtotal.toFixed(2);
    }
    const getSubTotal = ()=>{
        let subtotal = 0;
        carts.Carts.map((item,key) => {
            subtotal = subtotal+(item.price * item.quantity)
        })
        // subtotal = subtotal+shippingcost;
        return subtotal.toFixed(2);
    }
    const getCountries = async()=>{
        const list = await axios.post(`${API_PREFIX_PATH}/utils/countries?twitter=true`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Important for file uploads
            // 'Authorization': `Bearer ${token}`
        },
        });
        if(list.data["IsSuccessful"]){
            const newlist = _.map(list.data.list, (item) =>  ({...item,value: item.name, label: item.name}) )
            setcountries(newlist)
            if(formData.country){
                setloading(true)
        
        setstatesList([])
            const response = await axios.post(`${API_PREFIX_PATH}/utils/states?twitter=true`, {country:formData.country}, {
            headers: {
                'Content-Type': 'application/json', // Important for file uploads
                // 'Authorization': `Bearer ${token}`
            },
            });
            if(response.data["IsSuccessful"]){
                
                setstatesList(response.data["statesList"])
            }
            setloading(false)
            }
        }
  
    }
    const changeCountry = async(option,form,field)=>{
        let shcost = 4.99;
        let additionalcost = 1.99;
        if(option.code2 !=="US"){
            shcost = 14.99;
            additionalcost = 4.99;
        }
        const shippingcostin = (shcost+((carts.numberCart-1)*additionalcost)).toFixed(2)
        
        dispatch(updateShippingCost(shippingcostin))
        setloading(true)
        setformData({...formData,country:option.name,country_code:option.code2})
        setstatesList([])
            const response = await axios.post(`${API_PREFIX_PATH}/utils/states?twitter=true`, {country:option.name}, {
            headers: {
                'Content-Type': 'application/json', // Important for file uploads
                // 'Authorization': `Bearer ${token}`
            },
            });
            if(response.data["IsSuccessful"]){
                
                setstatesList(response.data["statesList"])
               
            }
            setloading(false)
            form.setFieldValue(
                "state",
                ""
            )
        form.setFieldValue(
            field,
            option.name
        )
    }
    const onFormSubmit = (v,setSubmitting)=>{
        setAddressInfo(v);
        setaddingOrder(true);
        console.log(shopInfo.admin_payment.toString());
        if(shopInfo.admin_payment.toString() === "false"){
            openDialog()
        }
        else{
            createOrder(null,v)
        }
        
    }
    const createOrder = async(info,address=null)=>{
        
        onDialogClose();
        // setaddingOrder(true);
        let lineItems = [];
        let totalQty = 0;
        let name ="";
        try {
             name = carts.Carts[0].sku.split("DTG-A1-")[1].split("(")[0]
        } catch (error) {
            
        }
        
        
        carts.Carts.map(item =>{
        
            // const keys = item["variant1_id"]+"_"+item["variant2_id"]
            // const varInfo = _.filter(data["variants"], d => d["variant1_id"] === item["variant1_id"] && d["variant2_id"] === item["variant2_id"])
            
            const color= item["color"]
            const size= item["size"]
           const qty = item["quantity"]
           totalQty = totalQty + qty
            let line = {};
                line["sku"] = item["sku"];
                line["name"] = `${item["name"]} - ${name} / ${color} / ${size}`;
                line["psku"] = item["psku"];
                line["qty"] = qty;
                line["price"] = item["colorCost"];
                line["salecost"] = item["price"];
                line["grams"] = 5;
                line["vendor_product_id"] = item["vendor_product_id"];
                line["variant1_id"] = item["variant1_id"];
                line["variant2_id"] = item["variant2_id"];
                line["variant_title"] =  `${name} / ${color} / ${size}`;
                line["title"] =  `${item["name"]} - ${name}`
                line["image"] = item["image"]?item["image"]:item["image_back"];
                line["color"] = color;
                line["frontBackPrint"] = item["image"] && item["image_back"]?true:false
                line["size"] = size;
                line["rowImage"] = item["image"]?item["image"]:item["image_back"];
                lineItems.push(line)
        })
        const costdetails={
            "cost":getCostTotal(),
            "shippingCost":shippingcost,
            "Subtotal":getSubTotal(),
        }
        const shopdata = {
            shop_name:shopInfo["shop_name"],
            link:shopInfo["shop_url"],
            type:shopInfo["type"],
            id:shopInfo["_id"],
            userId:shopInfo["userId"]
        }
       
    
        const orderData = {state:"Que",shippingInfo:AddressInfo?AddressInfo:address,paymentInfo:info,line_items:lineItems,shopdata:shopdata,amount:costdetails.cost,costdetails:costdetails}
    
            const orders = await apiCreateOrder(orderData)
            
            if(orders["data"]["IsSuccessful"]){
                toast(<Notification closable type="success" duration={2000}>
                    Your order has been sent.
                 </Notification>)
        
                dispatch(ClearCart({}))
                setaddingOrder(false);
                
                navigate(`/${params.shop}/order/${orders["data"]["data"]["orderid"]}`)
            }
            else{
                toast(<Notification closable type="danger" duration={2000}>
                {orderData["ErrorMessage"]?orderData["ErrorMessage"]:" Something went wrong. Try again."}
             </Notification>)
                
            }
    }
  return (
    <section className="pt-[70px] pb-[10px] bg-light">
    <section className="pt-10 pb-[170px] bg-light">
        <div className="container">
    <div className='flex justify-between '>
        <div className='flex flex-1 flex-col space-y-2'>
        <Formik
        className="w-full"
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                setTimeout(() => {
                    onFormSubmit(values, setSubmitting)
                }, 1000)
            }}
        >
            {({ values, touched, errors, isSubmitting, resetForm,setFieldValue }) => {
                const validatorProps = { touched, errors }
                return (
                    <Form>
                        <FormContainer>
        <div className='flex flex-col space-y-2'>
        <div className='font-bold text-2xl'>Contact</div>
        <div className='px-8'> <div className="md:grid grid-cols-1 gap-1"><FormItem
                                name="email"
                                label="Email"
                                {...validatorProps}
                                invalid={
                                    errors.email &&
                                    touched.email
                                }
                                errorMessage={errors.email}
                            >
                                <Field
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMail className="text-xl" />
                                    }
                                />
                            </FormItem>
                            
                            <FormItem
                                name="phone"
                                label="Phone"
                                {...validatorProps}
                                
                            >
                                <Field
                                    type="phone"
                                    autoComplete="off"
                                    name="phone"
                                    placeholder="Phone"
                                    component={Input}
                                    prefix={
                                        <HiOutlinePhone className="text-xl" />
                                    }
                                />
                            </FormItem>
                            </div>
                            </div>
        </div>
        <div className='flex flex-col space-y-2'>
        <div className='font-bold text-2xl'>Delivery</div>
        <div className='px-8'>
        <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-1 gap-24">
                                     <FormItem
                                name="country"
                                label={"Country"}
                                {...validatorProps}
                                invalid={
                                    errors.country &&
                                    touched.country
                                }
                                errorMessage={errors.country}
                            >
                                <Field name="country">
                                    {({ field, form }) => (
                                        <Select  
                                        cacheOptions
                                            field={field}
                                            form={form}
                                            options={countries}
                                           
                                            
                                            components={{
                                                Option: CustomSelectOption,
                                                Control: CustomControl,
                                            }}
                                            // component={AsyncSelect}
                                            value={countries.filter(
                                                (option) =>
                                                    option.name ===
                                                    values?.country
                                            )}
                                            // value={ options[0]}
                                            onChange={(option) => changeCountry(option,form,field.name)
                                                
                                            }
                                            // onInputChange={handleInputChange}
                                        />
                                    )}
                                </Field>
                            </FormItem>
                           </div>
                           <div className="md:grid grid-cols-1 gap-24">
                            <FormItem
                                name="name"
                                label="Name"
                                {...validatorProps}
                                invalid={
                                    errors.name &&
                                    touched.name
                                }
                                errorMessage={errors.name}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Name"
                                    component={Input}
                                    prefix={
                                        <HiOutlineUserCircle className="text-xl" />
                                    }
                                />
                            </FormItem>
                           </div>
                           <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-1 ">
                                   
                                    <FormItem
                                name="address"
                                label="Address"
                                invalid={
                                    errors.address &&
                                    touched.address
                                }
                                errorMessage={errors.address}
                                
                            >
                                <Field
                                    type="address"
                                    autoComplete="off"
                                    name="address"
                                    placeholder="Address"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                           
                           
                                       
                                           
                                        
                                    </div>
                                </div>
                                <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-1 gap-24">
                               
                                    <FormItem
                                name="address1"
                                label="Apartment, suite, etc. (optional)"
                                {...validatorProps}
                                
                            >
                                <Field
                                    type="address1"
                                    autoComplete="off"
                                    name="address1"
                                    placeholder="Apartment, suite, etc. (optional)"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                           
                           
                                       
                                           
                                        
                                    </div>
                                </div>
                                <div className='py-0 pr-8'>
                                    
                                    <div className="md:grid grid-cols-3 gap-6">
                                    <FormItem
                                name="city"
                                label="City"
                                {...validatorProps}
                                invalid={
                                    errors.city &&
                                    touched.city
                                }
                                errorMessage={errors.city}
                            >
                                <Field
                                    type="city"
                                    autoComplete="off"
                                    name="city"
                                    placeholder="City"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                            <FormItem
                                name="state"
                                label={"State"}
                                {...validatorProps}
                                invalid={
                                    errors.state &&
                                    touched.state
                                }
                                errorMessage={errors.state}
                            >
                                <Field name="state">
                                    {({ field, form }) => (
                                        <Select  
                                        
                                            field={field}
                                            form={form}
                                            options={statesList}
                                            isLoading={loading}
                                            
                                            components={{
                                                Option: CustomSelectOption,
                                                Control: CustomControl,
                                            }}
                                            
                                            value={statesList.filter(
                                                (option) =>
                                                    option.name ===
                                                    values?.state
                                            )}
                                            
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                            
                                        />
                                    )}
                                </Field>
                            </FormItem>
                            <FormItem
                                name="zip"
                                label="Zip Code"
                                {...validatorProps}
                                invalid={
                                    errors.zip &&
                                    touched.zip
                                }
                                errorMessage={errors.zip}
                            >
                                <Field
                                    type="zip"
                                    autoComplete="off"
                                    name="zip"
                                    placeholder="Zip Code"
                                    component={Input}
                                    prefix={
                                        <HiOutlineMap className="text-xl" />
                                    }
                                />
                            </FormItem>
                                        </div>
                                        </div>
                                        <div className='py-1'></div>
                            
                           
                            <div className="mt-4 ltr:text-center text-center">
                                
                               {shopInfo.admin_payment.toString() === "false"? <Button
                                    variant="solid"
                                    loading={addingOrder}
                                    type="submit"
                                    className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
                                >
                                    {addingOrder ? 'Updating' : 'Continue to Payment'}
                                </Button>:
                                 <Button
                                 variant="twoTone"
                                 loading={addingOrder}
                                 type="submit"
                                 className="btn btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]"
                             >
                                 {addingOrder ? 'Updating' : 'Confirm Order'}
                             </Button>}
                            </div>
                           </div>
        </div>
        </div>
        </FormContainer>
        </Form>
        
                )
            }}
        </Formik>

        </div>
        <div  className='flex flex-col w-[35%] border rounded-sm border-gray-200'>
         <div className='flex  justify-between p-3 border rounded-sm border-gray-200'>
            <span>Order Summary</span>
            {/* <span>Edit Cart</span> */}
            </div>  
            <div className=' p-3'>
            <div className='pb-3 text-gray-600 font-semibold'>{carts.numberCart} items</div>
            <div className="flex flex-col space-y-3 justify-center">{
                carts.Carts.map((item,key) => 
                <div key={key} className="flex flex-row space-x-2 text-gray-400">
                  <div > <img src={item.image} className="w-16" /> </div>
                  <div className="flex flex-1 flex-col space-y-1">
                  <div>{item.quantity} x {item.name}</div>
                  {/* <div>${item.price}</div> */}
                  <div className="flex space-x-2 text-sm"><div className="font-bold">Color:</div> <div>{item.color}</div></div>
                  <div className="flex space-x-2 text-sm"><div className="font-bold">Size:</div> <div>{item.size}</div></div>
                  {/* <div className="flex flex-col space-y-2 text-sm">
                  <div className="font-bold">Quantity:</div>
                  <div className="flex space-x-2 ">
                    <AddMinusInput value={item.quantity} name="textsize" itemkey={key} increameent={1} handleValueChange={handleSize}  />
                    <Button variant="plain" onClick={()=>removeItem(key)}>Remove</Button></div>
                    </div> */}

                  </div>
                  { shopInfo.admin_payment.toString() === "false" && <div className='text-gray-600 font-semibold'>${(item.price * item.quantity).toFixed(2)}</div>}
                 </div> )

               }</div>
                
                </div> 
                {shopInfo.admin_payment.toString() === "false" && <div className='flex space-y-3 flex-col p-3 border rounded-sm border-gray-200'>
                <div className='flex  justify-between '>
                    <span>Subtotal</span>
                    <span>${getSubTotal()}</span>
            </div>
            <div className='flex  justify-between '>
                    <span>Shipping</span>
                    <span>${shippingcost}</span>
            </div>
            </div>  }
            {shopInfo.admin_payment.toString() === "false" &&  <div className='flex space-y-3 flex-col p-3 border rounded-sm border-gray-200'>
                <div className='flex  justify-between items-center '>
                    <span>Total(USD)</span>
                    <span className='text-3xl font-semibold'>${getFullTotal()}</span>
            </div>
            
            </div>} 
            </div>
    </div>
    </div>
    </section>
    <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
                width={650}
            >
        <PaymentDialog amount={getFullTotal()} backPayState={createOrder}/>
    </Dialog>
    </section>
  )
}

export default Details