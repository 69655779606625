import { APP_PREFIX_PATH } from 'constants/route.constant'
import React from 'react'
import { Link } from 'react-router-dom'

const MainHeroSection = (props) => {
  return (
    <>
      <section className="md:pt-[120px] pt-[120px] pb-20 md:pb-[20px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <h1 className="font-bold text-[36px]">{props.title}</h1>
            <p className="mt-2">{props.subTitle}</p>
           {!props.hidebtn && <div className="mt-4">
              <Link to={APP_PREFIX_PATH+"/pages/contact"} className="btn-custom py-2 px-[15px]">
                Contact US
              </Link>
            </div>}
          </div>
        </div>
      </section>
    </>
  )
}

export default MainHeroSection
