import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kids from "assets/images/kids-clothing.jpg";
import mens from "assets/images/mens-clothing.jpg";
import womens from "assets/images/womens-clothing.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getProducts, setActiveFilter, setFilterData } from '../store/dataSlice';
const CategorySlider = () => {
    const dispatch = useDispatch()
    const filterData = useSelector(
        (state) => state.productlist.data.filterData
    )
    const { pageIndex, pageSize, sort, query, total } = useSelector(
        (state) => state.productlist.data.tableData
    )
    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
          <div
            className={`${className} custom-prev-arrow`}
            onClick={onClick}
          />
        );
      };
    
      const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
          <div
            className={`${className} custom-next-arrow`}
            onClick={onClick}
          />
        );
      };
      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 9,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000,
        pauseOnHover: true,
        centerPadding: 0,
        slidesToScroll: 1,
        centerMode: false,
        arrows:false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                infinite: false,

                slidesToShow: 6,
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              
              }
            },
            {
              breakpoint: 480,
              settings: {
        infinite: true,

                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
        
      };
      const topsLideClick = (type)=>{
        const newFilterData = cloneDeep(filterData)
        newFilterData.category = type
        dispatch(setFilterData(newFilterData));
        dispatch(setActiveFilter({type:type}))

        dispatch(getProducts({ pageIndex, pageSize, sort, query, filterData:newFilterData,token:null,isFav:false }))

    }
  return (
    <div className='bg-gray-200 rounded-md mb-4 py-3  slider-container'>
    <Slider {...settings}>
    <div key={0} className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={kids} className='w-100' />
                </div>
                <div className='weight600'>Kids's/Youth Clothing</div>
            </div>
            <div key={1}  className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Men"])}>
                <div className='w-24 h-24 rounded-full bg-pink-100 overflow-hidden '>
                <img src={mens} className='w-100' />

                </div>
                <div className='weight600'>Men's Clothing</div>
            </div>
            
            <div key={2} className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Women"])}>
                <div className='w-24 h-24 rounded-full bg-pink-100 overflow-hidden'>
                <img src={womens} className='w-100' />

                </div>
                <div className='weight600'>Women's Clothing</div>
            </div>
    </Slider>
    </div>
  )
}

export default CategorySlider