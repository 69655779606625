import { combineReducers } from '@reduxjs/toolkit'
import todoProduct from './cart'
// import user from './userSlice'
// import shop from './shopSlice'

const reducer = combineReducers({
    todoProduct
})

export default reducer
