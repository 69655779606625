

import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import display7 from "assets/images/features/display-7.jpg";
import display2 from "assets/images/features/display-2.jpg";
import display3 from "assets/images/features/display-3.jpg";
import { Button, Dialog } from "components/ui";
import { useState } from "react";
import whatisimg from "assets/images/connect/what-is.jpg";
import ConnectBtn from "components/buttons/ConnectBtn";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";

const WhyDisplay = () => {
   const [dialogIsOpen, setIsOpen] = useState(false);
   const whyInfo = ["High Profit Margins","Swift Production Fulfillment","Wide Range of Quality Products"];
   useEffect(() => {
    // openDialog()
   
     return () => {
      
     }
   }, [])
   

      // Custom navigation arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        onClick={onClick}
      />
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerPadding: 0,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    
  };
  return (
    <>
<div className="slider-container">
    
      <div className="item mx-6">
      
       
      <div className="" style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
      <iframe
        src={`https://www.youtube.com/embed/cDx2tDiRzeY`}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="US Print Factory"
      ></iframe>
    </div>
       
      </div>
      
      <div className="md:px-10 px-2 text-center flex items-center justify-center relative z-10 mb-5">
      <div className="flex justify-center h-full flex-col pt-[36px]  w-full  ">
          <span className="font-extrabold text-2xl  normal-case mb-2 relative z-10 ">Create your free account
          and start selling today</span>
          
          <div className="text-[18px] md:text-[30px] font-bold text-left ">
          <Link to={`https://usprintfactory.com/app/`} target="_blank" className=" "><ConnectBtn btnClass="px-3 font-bold text-lg " text={"Start for free"} /></Link>
            </div>
           
        </div>
        
      </div>
    
  </div>
  </>
  )
}

export default WhyDisplay