import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'productlist/state',
    initialState: {
        sideBarExpand: true,
        mobileSideBarExpand: false,
        selectedCategory: {},
        selectedSubCategory: {label:"All",value:"all"},
        reply: false,
        newMessageDialog: false,
        designerView:false,
        currentStep:0,
        loading:false,
        selectedProduct:null,
        selectedCategory: {},
        selectedSubCategory: {label:"All",value:"all"},
    },
    reducers: {
        updateReply: (state, action) => {
            state.reply = action.payload
        },
        toggleSidebar: (state, action) => {
            state.sideBarExpand = action.payload
        },
        toggleMobileSidebar: (state, action) => {
            state.mobileSideBarExpand = action.payload
        },
        toggleNewMessageDialog: (state, action) => {
            state.newMessageDialog = action.payload
        },
        updateSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
        updateSelectedSubCategory: (state, action) => {
            state.selectedSubCategory = action.payload
        },
        updateDesignerView: (state, action) => {
            state.designerView = action.payload
        },
        updateCurrentStep: (state, action) => {
            state.currentStep = action.payload
        },
    },
})

export const {
    updateReply,
    toggleSidebar,
    toggleMobileSidebar,
    toggleNewMessageDialog,
    updateSelectedCategory,
    updateSelectedSubCategory,
    updateCurrentStep,
    updateDesignerView
} = stateSlice.actions

export default stateSlice.reducer
