import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Buttons'
import { HiMinus, HiPlus } from 'react-icons/hi'
import { FormNumericInput } from 'components/shared'
import { useState } from 'react'
const AddMinusInput = React.forwardRef((props, ref) => {
    const {
        value,
        callback,
        handleValueChange,
        increameent,
        itemkey,
        name,
        ...rest
    } = props;
    const [currenValue, setCurrentvalue] = useState(value)
    
    const updateChange = (e) =>{
        console.log(currenValue,e.value);
        setCurrentvalue(e.floatValue);
        // handleValueChange(e.floatValue,name)
        
    }
    const addValue = ()=>{
        
        setCurrentvalue(parseInt(currenValue+increameent))
        handleValueChange(parseInt(currenValue+increameent),itemkey,"plus")

    }
    const minusValue = ()=>{
        if(currenValue === 1){
            return;
        }
        setCurrentvalue(parseInt(currenValue-increameent))
        handleValueChange(parseInt(currenValue-increameent),itemkey,"minus")

    }
  return (
    <div className='flex  space-x-0 w-50'>
         <Button icon={<HiMinus />} onClick={minusValue} className="!rounded-r-none !bg-gray-100 !border-r-0 w-20" />
<FormNumericInput disabled value={currenValue}  onValueChange={updateChange} className="w-10 px-1 text-center focus:ring-slate-200 !rounded-r-none !rounded-l-none" />
<Button icon={<HiPlus />} onClick={addValue}  className="!rounded-l-none !border-l-0 w-20 !bg-gray-100" />
    </div>
  )
})

AddMinusInput.propTypes = {
    
   
    className: PropTypes.string,
    
    color: PropTypes.string,
   
    active: PropTypes.bool,
}

AddMinusInput.defaultProps = {
    variant: 'default',
    shape: 'round',
    increameent:1,
    active: false,
    loading: false,
    disabled: false,
    color: '',
}

export default AddMinusInput