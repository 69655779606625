import React, { useEffect, useState } from "react";
import {
  pricingplanPerMonth,
  pricingplanPerYear,
  pricingPlanPolicy,
} from "../../assets/fake-data/landingpage-three/pricingplan";
import { Link } from "react-router-dom";

const PricingPlan = () => {
  const [dataBlock] = useState({
    title: "Simple Plan For Everyone",
    subTitle:
      "With our advanced tools, we are fully equipped to provide an extensive array of Print-on-Demand (POD) services at an affordable cost.",
  });

  useEffect(() => {
    
  }, [])

  
  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[900px] mt-6">
                {dataBlock.subTitle}
              </p>
            </div>
          </div>
         
          <div id="tab-panels1">
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] mt-12">
                {pricingplanPerMonth.map((x) => {
                  return (
                    <div
                      key={x.id}
                      className="border-2 text-center border-[#f1f1f1] relative overflow-hidden py-12 px-6 rounded-lg"
                    >
                      <h4 className="font-bold text-2xl">{x.name}</h4>
                      <div className="py-6 space-x-2.5">
                      <h3 className="font-bold mb-1 text-[32px]">{x.price}</h3>
                      <p className="text-muted">{x.subText}</p>
                      </div>
                      <p className="text-muted max-w-[300px] mx-auto">
                        {x.description}
                      </p>
                      <div className="h-px w-full bg-[#e7e7e7] my-12"></div>
                      <div id="tab-panels">
                {x.planTable.map((x) => {
                    return <div key={x.id} className="md:max-w-[400px] mx-auto relative overflow-hidden  px-[16px] rounded-lg">
                        
                        <div className="space-y-[16px] text-left">
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text1}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text2}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text3}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text4}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text5}</p>
                        </div>
                        
                    </div>
                })}
                    
                 
                </div>
                      <div className="mt-12">
                        <Link to={`https://usprintfactory.com/app/sign-up?id=${x.id}`} target="_blank"  className="btn-custom">
                          Choose Plan
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="hidden">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] mt-12">
                {pricingplanPerYear.map((x) => {
                  return (
                    <div key={x.id} className="border-2 text-center border-[#f1f1f1] relative overflow-hidden py-12 px-6 rounded-lg">
                      <h4 className="font-bold text-2xl">{x.name}</h4>
                      <div className="py-6 space-x-2.5">
                        {x.starArray.map((x) => {
                          return (
                            <i key={x} className="mdi mdi-star text-custom h-[30px] w-[30px] leading-[30px] rounded-md inline-block bg-custom/20"></i>
                          );
                        })}
                      </div>
                      <p className="text-muted max-w-[300px] mx-auto">
                        {x.description}
                      </p>
                      <div className="h-px w-full bg-[#e7e7e7] my-12"></div>
                      <h3 className="font-bold mb-1 text-[40px]">{x.price}</h3>
                      <p className="text-muted">{x.subText}</p>
                      <div className="mt-12">
                        <Link to="#" className="btn-custom">
                          Choose Plan
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingPlan;
