import { createSlice } from '@reduxjs/toolkit'

export const initialState = null

export const shopSlice = createSlice({
    name: 'auth/shop',
    initialState,
    reducers: {
        setShop: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setShop } = shopSlice.actions

export default shopSlice.reducer
