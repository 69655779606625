import React, { useState } from "react";
import overviewData from "../../assets/fake-data/overview";

const NeedHelp = () => {
  const [toggleModal, setToggleModal] = useState(false);
  return (
    <>
      <section className="py-20 bg-[url('../images/contact.jpg')] relative bg-cover">
        <div className="absolute inset-0 w-full h-full bg-dark/50"></div>
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="bg-white md:py-20 md:px-[60px] px-5 py-[60px] relative max-w-[600px] md:ml-0 mx-auto rounded">
              <div className="text-center">
                <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                  <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                  Still Need Help?
                </h2>
                <p className="mx-auto text-lg max-w-[600px] mt-6">
                  Due to its widespread use as filler text for layouts,
                  non-readability is of great importance.
                </p>
                <div className="pt-12">
                  <button
                    className="btn-custom w-3/4"
                    onClick={() => setToggleModal(true)}
                  >
                    Get In Touch
                  </button>
                </div>
                <div>
                  {toggleModal ? (
                    <>
                      <div
                        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="relative w-full my-6 mx-auto max-w-[500px]">
                          <div className="rounded relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-center justify-between p-4 border-b border-[#dee2e6]">
                              <h3 className="text-xl font-bold">Contact Us</h3>
                              <button
                                className="ml-auto bg-transparent border-0 text-dark"
                                onClick={() => setToggleModal(false)}
                              >
                                <span className="bg-transparent stroke-dark block">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 stroke-dark"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div className="relative p-3 flex-auto">
                              <form className="space-y-6">
                                {overviewData.map((x) => {
                                  return (
                                    <div key={x.id}>
                                      <input
                                        type={x.type}
                                        className={x.class}
                                        placeholder={x.placeHolder}
                                        required={true}
                                      />
                                    </div>
                                  );
                                })}
                                <button
                                  className="btn-custom w-full"
                                  type="submit"
                                >
                                  Send Message
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="fixed inset-0 z-40 bg-dark/60"
                        id="modal-id-backdrop"
                      ></div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeedHelp;
