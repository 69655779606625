import { combineReducers } from 'redux';
import {GET_NUMBER_CART,ADD_CART, DECREASE_QUANTITY,CLEAR_CART, INCREASE_QUANTITY, DELETE_CART,TOGGLE_CART} from  './actions';

const initProduct = {
    numberCart:0,
    showCart:false,
    Carts:[],
    _products:[]
}

function todoProduct(state = initProduct,action){
    switch(action.type){
       
        case GET_NUMBER_CART:
                return{
                    ...state
                }
        case ADD_CART:
            if(state.numberCart==0){
                let cart = {
                    ...action.payload,
                    id:action.payload.uspf_variant_id,
                    quantity:action.payload.qty,
                   
                } 
                state.Carts.push(cart); 
            }
            else{
                let check = false;
                state.Carts.map((item,key)=>{
                    if(item.id==action.payload.uspf_variant_id){
                        state.Carts[key].quantity=state.Carts[key].quantity+action.payload.qty;
                        check=true;
                    }
                });
                if(!check){
                    let _cart = {
                        ...action.payload,
                        id:action.payload.uspf_variant_id,
                        quantity:action.payload.qty,
                    }
                    state.Carts.push(_cart);
                }
            }
            return{
                ...state,
                numberCart:state.numberCart+action.payload.qty,
                showCart:true
            }
            case INCREASE_QUANTITY:
                state.numberCart++
                state.Carts[action.payload].quantity++;
              
               return{
                   ...state
               }
            case DECREASE_QUANTITY:
                let quantity = state.Carts[action.payload].quantity;
                if(quantity>1){
                    state.numberCart--;
                    state.Carts[action.payload].quantity--;
                }
              
                return{
                    ...state
                }
            case DELETE_CART:
                let quantity_ = state.Carts[action.payload].quantity;
                return{
                    ...state,
                    numberCart:state.numberCart - quantity_,
                    Carts:state.Carts.filter(item=>{
                        return item.id!=state.Carts[action.payload].id
                    })
                    
                }
            case CLEAR_CART:
                
                return{
                    ...state,
                    numberCart:0,
                    Carts:[]
                    
                }    
            case TOGGLE_CART:
            let view = action.payload;
            return{
                ...state,
                showCart:view,
               
                
            }
        default:
            return state;
    }
}
// const ShopApp = combineReducers({
//     _todoProduct:todoProduct
// });
export default todoProduct;