import React, { useEffect, useState } from "react";
import { NavLink, useLocation, matchPath, Link } from "react-router-dom";
import { AddMinusInput, Button, Drawer,Menu } from 'components/ui'
import { ActionLink } from 'components/shared'
import logo from "assets/images/logo-dark.png";


const LandingHeader = ({shop}) => {
  
  return (
    <div>
      
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed w-full horizontal-nav flex items-center">
          <div className="container h-full sm:px-0">
            <div className="flex items-center justify-center h-full">
              <div className="flex items-center space-x-3">
           
              <NavLink className="navbar-brand" to="/">
                <img src={logo} className="mr-2 w-[90px] md:w-[110px]" alt="logo" />
              </NavLink>
              </div>

             
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default LandingHeader;
