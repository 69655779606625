
import React from "react";

import FooterTwo from "components/footers/FooterTwo";
import ExtraHeroSection from "features/common/ExtraHeroSection";

const UsagePolicy = () => {
  return (
    <>
      <ExtraHeroSection title="Acceptable Use Policy" subTitle="Last modified: April 17th, 2023" />
      <section className="pt-10 pb-[170px] bg-light">
        <div className="container">
        <p>This policy describes the acceptable use of the goods and services provided by US Print Factory LLC (&ldquo;<strong>Company</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo;) ,&nbsp;<a href="http://www.uspfdashboard.com/" className="text-blue-500">www.uspfdashboard.com</a> (our &ldquo;<strong>Website</strong>&rdquo;), or our application on the Shopify platform (the &ldquo;<strong>Application</strong>&rdquo;), and our practices for maintaining and operating in accordance to said policy.&nbsp;</p>
<p>&nbsp;</p>
<h5><strong>Prohibited Uses</strong></h5>
<p>You may use the Website or Application only for lawful purposes and in accordance with our Terms of Use. You agree not to use the Website:&nbsp;</p>
<ul>
    <li>To sell Merchandise that infringes upon third party intellectual property rights (such as copyright, trademark, trade dress, and rights of publicity).&nbsp;</li>
    <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the U.S. or other countries).</li>
    <li>For the purpose of exploiting, harming, or attempting to harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
    <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out within our Terms of Use policy.</li>
    <li>To transmit, or procure the sending of, any advertising or promotional material without or prior written consent, including any &ldquo;junk mail&rdquo;, &ldquo;chain letter&rdquo; or &ldquo;spam&rdquo; or any similar solicitation.</li>
    <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail address or screen names associated with any of the forgoing).&nbsp;</li>
    <li>To engage in any other conduct that restricts or inhibits anyone&rsquo;s use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website or expose them to liability.</li>
    <li>Additionally, you agree not to:</li>
    <li>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&rsquo;s use of the Website, including their ability to engage in real time activities through the Website.</li>
    <li>Use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>
    <li>Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.</li>
    <li>Use any device, software, or routine that interferes with the proper working of the Website.</li>
    <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
    <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website.</li>
    <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
    <li>Otherwise attempt to interfere with the proper working of the Website.</li>
</ul>
<p><br /></p>
<h5><strong>Copyright and Trademark Infringement</strong></h5>
<p>If you believe that any User Contributions violate your copyright or trademark, please send an email to&nbsp;<a href="mailto:Support@USPrintFactory.com" className="text-blue-500">support@USPrintFactory.com</a> with the following: (a) your physical or electronic signature; (b) identification of the copyrighted/trademark work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works; (c) identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material; (d) adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address); (e) a statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law; (f) a statement that the information in the written notice is accurate; and (g) a statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner. It is the policy of the Company to terminate the user accounts of repeat infringers.&nbsp;</p>
<p><br /></p>
<h5><strong>Contact Information</strong></h5>
<p>To ask questions or comment about this Acceptable Use Policy and our adherence practices, please contact us at:&nbsp;<a href="mailto:support@USPrintFactory.com" className="text-blue-500">support@USPrintFactory.com</a> &nbsp;</p>
<p><br /></p>
    </div></section>
      <FooterTwo/>
    </>
  );
};

export default UsagePolicy;
