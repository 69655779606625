
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kids from "assets/images/kids-clothing.jpg";
import mens from "assets/images/mens-clothing.jpg";
import womens from "assets/images/womens-clothing.jpg";

const ProductSlider = () => {
  
    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
          <div
            className={`${className} custom-prev-arrow left-1`}
            onClick={onClick}
          />
        );
      };
    
      const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
          <div
            className={`${className} custom-next-arrow right-1`}
            onClick={onClick}
          />
        );
      };
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 9,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000,
        pauseOnHover: true,
        centerPadding: 0,
        slidesToScroll: 1,
        centerMode: false,
        arrows:false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 6,
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2
              }
            }
          ]
        
      };
      const topsLideClick = (type)=>{
        
    }
  return (
    <div className='bg-gray-50 rounded-md mt-8 py-6 slider-container'>
    <Slider {...settings}>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/2766_fm.jpg"} className='w-80 md:w-100' />
    </div>
                <div className='weight600'>Jersey Tank</div>
    </div>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/24_fm.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>V-Neck Tee</div>
    </div>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/6168_fm.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>Toddler Jersey</div>
    </div><div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/1822_fm.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>T-Shirt</div>
    </div><div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/123_fm.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>Long Sleeve</div>
    </div>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/571_fm.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>Sweatshirt</div>
    </div>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://cdnp.sanmar.com/medias/sys_master/images/images/h67/h66/13118744068126/7045-Charcoal-1-LPC54TTCharcoalModelFront1-337W.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>Tank Top</div>
    </div>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://cdnp.sanmar.com/medias/sys_master/images/images/hd0/hf1/10527195922462/4180-AthleticHthr-1-PC78HAthleticHthrModelFront1-337W.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>Hoodies</div>
    </div>
    <div className='flex flex-col items-center space-y-2 min-w-[128px] cursor-pointer' onClick={()=>topsLideClick(["Kids","Youth"])}>
                <div className='w-24 h-24 md:w-28 md:h-28 rounded-full bg-pink-100 overflow-hidden'>
                    <img src={"https://www.ssactivewear.com/Images/Style/3895_fm.jpg"} className='w-80 md:w-100' />
                </div>
                <div className='weight600'>Racerback Tank</div>
    </div>
    </Slider>
    </div>
  )
}

export default ProductSlider