import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logoLight from '../assets/images/logo-light.png'
import { Button ,FormContainer, FormItem, Input ,toast,Notification} from 'components/ui'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import useAuth from 'utils/hooks/useAuth'

const validationSchema = Yup.object().shape({
    
    email: Yup.string().email('Invalid email').required('Please enter your email'),
   
})
const PWReset = ({callback}) => {
    const [dataBlock] = useState({
        title:"Forgot Your Password?",
        subTitle:"Change your password in three easy steps. This helps to keep your new password secure."
    })
    const disableSubmit = false;
    const { getPassword } = useAuth()
    const onSignIn = async (values, setSubmitting) => {
        const { email } = values;
        
        setSubmitting(true)

        const result = await getPassword({ email })

        if (result.status === 'failed') {
            toast(<Notification closable type="danger" duration={2000}>
            {result.message}
  </Notification>)
        }
        else{
            toast(<Notification closable type="success" duration={2000}>
            Email has been sent with link to reset password.
  </Notification>)
        }

        setSubmitting(false)
    }
  return (
    <>
       <section className="items-center flex bg-[url('../images/login_bg.jpg')] bg-cover bg-center relative py-20 min-h-screen">
        <div className="bg-[#0d0f14]/25 absolute inset-0 backdrop-blur-md"></div>
        <div className="container relative">
           
            <div className="grid grid-cols-1 mt-16">
                <div className="max-w-[450px] mx-auto py-10 px-8 rounded-xl bg-white w-full">
                    <h4 className="font-bold text-2xl mb-2 text-center capitalize">{dataBlock.title}</h4>
                    <p className="text-muted text-center">{dataBlock.subTitle}</p>
                    <Formik
                // Remove this initial value
                initialValues={{
                    email: '',
                   
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    if (!disableSubmit) {
                        onSignIn(values, setSubmitting)
                    } else {
                        setSubmitting(false)
                    }
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form className="mt-6 space-y-4">
                        <FormContainer className="mt-6 space-y-4">
                            
                        <FormItem
                                label="Email"
                                invalid={errors.email && touched.email}
                                errorMessage={errors.email}
                            >
                                <Field
                                    type="email"
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    component={Input}
                                />
                            </FormItem>
                            
                            <div className="mt-4 text-center py-4">
                              <Button variant="solid" loading={isSubmitting}  type="submit" className="btn bg-green btn-sm btn-custom navbar-btn btn-rounded py-2 px-[15px]" >Get Password</Button>
                            </div>
                        </FormContainer>
                    </Form>
                )}
                    </Formik>
                </div>
                <div className="text-center mt-6">
                    <p><small className="text-white mr-2">Already have an account?</small> <Button onClick={()=>callback("login")} className=" font-bold">Sign In</Button></p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PWReset
